import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  InputlistProps,
  InputProps,
  useStyles,
  GlobalCss,
} from "./Components/ComponentsStyle/ModalStyle";
import {
  GeneralInputForm,
  Form,
} from "../../app/generalComponent/GeneralInputForm";
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/SearchOutlined";
import CancelIcon from "@material-ui/icons/Cancel";
import Pagination from "@material-ui/lab/Pagination";
import Typography from "@material-ui/core/Typography";
import {
  GetPatientRegistration,
  GetPatientRegistrationDetails,
  PostAllResult,
  UpdateAllResult,
} from "./CustomerTestResultAPI";
import { UrineExam } from "./Components/UrineExam";
import { StoolExam } from "./Components/StoolExam";
import { CbcExam } from "./Components/CbcExam";
import { SemenExam } from "./Components/SemenExam";
import { CbcExamDiff5 } from "./Components/CbcExamDiff5";
import Barcode from "react-barcode";
import { PrintDoc } from "./Components/PrintDoc";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import { selectClientInfo } from "../Authentecation/AuthSlice";
import { FormattedMessage } from "react-intl";

// import { jsPDF } from "jspdf";
const initialFValues = {
  invoice_id: "",
  patient_id: "",
  patient_name: "",
  gender: "",
  age: "",
  age_type: "",
  phone_number: "",
  registration_date: "",
  customer_pricing_type: "",
  customer_pricing_type_textInput: "",
  test_items: null,
  total_amount: "",
};

const CustomerTestResultPage = () => {
  const [patientRegistration, setPatientRegistration] = useState(null);
  const [urine_customer_test, setUrine_customer_test] = useState(null);
  const [urineResult, setUrineResult] = useState([]);
  const [stool_customer_test, setStool_customer_test] = useState(null);
  const [stoolResult, setStoolResult] = useState([]);
  const [cbc3_customer_test, setCbc3_customer_test] = useState(null);
  const [cbc3Result, setCbc3Result] = useState([]);
  const [cbc5_customer_test, setCbc5_customer_test] = useState(null);
  const [cbc5Result, setCbc5Result] = useState([]);
  const [semen_customer_test, setSemen_customer_test] = useState(null);
  const [semenResult, setSemenResult] = useState([]);
  const [urineExamOpen, setUrineExamOpen] = useState(false);
  const [stoolExamOpen, setStoolExamOpen] = useState(false);
  const [cbcExamOpen, setCbcExamOpen] = useState(false);
  const [cbcExamDiff5Open, setCbcExamDiff5Open] = useState(false);
  const [semenExamOpen, setSemenExamOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const per_page = 10;
  const classes = useStyles();
  const theme = useTheme();
  const printRef = useRef();
  const client = useSelector(selectClientInfo);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    // if ("patient_name" in fieldValues)
    //   temp.patient_name = fieldValues.patient_name ? "": "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    convertToDefEventPara,
  } = GeneralInputForm(initialFValues, null, true, false, validate, null);

  const LoadPatientRegistration = async (_page = 1) => {
    await setPage(_page);
    const response = await GetPatientRegistration(
      per_page,
      _page,
      searchInput,
      client.id
    );
    setPatientRegistration(response.data.data);
    setPageCount(Math.ceil(response.data.count / per_page));
    console.log(response);
  };

  const handleSelectRow = async (id) => {
    let data = await GetPatientRegistrationDetails(id)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
    setValues({
      ...values,
      invoice_id: id,
      patient_id: data.patient.id,
      patient_name: data.patient?.patient_name,
      gender: data.patient.gender,
      age: data.patient.age,
      age_type: data.patient.age_type,
      phone_number: data.patient.phone_number,
      customer_pricing_type: data.patient.customer_pricing_type.id,
      customer_pricing_type_textInput:
        data.patient.customer_pricing_type.pricingtype,
      registration_date: data.visit_date,
      test_items: data.registrationitems,
      total_amount: data.total_amount,
    });
  };

  const handleInputTestResultListChange = (event, index, listName) => {
    debugger;
    if (event.target.value.length === 0) {
      return;
    }
    // const index = values[listName].findIndex((item) => {
    //   return item.id === id;
    // });
    const item = Object.assign({}, values[listName][index]["result"][0]);
    // const customer_test = values[listName][index]["id"];
    const invoice_id = values[listName][index]["registration"]["id"];
    const test_name = values[listName][index]["test"]["english_name"];
    item[event.target.name] = event.target.value;
    item["invoice_id"] = invoice_id;
    item["test_name"] = test_name;
    // item["customer_test"] = customer_test;

    const items = Object.assign([], values[listName]);
    items[index]["result"][0] = item;

    handleInputChange(convertToDefEventPara(listName, items));
  };

  const AddOrUpdateResult = async (e, item) => {
    debugger;
    if (item.result[0].id) {
      UpdateAllResult(e, item.result[0]);
    } else {
      PostAllResult(e, item.result[0]);
    }
    let data = await GetPatientRegistrationDetails(values.invoice_id)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
    setValues({
      ...values,
      patient_id: data.patient.id,
      patient_name: data.patient?.patient_name,
      gender: data.patient.gender,
      age: data.patient.age,
      age_type: data.patient.age_type,
      phone_number: data.patient.phone_number,
      customer_pricing_type: data.patient.customer_pricing_type.id,
      customer_pricing_type_textInput:
        data.patient.customer_pricing_type.pricingtype,
      registration_date: data.visit_date,
      test_items: data.registrationitems,
      total_amount: data.total_amount,
    });
  };

  useEffect(() => {
    LoadPatientRegistration();
  }, []);

  return (
    <Fragment>
      <GlobalCss />
      <UrineExam
        open={urineExamOpen}
        setOpen={setUrineExamOpen}
        result={urineResult}
        invoice_id={urine_customer_test}
        loadRefreshData={handleSelectRow}
      />
      <StoolExam
        open={stoolExamOpen}
        setOpen={setStoolExamOpen}
        result={stoolResult}
        invoice_id={stool_customer_test}
        loadRefreshData={handleSelectRow}
      />
      <CbcExam
        open={cbcExamOpen}
        setOpen={setCbcExamOpen}
        result={cbc3Result}
        invoice_id={cbc3_customer_test}
        loadRefreshData={handleSelectRow}
      />
      <CbcExamDiff5
        open={cbcExamDiff5Open}
        setOpen={setCbcExamDiff5Open}
        result={cbc5Result}
        invoice_id={cbc5_customer_test}
        loadRefreshData={handleSelectRow}
      />
      <SemenExam
        open={semenExamOpen}
        setOpen={setSemenExamOpen}
        result={semenResult}
        invoice_id={semen_customer_test}
        loadRefreshData={handleSelectRow}
      />
      <Form>
        <Grid container>
          <Grid item xs={9}>
            <Paper className={classes.form}>
              <div className={classes.spaceAroundRow}>
                <div style={{ height: 50, marginInline: 5 }}>
                  <Barcode
                    value={
                      values?.invoice_id ? values.invoice_id.toString() : "0"
                    }
                    width={2}
                    height={40}
                    format={"CODE128"}
                    displayValue={true}
                    fontOptions={""}
                    font={"monospace"}
                    textAlign={"center"}
                    textPosition={"bottom"}
                    textMargin={2}
                    fontSize={12}
                    background={"#ffffff"}
                    lineColor={"#000000"}
                    margin={5}
                    marginTop={undefined}
                    marginBottom={undefined}
                    marginLeft={undefined}
                    marginRight={undefined}
                  />
                </div>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "100%", marginInline: 5 }}
                  label={<FormattedMessage id="patient_name" />}
                  name="patient_name"
                  value={values.patient_name}
                />
                <TextField
                  id="outlined-select"
                  select
                  variant="filled"
                  size="small"
                  InputProps={InputProps}
                  style={{ width: "100%", marginInline: 5 }}
                  label={<FormattedMessage id="gender" />}
                  name="gender"
                  value={values.gender}
                  defaultValue={1}
                >
                  {/* <MenuItem value={0}>
                  <em>None</em>
                </MenuItem> */}
                  <MenuItem value={"Male"}>
                    {<FormattedMessage id="male" />}
                  </MenuItem>
                  <MenuItem value={"Female"}>
                    {<FormattedMessage id="female" />}
                  </MenuItem>
                  {/* {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
                </TextField>
                <TextField
                  id="outlined-number"
                  variant="filled"
                  size="small"
                  type="number"
                  InputProps={InputProps}
                  style={{ width: "100%", marginInline: 5 }}
                  label={<FormattedMessage id="age" />}
                  name="age"
                  value={values.age}
                  {...(errors.age && { error: true, helperText: errors.age })}
                />
                <TextField
                  id="outlined-select"
                  select
                  variant="filled"
                  size="small"
                  InputProps={InputProps}
                  style={{ width: "100%", marginInline: 5 }}
                  label={<FormattedMessage id="age_type" />}
                  name="age_type"
                  value={values.age_type}
                  defaultValue={1}
                >
                  {/* <MenuItem value={0}>
                  <em>None</em>
                </MenuItem> */}
                  <MenuItem value={"Years"}>
                    {<FormattedMessage id="years" />}
                  </MenuItem>
                  <MenuItem value={"Week"}>
                    {<FormattedMessage id="week" />}
                  </MenuItem>
                  <MenuItem value={"Day"}>
                    {<FormattedMessage id="day" />}
                  </MenuItem>
                  {/* {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
                </TextField>
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  size="small"
                  InputProps={InputProps}
                  style={{ width: "45%", marginInline: 5 }}
                  label={<FormattedMessage id="phone_number" />}
                  name="phone_number"
                  value={values.phone_number}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  size="small"
                  InputProps={InputProps}
                  style={{ width: "45%", marginInline: 5 }}
                  label={<FormattedMessage id="customer_pricing_type" />}
                  name="customer_pricing_type"
                  value={values.customer_pricing_type_textInput}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  size="small"
                  InputProps={InputProps}
                  style={{ width: "45%", marginInline: 5 }}
                  label={<FormattedMessage id="registration_date" />}
                  name="registration_date"
                  value={values.registration_date}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  size="small"
                  InputProps={InputProps}
                  style={{ width: "45%", marginInline: 5 }}
                  label={<FormattedMessage id="total_amount" />}
                  name="total_amount"
                  value={values.total_amount}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      color="secondary"
                      style={{ marginInlineEnd: 20 }}
                      variant="contained"
                      onClick={() => {}}
                    >
                      <FormattedMessage id="print" />
                    </Button>
                  )}
                  content={() => printRef.current}
                />
              </div>
              <div style={{ display: "none" }}>
                <PrintDoc ref={printRef} values={values} client={client} />
              </div>
              <div>
                <ul className={classes.ul}>
                  {values.test_items?.map((item, index) => (
                    <li key={"test" + index} className={classes.li}>
                      <div className={classes.spaceAroundRowList}>
                        {/* <div style={{ width: "100%", marginInline: 5 }}>
                          <Barcode
                            value={item.id.toString()}
                            width={1}
                            height={50}
                            format={"CODE128"}
                            displayValue={false}
                            fontOptions={""}
                            font={"monospace"}
                            textAlign={"center"}
                            textPosition={"bottom"}
                            textMargin={2}
                            fontSize={20}
                            background={"#ffffff"}
                            lineColor={"#000000"}
                            margin={5}
                            marginTop={undefined}
                            marginBottom={undefined}
                            marginLeft={undefined}
                            marginRight={undefined}
                          />
                        </div> */}

                        <TextField
                          id="outlined-basic"
                          variant="filled"
                          InputProps={InputlistProps}
                          size="small"
                          fullWidth
                          style={{ marginInline: 5 }}
                          label={<FormattedMessage id="test_name" />}
                          name="test_textInput"
                          value={
                            item.test.english_name + " " + item.test.device_name
                          }
                        />
                        {item.test.more_result ? (
                          (item.test.result_type === "Urine" && (
                            <div className={classes.ButtonContainer}>
                              <Button
                                style={{
                                  marginInline: 5,
                                  color: theme.palette.info.main,
                                  textAlign: "initial",
                                }}
                                onClick={(event) => {
                                  console.log(item);
                                  setUrine_customer_test(item.registration.id);
                                  setUrineResult(item.result[0]);
                                  setUrineExamOpen(true);
                                }}
                              >
                                Urine Result
                              </Button>
                            </div>
                          )) ||
                          (item.test.result_type === "Stool" && (
                            <div className={classes.ButtonContainer}>
                              <Button
                                style={{
                                  marginInline: 5,
                                  color: theme.palette.info.main,
                                  textAlign: "initial",
                                }}
                                onClick={(event) => {
                                  console.log(item);
                                  setStool_customer_test(item.registration.id);
                                  setStoolResult(item.result[0]);
                                  setStoolExamOpen(true);
                                }}
                              >
                                Stool Result
                              </Button>
                            </div>
                          )) ||
                          (item.test.result_type === "CBC Diff 3" && (
                            <div className={classes.ButtonContainer}>
                              <Button
                                style={{
                                  marginInline: 5,
                                  color: theme.palette.info.main,
                                  textAlign: "initial",
                                }}
                                onClick={(event) => {
                                  console.log(item);
                                  setCbc3_customer_test(item.registration.id);
                                  setCbc3Result(item.result[0]);
                                  setCbcExamOpen(true);
                                }}
                              >
                                CBC Diff 3 Result
                              </Button>
                            </div>
                          )) ||
                          (item.test.result_type === "CBC Diff 5" && (
                            <div className={classes.ButtonContainer}>
                              <Button
                                style={{
                                  marginInline: 5,
                                  color: theme.palette.info.main,
                                  textAlign: "initial",
                                }}
                                onClick={(event) => {
                                  console.log(item);
                                  setCbc5_customer_test(item.registration.id);
                                  setCbc5Result(item.result[0]);
                                  setCbcExamDiff5Open(true);
                                }}
                              >
                                CBC Diff 5 Result
                              </Button>
                            </div>
                          )) ||
                          (item.test.result_type === "Semen" && (
                            <div className={classes.ButtonContainer}>
                              <Button
                                style={{
                                  marginInline: 5,
                                  color: theme.palette.info.main,
                                  textAlign: "initial",
                                }}
                                onClick={(event) => {
                                  console.log(item);
                                  setSemen_customer_test(item.registration.id);
                                  setSemenResult(item.result[0]);
                                  setSemenExamOpen(true);
                                }}
                              >
                                Semen Result
                              </Button>
                            </div>
                          ))
                        ) : (
                          <TextField
                            id="outlined-basic"
                            variant="filled"
                            InputProps={InputlistProps}
                            size="small"
                            fullWidth
                            style={{ marginInline: 5 }}
                            label={<FormattedMessage id="test_result" />}
                            name="result"
                            value={item.result[0].result}
                            onChange={(e) =>
                              handleInputTestResultListChange(
                                e,
                                index,
                                "test_items"
                              )
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                AddOrUpdateResult(e, item);
                              }
                            }}
                            {...(errors.result && {
                              error: true,
                              helperText: errors.result,
                            })}
                          />
                        )}
                        <TextField
                          id="outlined-basic"
                          variant="filled"
                          InputProps={InputlistProps}
                          size="small"
                          fullWidth
                          style={{ marginInline: 5 }}
                          label={<FormattedMessage id="unit" />}
                          name="unit"
                          value={item.test.units}
                        />
                        {item.test.result_type === "Urine" ||
                        item.test.result_type === "Stool" ||
                        item.test.result_type === "CBC Diff 3" ||
                        item.test.result_type === "CBC Diff 5" ||
                        item.test.result_type === "Semen" ? (
                          <TextField
                            id="outlined-basic"
                            variant="filled"
                            InputProps={InputlistProps}
                            size="small"
                            fullWidth
                            style={{ marginInline: 5 }}
                            label="___"
                            name="dash"
                            value={""}
                            disabled
                          />
                        ) : (
                          <TextField
                            id="outlined-basic"
                            variant="filled"
                            InputProps={InputlistProps}
                            size="small"
                            fullWidth
                            style={{ marginInline: 5 }}
                            label={<FormattedMessage id="normal" />}
                            name="normal"
                            value={item.test.normal[0].normal_value}
                          />
                        )}

                        <FormControlLabel
                          control={
                            <Switch
                              type="checkbox"
                              name="is_full"
                              checked={item.is_full}
                              onChange={(e) =>
                                handleInputChange(
                                  convertToDefEventPara(
                                    "is_full",
                                    e.target.checked
                                  )
                                )
                              }
                              color="primary"
                            />
                          }
                          label={<FormattedMessage id="is_full" />}
                          // labelPlacement="top"
                          style={{ color: "gray" }}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          style={{
                            height: 30,
                            whiteSpace: "nowrap",
                          }}
                          onClick={(event) => {
                            console.log(values);
                          }}
                        >
                          {<FormattedMessage id="reTest" />}
                        </Button>
                        {/* <FormControlLabel
                          control={
                            <Switch
                              name="is_verified"
                              checked={item.is_verified}
                              onChange={(e) =>
                                handleInputChange(
                                  convertToDefEventPara(
                                    "is_verified",
                                    e.target.checked
                                  )
                                )
                              }
                              color="primary"
                            />
                          }
                          label="V"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              name="is_printed"
                              checked={item.is_printed}
                              onChange={(e) =>
                                handleInputChange(
                                  convertToDefEventPara(
                                    "is_printed",
                                    e.target.checked
                                  )
                                )
                              }
                              color="primary"
                            />
                          }
                          label="P"
                        /> */}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <TableContainer component={Paper} style={{ maxHeight: "80vh" }}>
              <TextField
                style={{
                  padding: 10,
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                }}
                placeholder="Search"
                type="text"
                variant="outlined"
                fullWidth
                size="small"
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
                InputProps={{
                  startAdornment: (
                    // <InputAdornment position="start">
                    //   <SearchIcon/>
                    // </InputAdornment>
                    <IconButton
                      aria-label="toggle visibility"
                      onClick={() => LoadPatientRegistration()}
                    >
                      <SearchIcon />
                    </IconButton>
                  ),
                  endAdornment: searchInput ? (
                    <IconButton
                      aria-label="toggle visibility"
                      onClick={() => setSearchInput("")}
                    >
                      <CancelIcon />
                    </IconButton>
                  ) : null,
                }}
              />
              <Table aria-label="simple table" style={{}}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{"Id"}</TableCell>
                    {/* <TableCell align="center">{"Patient Id"}</TableCell> */}
                    <TableCell align="center">
                      {<FormattedMessage id="patient_name" />}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {patientRegistration?.map((reqis, index) => (
                    <TableRow
                      hover
                      onClick={() => {
                        handleSelectRow(reqis.id);
                      }}
                      key={"patientRegistration" + index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">{reqis.id}</TableCell>
                      {/* <TableCell align="center">{patient.patient.id}</TableCell>z */}
                      <TableCell align="center">
                        {reqis.patient?.patient_name}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Pagination
                style={{
                  paddingBlock: 10,
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "white",
                }}
                count={pageCount}
                page={page}
                showFirstButton
                showLastButton
                defaultPage={1}
                size="medium"
                onChange={async (event, value) => {
                  await LoadPatientRegistration(value);
                }}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </Form>
    </Fragment>
  );
};

export default CustomerTestResultPage;

// function CustomExportPdf(columns, data = [], filename = "data") {
//   try {
//     let finalData = data;
//     if (data.length && !Array.isArray(data[0])) {
//       if (typeof data[0] === "object") {
//         finalData = data.map((row) =>
//           columns.map((col) =>
//             col.exportTransformer
//               ? col.exportTransformer(row)
//               : row[col.field]
//           )
//         );
//       }
//     }
//       const content = {
//         startY: 50,
//         head: [columns.map((col) => col.title)],
//         body: finalData,
//       };
//       const unit = "pt";
//       const size = "A4";
//       const orientation = "landscape";
//       const doc = new jsPDF(orientation, unit, size);
//       doc.setFontSize(15);
//       doc.text(filename, 40, 40);
//       doc.autoTable(content);
//       doc.save(filename + Date.now().toString() + ".pdf");
//     // }
//   } catch (err) {
//     console.error(
//       `exporting pdf : ${err}`
//     );
//   }
// }
