import axiosInstance from "../../app/axiosInstance";

export const LoginUser = (username, password) => {
  let formData = {
    username: username,
    password: password,
  };
  return axiosInstance
    .post(`/api/user/login/`, JSON.stringify(formData))
    .then(function (response) {
      return response.data;
    });
};

export const GetClientAutoComplete = (search = "") =>
  axiosInstance.get(
    `/api/clientinfo/client_autoComplete/?search=${search}`
  );