import {
  Button,
  Container,
  // FormHelperText,
  MenuItem,
  Modal,
  Snackbar,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import SaveIcon from "@material-ui/icons/Save";
// import DepartmentDialog from "./DepartmentDialog";
// import GroupDialog from "./GroupDialog";
import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import { PostNormal } from "../TestAPI";
import { useStyles, InputProps } from "./ComponentsStyle/ModalStyle";
import { FormattedMessage } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const initialFValues = {
  test: "",
  gender: "",
  from_age: "",
  to_age: "",
  age_type: "",
  normal_value: "",
  lower_limit: "",
  upper_limit: "",
  lower_comment: "",
  upper_comment: "",
};

export const AddNormalModal = ({ test_id, open, setOpen }) => {
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const classes = useStyles();
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("gender" in fieldValues)
      temp.gender = fieldValues.gender ? "" : "This field is required.";
    if ("from_age" in fieldValues)
      temp.from_age = fieldValues.from_age ? "" : "This field is required.";
    if ("to_age" in fieldValues)
      temp.to_age = fieldValues.to_age ? "" : "This field is required.";
    if ("age_type" in fieldValues)
      temp.age_type = fieldValues.age_type ? "" : "This field is required.";
    if ("normal_value" in fieldValues)
      temp.normal_value = fieldValues.normal_value
        ? ""
        : "This field is required.";
    if ("lower_limit" in fieldValues)
      temp.lower_limit = fieldValues.lower_limit
        ? ""
        : "This field is required.";
    if ("upper_limit" in fieldValues)
      temp.upper_limit = fieldValues.upper_limit
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    convertToDefEventPara,
    resetForm,
  } = GeneralInputForm(initialFValues, null, true, false, validate, null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };

  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <Button
              variant="contained"
              style={{ marginInline: 20 }}
              onClick={(event) => {
                console.log(values);
                console.log(test_id);
                if (validate(values)) {
                  PostNormal(event, values, test_id);
                  setOpenSuccessAlert(true);
                } else {
                  setOpenErrorAlert(true);
                }
              }}
              startIcon={<SaveIcon />}
            >
              <FormattedMessage id="create" />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
                resetForm();
              }}
            >
              <FormattedMessage id="close" />
            </Button>
          </div>
          <Form className={classes.form}>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-select"
                select
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "25%", marginInline: 5 }}
                label={<FormattedMessage id="gender" />}
                name="gender"
                required
                value={values.gender}
                onChange={handleInputChange}
                {...(errors.gender && {
                  error: true,
                  helperText: errors.gender,
                })}
                defaultValue={1}
              >
                {/* <MenuItem value={0}>
                  <em>None</em>
                </MenuItem> */}
                <MenuItem value={"Male"}>
                  <FormattedMessage id="Male" />
                </MenuItem>
                <MenuItem value={"Female"}>
                  <FormattedMessage id="Female" />
                </MenuItem>
                {/* {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
              </TextField>
              <TextField
                id="outlined-number"
                variant="filled"
                size="small"
                type="number"
                required
                InputProps={InputProps}
                style={{ width: "25%", marginInline: 5 }}
                label={<FormattedMessage id="from_age" />}
                name="from_age"
                value={values.from_age}
                onChange={handleInputChange}
                {...(errors.from_age && {
                  error: true,
                  helperText: errors.from_age,
                })}
              />
              <TextField
                id="outlined-number"
                variant="filled"
                size="small"
                type="number"
                required
                InputProps={InputProps}
                style={{ width: "25%", marginInline: 5 }}
                label={<FormattedMessage id="to_age" />}
                name="to_age"
                value={values.to_age}
                onChange={handleInputChange}
                {...(errors.to_age && {
                  error: true,
                  helperText: errors.to_age,
                })}
              />
              <TextField
                id="outlined-select"
                select
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "25%", marginInline: 5 }}
                label={<FormattedMessage id="age_type" />}
                name="age_type"
                required
                value={values.age_type}
                onChange={handleInputChange}
                {...(errors.age_type && {
                  error: true,
                  helperText: errors.age_type,
                })}
                defaultValue={1}
              >
                {/* <MenuItem value={0}>
                  <em>None</em>
                </MenuItem> */}
                <MenuItem value={"Years"}>
                  <FormattedMessage id="Years" />
                </MenuItem>
                <MenuItem value={"Week"}>
                  <FormattedMessage id="Week" />
                </MenuItem>
                <MenuItem value={"Day"}>
                  <FormattedMessage id="Day" />
                </MenuItem>
                {/* {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
              </TextField>
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                size="small"
                required
                InputProps={InputProps}
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="normal_value" />}
                name="normal_value"
                value={values.normal_value}
                onChange={handleInputChange}
                {...(errors.normal_value && {
                  error: true,
                  helperText: errors.normal_value,
                })}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                size="small"
                InputProps={InputProps}
                required
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="lower_limit" />}
                name="lower_limit"
                value={values.lower_limit}
                onChange={handleInputChange}
                {...(errors.lower_limit && {
                  error: true,
                  helperText: errors.lower_limit,
                })}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                size="small"
                required
                InputProps={InputProps}
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="upper_limit" />}
                name="upper_limit"
                value={values.upper_limit}
                onChange={handleInputChange}
                {...(errors.upper_limit && {
                  error: true,
                  helperText: errors.upper_limit,
                })}
              />
            </div>

            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%" }}
                label={<FormattedMessage id="lower_comment" />}
                name="lower_comment"
                checked={values.lower_comment}
                onChange={handleInputChange}
                {...(errors.lower_comment && {
                  error: true,
                  helperText: errors.lower_comment,
                })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%" }}
                label={<FormattedMessage id="upper_comment" />}
                name="upper_comment"
                checked={values.upper_comment}
                onChange={handleInputChange}
                {...(errors.upper_comment && {
                  error: true,
                  helperText: errors.upper_comment,
                })}
              />
            </div>
          </Form>
          <Snackbar
            open={OpenSuccessAlert}
            autoHideDuration={4000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert onClose={handleClose} severity="success">
              Data saved
            </Alert>
          </Snackbar>

          <Snackbar
            open={openErrorAlert}
            autoHideDuration={4000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert onClose={handleClose} severity="error">
              Error saving data
            </Alert>
          </Snackbar>
        </Container>
      </Modal>
    </Fragment>
  );
};
