import { getFromLocalStorage } from "../../app/Utility/CacheStore";
import axiosInstance from "../../app/axiosInstance";

export const GetDepartmentAutoComplete = (search = "",client) =>
  axiosInstance.get(
    `/api/testManagement/department_autoComplete/?search=${search}&client=${client}`
  );

export const GetGroupAutoComplete = (search = "",client) =>
  axiosInstance.get(`/api/testManagement/group_autoComplete/?search=${search}&client=${client}`);

export const GetTestDetails = (id) =>
  axiosInstance
    .get(`/api/testManagement/test_detail/${id}/`)
    .then(function (response) {
      return response.data;
    });

export const PostTest = async (e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  const clientinfo = await getFromLocalStorage("clientinfo");

  formData.append(
    "english_name",
    postData.english_name ? postData.english_name : ""
  );
  formData.append(
    "device_name",
    postData.device_name ? postData.device_name : ""
  );
  formData.append(
    "report_title",
    postData.report_title ? postData.report_title : ""
  );
  formData.append("sample", postData.sample ? postData.sample : "");
  formData.append("symbol", postData.symbol ? postData.symbol : "");
  formData.append("units", postData.units ? postData.units : "");
  formData.append("department", postData.department ? postData.department : "");
  formData.append("group", postData.group ? postData.group : "");
  formData.append("volume", postData.volume ? postData.volume : "");
  formData.append("priority", postData.priority ? postData.priority : "");
  formData.append("time", postData.time ? postData.time : "");
  formData.append("time_type", postData.time_type ? postData.time_type : "");
  formData.append("is_prepration", postData.is_prepration);
  formData.append("is_active", postData.is_active);
  formData.append("more_result", postData.more_result);
  formData.append(
    "result_type",
    postData.more_result ? postData.result_type : ""
  );
  formData.append("precautons", postData.precautons ? postData.precautons : "");
  formData.append(
    "defult_result",
    postData.defult_result ? postData.defult_result : ""
  );
  formData.append(
    "defult_comment",
    postData.defult_comment ? postData.defult_comment : ""
  );
  formData.append("client", clientinfo ? clientinfo.id : null);
  axiosInstance.post(`api/testManagement/test/`, formData);
};

export const EditTest = (id, e, postData) => {
  debugger;
  e.preventDefault();
  let formData = new FormData();
  formData.append(
    "english_name",
    postData.english_name ? postData.english_name : ""
  );
  formData.append(
    "device_name",
    postData.device_name ? postData.device_name : ""
  );
  formData.append(
    "report_title",
    postData.report_title ? postData.report_title : ""
  );
  formData.append("sample", postData.sample ? postData.sample : "");
  formData.append("symbol", postData.symbol ? postData.symbol : "");
  formData.append("units", postData.units ? postData.units : "");
  formData.append("department", postData.department ? postData.department : "");
  formData.append("group", postData.group ? postData.group : "");
  formData.append("volume", postData.volume ? postData.volume : "");
  formData.append("priority", postData.priority ? postData.priority : "");
  formData.append("time", postData.time ? postData.time : "");
  formData.append("time_type", postData.time_type ? postData.time_type : "");
  formData.append("is_prepration", postData.is_prepration);
  formData.append("is_active", postData.is_active);
  formData.append("more_result", postData.more_result);
  formData.append(
    "result_type",
    postData.more_result ? postData.result_type : ""
  );
  formData.append("precautons", postData.precautons ? postData.precautons : "");
  formData.append(
    "defult_result",
    postData.defult_result ? postData.defult_result : ""
  );
  formData.append(
    "defult_comment",
    postData.defult_comment ? postData.defult_comment : ""
  );
  axiosInstance.put(`api/testManagement/test_detail/${id}/`, formData);
};

export const DeleteTest = (id) =>
  axiosInstance.delete(`/api/testManagement/test_detail/${id}/`);

export const PostNormal = (e, postData, test) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("gender", postData.gender ? postData.gender : "");
  formData.append("from_age", postData.from_age ? postData.from_age : "");
  formData.append("to_age", postData.to_age ? postData.to_age : "");
  formData.append("test", test ? test : "");
  formData.append("age_type", postData.age_type ? postData.age_type : "");
  formData.append(
    "normal_value",
    postData.normal_value ? postData.normal_value : ""
  );
  formData.append(
    "lower_limit",
    postData.lower_limit ? postData.lower_limit : ""
  );
  formData.append(
    "upper_limit",
    postData.upper_limit ? postData.upper_limit : ""
  );
  formData.append(
    "lower_comment",
    postData.lower_comment ? postData.lower_comment : ""
  );
  formData.append(
    "upper_comment",
    postData.upper_comment ? postData.upper_comment : ""
  );
  axiosInstance.post(`api/testManagement/normal/`, formData);
};

export const EditNormal = (e, postData, id) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("gender", postData.gender ? postData.gender : "");
  formData.append("from_age", postData.from_age ? postData.from_age : "");
  formData.append("to_age", postData.to_age ? postData.to_age : "");
  formData.append("test", postData.test ? postData.test : "");
  formData.append("age_type", postData.age_type ? postData.age_type : "");
  formData.append(
    "normal_value",
    postData.normal_value ? postData.normal_value : ""
  );
  formData.append(
    "lower_limit",
    postData.lower_limit ? postData.lower_limit : ""
  );
  formData.append(
    "upper_limit",
    postData.upper_limit ? postData.upper_limit : ""
  );
  formData.append(
    "lower_comment",
    postData.lower_comment ? postData.lower_comment : ""
  );
  formData.append(
    "upper_comment",
    postData.upper_comment ? postData.upper_comment : ""
  );
  axiosInstance.put(`api/testManagement/normal_detail/${id}/`, formData);
};

export const GetNormalDetails = (id) =>
  axiosInstance
    .get(`/api/testManagement/normal_detail/${id}/`)
    .then(function (response) {
      return response.data;
    });

export const DeleteNormal = (id) =>
  axiosInstance.delete(`/api/testManagement/normal_detail/${id}/`);
