import {
  Button,
  Container,
  // FormHelperText,
  MenuItem,
  Modal,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import {
  useStyles,
  DateInputProps,
  ReadOnlyInputProps,
} from "./ComponentsStyle/ModalStyle";
import BaseAutoComplete from "../../../app/generalComponent/BaseAutoComplete";
import { FormattedMessage } from "react-intl";

const initialFValues = {
  id: "",
  patient_name: "",
  gender: "",
  age: "",
  age_type: "",
  phone_number: "",
  email: "",
  customer_pricing_type: "",
  customer_pricing_type_textInput: "",
  address: "",
  medical_info: "",
};

export const ViewModal = ({ data, open, setOpen }) => {
  const classes = useStyles();

  const { values, setValues, resetForm } = GeneralInputForm(
    initialFValues,
    null,
    false,
    false,
    null,
    null
  );

  useEffect(() => {
    if (data !== null) {
      setValues(data);
    }
  }, [data, setValues]);

  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
                resetForm();
              }}
            >
              <FormattedMessage id="close" />
            </Button>
          </div>
          <Form className={classes.form}>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={ReadOnlyInputProps}
                size="small"
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="patient_name" />}
                name="patient_name"
                value={values.patient_name ? values.patient_name : ""}
              />
              <TextField
                id="outlined-select"
                select
                variant="filled"
                size="small"
                InputProps={ReadOnlyInputProps}
                style={{ width: "20%", marginInline: 5 }}
                label={<FormattedMessage id="gender" />}
                name="gender"
                value={values.gender ? values.gender : ""}
                defaultValue={1}
              >
                {/* <MenuItem value={0}>
                  <em>None</em>
                </MenuItem> */}
                <MenuItem value={"Male"}>
                  <FormattedMessage id="Male" />
                </MenuItem>
                <MenuItem value={"Female"}>
                  <FormattedMessage id="Female" />
                </MenuItem>
                {/* {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
              </TextField>
              <TextField
                id="outlined-number"
                variant="filled"
                size="small"
                type="number"
                InputProps={ReadOnlyInputProps}
                style={{ width: "20%", marginInline: 5 }}
                label={<FormattedMessage id="age" />}
                name="age"
                value={values.age ? values.age : ""}
              />
              <TextField
                id="outlined-select"
                select
                variant="filled"
                size="small"
                InputProps={ReadOnlyInputProps}
                style={{ width: "20%", marginInline: 5 }}
                label={<FormattedMessage id="age_type" />}
                name="age_type"
                value={values.age_type ? values.age_type : ""}
                defaultValue={1}
              >
                {/* <MenuItem value={0}>
                  <em>None</em>
                </MenuItem> */}
                <MenuItem value={"Years"}>Years</MenuItem>
                <MenuItem value={"Week"}>Week</MenuItem>
                <MenuItem value={"Day"}>Day</MenuItem>
                {/* {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
              </TextField>
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                size="small"
                InputProps={ReadOnlyInputProps}
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="phone_number" />}
                name="phone_number"
                value={values.phone_number ? values.phone_number : ""}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                size="small"
                InputProps={ReadOnlyInputProps}
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="email" />}
                name="email"
                value={values.email ? values.email : ""}
              />
            </div>

            <div className={classes.withOutSpaceRow}>
              <BaseAutoComplete
                label={<FormattedMessage id="customer_pricing_type" />}
                name="customer_pricing_type"
                variant="filled"
                size="small"
                customStyle={{ width: "90%", marginInlineEnd: 5 }}
                optionLable="pricingtype"
                options={[]}
                fetchData={() => {}}
                inputValue={
                  values.customer_pricing_type_textInput
                    ? values.customer_pricing_type_textInput.toString()
                    : ""
                }
                onSelectChange={async (newValue) => {}}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={ReadOnlyInputProps}
                style={{ width: "100%" }}
                label={<FormattedMessage id="medical_info" />}
                name="medical_info"
                value={values.medical_info ? values.medical_info : ""}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={ReadOnlyInputProps}
                style={{ width: "100%" }}
                label={<FormattedMessage id="address" />}
                name="address"
                value={values.address ? values.address : ""}
              />
            </div>
          </Form>
        </Container>
      </Modal>
    </Fragment>
  );
};
