import axiosInstance from "../../app/axiosInstance";

export const GetClientInfo = (id) =>
  axiosInstance
    .get(`/api/clientinfo/client_detail/${id}`)
    .then(function (response) {
      return response.data;
    });

export const EditClientInfo = (e, postData, id) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("branch_name", postData.branch_name);
  if (postData.header_image) {
    formData.append("header_image", postData.header_image);
  }
  if (postData.footer_image) {
    formData.append("footer_image", postData.footer_image);
  }

  return axiosInstance
    .put(`/api/clientinfo/client_detail/${id}/`, formData)
    .then(function (response) {
      console.log(response);
      return { success: true, data: response };
    })
    .catch(function (error) {
      console.log(error.response);
      return { success: false, data: error.response };
    });
};

export const PostClientInfo = (e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("branch_name", postData.branch_name);
  if (postData.header_image) {
    formData.append("header_image", postData.header_image);
  }
  if (postData.footer_image) {
    formData.append("footer_image", postData.footer_image);
  }

  return axiosInstance
    .post(`/api/clientinfo/client/`, formData)
    .then(function (response) {
      console.log(response);
      return { success: true, data: response };
    })
    .catch(function (error) {
      console.log(error.response);
      return { success: false, data: error.response };
    });
};

export const DeleteClient = (id) =>
  axiosInstance.delete(`/api/clientinfo/client_detail/${id}/`);
