import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
// import { Copyright } from "../../app/generalComponent/Copyright";
import { InputProps, useStyles } from "./styles/LoginPageStyle";
import { GeneralInputForm } from "../../app/generalComponent/GeneralInputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  Login,
  loginAndThenGetClientInfo,
  selectError,
  selectLoading,
} from "./AuthSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { getFromLocalStorage } from "../../app/Utility/CacheStore";
import { useEffect } from "react";

// import { useContext } from "react";

const initialFValues = {
  username: "",
  password: "",
  remember_me: false,
};
export default function LoginPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isLoading = useSelector(selectLoading);
  const error = useSelector(selectError);
  // const [clientOptions, setClientOptions] = React.useState([]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("username" in fieldValues)
      temp.username = fieldValues.username ? "" : "This field is required.";
    if ("password" in fieldValues)
      temp.password = fieldValues.password ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
    convertToDefEventPara,
  } = GeneralInputForm(initialFValues, null, true, false, validate, null);

  const setLoggedInUser = async () => {
    const token = await getFromLocalStorage("token");
    const user = await getFromLocalStorage("user");
    const clientinfo = await getFromLocalStorage("clientinfo");
    if (token) {
      await dispatch(
        Login({ token: token, user: JSON.parse(user), clientinfo: clientinfo })
      );
      navigate("/");
    }
  };
  useEffect(() => {
    async function CheckUser() {
      await setLoggedInUser();
    }
    CheckUser();
  }, []);

  // const fetchClientAutoCompleteData = async (search = "") => {
  //   const response = await GetClientAutoComplete(search);
  //   const data = await response.data;
  //   console.log(data);
  //   setClientOptions(data);
  // };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        square
        elevation={6}
        component={Paper}
        className={classes.login}
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {isLoading ? (
            <Typography>signing in...</Typography>
          ) : (
            <form className={classes.form} noValidate>
              <TextField
                id="email"
                variant="filled"
                InputProps={InputProps}
                fullWidth
                margin="normal"
                size="small"
                label="Mail Address / Username"
                name="username"
                autoComplete="email"
                required
                value={values.username}
                onChange={handleInputChange}
                {...(errors.username && {
                  error: true,
                  helperText: errors.username,
                })}
              />
              <TextField
                id="password"
                variant="filled"
                InputProps={InputProps}
                fullWidth
                margin="normal"
                size="small"
                label="Password"
                type="password"
                name="password"
                autoComplete="current-password"
                required
                value={values.password}
                onChange={handleInputChange}
                {...(errors.password && {
                  error: true,
                  helperText: errors.password,
                })}
              />
              {/* <BaseAutoComplete
              label="Branch"
              name="clientInfo"
              variant="filled"
              size="small"
              customStyle={{ width: "100%", marginBlock: 10, marginInline: 5 }}
              options={clientOptions}
              optionLable="branch_name"
              fetchData={fetchClientAutoCompleteData}
              inputValue={
                values.base_currency ? values.base_currency.currency_name : ""
              }
              onSelectChange={async (newValue) => {
                await handleInputChange(
                  convertToDefEventPara(
                    "clientInfo",
                    newValue ? newValue : null
                  )
                );
              }}
            /> */}
              {/* <FormControlLabel
              style={{ width: "100%", marginInline: 5 }}
              control={
                <Switch
                  name="remember_me"
                  checked={values.remember_me}
                  onChange={(e) =>
                    handleInputChange(
                      convertToDefEventPara("remember_me", e.target.checked)
                    )
                  }
                  color="primary"
                />
              }
              label="Remember me"
            /> */}
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => {
                  console.log(values);
                  dispatch(
                    loginAndThenGetClientInfo({
                      username: values.username,
                      password: values.password,
                      remember_me: values.remember_me,
                    })
                  ).then(() => {
                    if (location.state?.form) {
                      navigate(location.state.form);
                    } else {
                      navigate("/");
                    }
                  });
                }}
              >
                Sign In
              </Button>
              {error !== "" && (
                <Typography style={{ color: "red" }}>{error}</Typography>
              )}
              {/* <Link href="#" variant="body2">
              Forgot password?
            </Link> */}
              {/* 
            <Box mt={5}>
              <Copyright />
            </Box> */}
            </form>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
