import {
  Button,
  Container,
  Modal,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import {
  Form,
  GeneralInputForm,
} from "../../../app/generalComponent/GeneralInputForm";
import { InputProps, useStyles } from "./ComponentsStyle/ModalStyle";
import MuiAlert from "@material-ui/lab/Alert";
import { PostStoolResult, UpdateStoolResult } from "../CustomerTestResultAPI";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFValues = {};

export const StoolExam = ({
  open,
  setOpen,
  result,
  invoice_id,
  loadRefreshData,
}) => {
  const classes = useStyles();
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("x" in fieldValues)
      temp.x = fieldValues.x ? "" : "Patient field is required.";

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    GeneralInputForm(initialFValues, null, true, false, validate, null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };

  useEffect(() => {
    setValues(result);
    setInvoiceId(invoice_id);
  }, [result]);

  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <Form className={classes.form}>
            <h3>Stool Examination</h3>
            <div>
              <h5>Physical Examination</h5>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Colour"
                  name="colour"
                  value={values.colour}
                  required
                  onChange={handleInputChange}
                  {...(errors.colour && {
                    error: true,
                    helperText: errors.colour,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Consistency"
                  name="consistency"
                  value={values.consistency}
                  required
                  onChange={handleInputChange}
                  {...(errors.consistency && {
                    error: true,
                    helperText: errors.consistency,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Reaction"
                  name="reaction"
                  value={values.reaction}
                  required
                  onChange={handleInputChange}
                  {...(errors.reaction && {
                    error: true,
                    helperText: errors.reaction,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Food Particles"
                  name="food_particles"
                  value={values.food_particles}
                  required
                  onChange={handleInputChange}
                  {...(errors.food_particles && {
                    error: true,
                    helperText: errors.food_particles,
                  })}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Odor"
                  name="odor"
                  value={values.odor}
                  required
                  onChange={handleInputChange}
                  {...(errors.odor && {
                    error: true,
                    helperText: errors.odor,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Blood"
                  name="blood"
                  value={values.blood}
                  required
                  onChange={handleInputChange}
                  {...(errors.blood && {
                    error: true,
                    helperText: errors.blood,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Mucus"
                  name="mucus"
                  value={values.mucus}
                  required
                  onChange={handleInputChange}
                  {...(errors.mucus && {
                    error: true,
                    helperText: errors.mucus,
                  })}
                />
              </div>
            </div>

            <div>
              <h5>Microscopic Examination</h5>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Pus Cells"
                  name="pus_cells"
                  value={values.pus_cells}
                  required
                  onChange={handleInputChange}
                  {...(errors.pus_cells && {
                    error: true,
                    helperText: errors.pus_cells,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Flagellates"
                  name="flagellates"
                  value={values.flagellates}
                  required
                  onChange={handleInputChange}
                  {...(errors.flagellates && {
                    error: true,
                    helperText: errors.flagellates,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="RBCs"
                  name="rbcs"
                  value={values.rbcs}
                  required
                  onChange={handleInputChange}
                  {...(errors.rbcs && {
                    error: true,
                    helperText: errors.rbcs,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Undigested Food"
                  name="undigested_food_1"
                  value={values.undigested_food_1}
                  required
                  onChange={handleInputChange}
                  {...(errors.undigested_food_1 && {
                    error: true,
                    helperText: errors.undigested_food_1,
                  })}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Undigested Food"
                  name="undigested_food_2"
                  value={values.undigested_food_2}
                  required
                  onChange={handleInputChange}
                  {...(errors.undigested_food_2 && {
                    error: true,
                    helperText: errors.undigested_food_2,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Undigested Food"
                  name="undigested_food_3"
                  value={values.undigested_food_3}
                  required
                  onChange={handleInputChange}
                  {...(errors.undigested_food_3 && {
                    error: true,
                    helperText: errors.undigested_food_3,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Trophozoite"
                  name="trophozoite"
                  value={values.trophozoite}
                  required
                  onChange={handleInputChange}
                  {...(errors.trophozoite && {
                    error: true,
                    helperText: errors.trophozoite,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Cycts"
                  name="cycts"
                  value={values.cycts}
                  required
                  onChange={handleInputChange}
                  {...(errors.cycts && {
                    error: true,
                    helperText: errors.cycts,
                  })}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Ova"
                  name="ova"
                  value={values.ova}
                  required
                  onChange={handleInputChange}
                  {...(errors.ova && {
                    error: true,
                    helperText: errors.ova,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Starch"
                  name="starch"
                  value={values.starch}
                  required
                  onChange={handleInputChange}
                  {...(errors.starch && {
                    error: true,
                    helperText: errors.starch,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Larve"
                  name="larve"
                  value={values.larve}
                  required
                  onChange={handleInputChange}
                  {...(errors.larve && {
                    error: true,
                    helperText: errors.larve,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Parasitology Artifact"
                  name="parasitology_artifact"
                  value={values.parasitology_artifact}
                  required
                  onChange={handleInputChange}
                  {...(errors.parasitology_artifact && {
                    error: true,
                    helperText: errors.parasitology_artifact,
                  })}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Other"
                  name="other"
                  value={values.other}
                  required
                  onChange={handleInputChange}
                  {...(errors.other && {
                    error: true,
                    helperText: errors.other,
                  })}
                />
              </div>
              <div className={classes.spaceAroundRow}>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  minRows={3}
                  variant="filled"
                  size="small"
                  InputProps={InputProps}
                  style={{ width: "100%" }}
                  label="Comment"
                  name="comment"
                  value={values.comment}
                  onChange={handleInputChange}
                  {...(errors.comment && {
                    error: true,
                    helperText: errors.comment,
                  })}
                />
              </div>
            </div>
            <div className={classes.spaceBetweenRow}>
              <div>
                <Button
                  variant="contained"
                  style={{ marginInline: 20, marginBlockEnd: 10 }}
                  onClick={(event) => {
                    console.log(values);
                    console.log(invoiceId);
                    if (validate(values)) {
                      if (values.id === undefined) {
                        PostStoolResult(event, values, invoiceId);
                      } else {
                        UpdateStoolResult(event, values, invoiceId);
                      }
                      loadRefreshData(invoiceId);
                      setOpenSuccessAlert(true);
                    } else {
                      setOpenErrorAlert(true);
                    }
                  }}
                >
                  Save
                </Button>
                <Button
                  style={{ marginBlockEnd: 10 }}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          </Form>
        </Container>
      </Modal>
      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
