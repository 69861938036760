import { createTheme } from "@material-ui/core";
import Cookies from "js-cookie";

const storedLocale = Cookies.get("locale") || "en";

export const Customtheme = createTheme({
  direction: storedLocale === "ar" ? "rtl" : "ltr",
  palette: {
    primary: {
      main: "#D45D4A",
    },
  },
});
