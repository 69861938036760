import axiosInstance from "../../app/axiosInstance";

export const GetUserDetails = (id) => {
  return axiosInstance
    .get(`/api/user/staff_user/${id}/`)
    .then(function (response) {
      console.log(response);
      return { success: true, data: response.data };
    })
    .catch(function (error) {
      console.log(error.response);
      return { success: false, data: error.response };
    });
};

export const PostStaff = async (e, postData) => {
  e.preventDefault();

  let groups = [];
  for (var item of postData.groups) {
    groups = groups.concat(item.id);
  }
  let permissions = [];
  for (var item2 of postData.user_permissions) {
    permissions = permissions.concat(item2.id);
  }
  let formData = new FormData();

  formData.append("email", postData.email);
  formData.append("password", postData.password);
  formData.append("full_name", postData.full_name);
  formData.append("is_staff", postData.is_staff);
  formData.append("is_active", postData.is_active);
  formData.append("is_superuser", postData.is_superuser);
  return axiosInstance
    .post(`api/user/staff_user/`, formData)
    .then(function (response) {
      console.log(response.data);
      return { success: true, data: response.data };
    })
    .catch(function (error) {
      console.log(error.response.data);
      return { success: false, data: error.response.data };
    });
};

export const UpdateStaff = async (e, postData) => {
  e.preventDefault();

  let groups = [];
  for (var item of postData.groups) {
    groups = groups.concat(item.id);
  }
  let permissions = [];
  for (var item2 of postData.user_permissions) {
    permissions = permissions.concat(item2.id);
  }
  let formData = {
    email: postData.email,
    password: postData.password ? postData.password : "",
    full_name: postData.full_name,
    is_staff: postData.is_staff,
    is_active: postData.is_active,
    is_superuser: postData.is_superuser,
    groups: groups,
    user_permissions: permissions,
  };

  return axiosInstance
    .put(`api/user/staff_user/${postData.id}/`, JSON.stringify(formData), {
      headers: { "Content-Type": "application/json" },
    })
    .then(function (response) {
      console.log(response.data);
      return { success: true, data: response.data };
    })
    .catch(function (error) {
      console.log(error.response.data);
      return { success: false, data: error.response.data };
    });
};

export const DeleteUser = (id) =>
  axiosInstance.delete(`/api/user/staff_user/${id}/`);
