import axiosInstance from "../../app/axiosInstance";

export const GetPatientRegistration = (per_page, page, search = "", client) =>
  axiosInstance.get(
    `/api/customerregistration/RegistrationWithResult/?per_page=${per_page}&page=${page}&search=${search}&client=${client}`
  );

export const GetPatientRegistrationDetails = (id) =>
  axiosInstance.get(
    `/api/customerregistration/RegistrationWithResult_detail/${id}/`
  );

export const PostAllResult = (e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("result", postData.result);
  formData.append("test_name", postData.test_name);
  formData.append("invoice_id", postData.invoice_id);
  axiosInstance.post(`api/CustomerTestResult/allresult_by_english_name/`, formData);
};

export const UpdateAllResult = (e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("result", postData.result);
  formData.append("test_name", postData.test_name);
  formData.append("invoice_id", postData.invoice_id);
  axiosInstance.put(
    `api/CustomerTestResult/allresult_detail/${postData.id}/`,
    formData
  );
};

export const PostUrineResult = (e, postData, invoice_id) => {
  e.preventDefault();
  debugger;
  let formData = new FormData();
  formData.append("acetone", postData.acetone);
  formData.append("amorphous", postData.amorphous);
  formData.append("aspect", postData.aspect);
  formData.append("bacteria", postData.bacteria);
  formData.append("bilirubin", postData.bilirubin);
  formData.append("blood", postData.blood);
  formData.append("cast", postData.cast);
  formData.append("colour", postData.colour);
  formData.append("comment", postData.comment);
  formData.append("crystal_1", postData.crystal_1);
  formData.append("crystal_2", postData.crystal_2);
  formData.append("epithelial", postData.epithelial);
  formData.append("fungi", postData.fungi);
  formData.append("glucose", postData.glucose);
  formData.append("leucocytes", postData.leucocytes);
  formData.append("mucus", postData.mucus);
  formData.append("nitrite", postData.nitrite);
  formData.append("other", postData.other);
  formData.append("ova", postData.ova);
  formData.append("protein", postData.protein);
  formData.append("pus_cells", postData.pus_cells);
  formData.append("rbcs", postData.rbcs);
  formData.append("reaction", postData.reaction);
  formData.append("sp_gravity", postData.sp_gravity);
  formData.append("urobilinogen", postData.urobilinogen);
  formData.append("volume", postData.volume);
  formData.append("invoice_id", invoice_id);
  axiosInstance.post(`api/CustomerTestResult/urineresult/`, formData);
};

export const UpdateUrineResult = (e, postData, invoice_id) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("acetone", postData.acetone);
  formData.append("amorphous", postData.amorphous);
  formData.append("aspect", postData.aspect);
  formData.append("bacteria", postData.bacteria);
  formData.append("bilirubin", postData.bilirubin);
  formData.append("blood", postData.blood);
  formData.append("cast", postData.cast);
  formData.append("colour", postData.colour);
  formData.append("comment", postData.comment);
  formData.append("crystal_1", postData.crystal_1);
  formData.append("crystal_2", postData.crystal_2);
  formData.append("epithelial", postData.epithelial);
  formData.append("fungi", postData.fungi);
  formData.append("glucose", postData.glucose);
  formData.append("leucocytes", postData.leucocytes);
  formData.append("mucus", postData.mucus);
  formData.append("nitrite", postData.nitrite);
  formData.append("other", postData.other);
  formData.append("ova", postData.ova);
  formData.append("protein", postData.protein);
  formData.append("pus_cells", postData.pus_cells);
  formData.append("rbcs", postData.rbcs);
  formData.append("reaction", postData.reaction);
  formData.append("sp_gravity", postData.sp_gravity);
  formData.append("urobilinogen", postData.urobilinogen);
  formData.append("volume", postData.volume);
  formData.append("invoice_id", invoice_id);
  axiosInstance.put(
    `api/CustomerTestResult/urineresult_detail/${postData.id}/`,
    formData
  );
};
export const PostStoolResult = (e, postData, invoice_id) => {
  e.preventDefault();
  debugger;
  let formData = new FormData();
  formData.append("blood", postData.blood);
  formData.append("colour", postData.colour);
  formData.append("comment", postData.comment);
  formData.append("consistency", postData.consistency);
  formData.append("cycts", postData.cycts);
  formData.append("flagellates", postData.flagellates);
  formData.append("food_particles", postData.food_particles);
  formData.append("larve", postData.larve);
  formData.append("mucus", postData.mucus);
  formData.append("odor", postData.odor);
  formData.append("other", postData.other);
  formData.append("ova", postData.ova);
  formData.append("parasitology_artifact", postData.parasitology_artifact);
  formData.append("pus_cells", postData.pus_cells);
  formData.append("rbcs", postData.rbcs);
  formData.append("reaction", postData.reaction);
  formData.append("starch", postData.starch);
  formData.append("trophozoite", postData.trophozoite);
  formData.append("undigested_food_1", postData.undigested_food_1);
  formData.append("undigested_food_2", postData.undigested_food_2);
  formData.append("undigested_food_3", postData.undigested_food_3);
  formData.append("invoice_id", invoice_id);
  axiosInstance.post(`api/CustomerTestResult/stoolresult/`, formData);
};

export const UpdateStoolResult = (e, postData, invoice_id) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("blood", postData.blood);
  formData.append("colour", postData.colour);
  formData.append("comment", postData.comment);
  formData.append("consistency", postData.consistency);
  formData.append("cycts", postData.cycts);
  formData.append("flagellates", postData.flagellates);
  formData.append("food_particles", postData.food_particles);
  formData.append("larve", postData.larve);
  formData.append("mucus", postData.mucus);
  formData.append("odor", postData.odor);
  formData.append("other", postData.other);
  formData.append("ova", postData.ova);
  formData.append("parasitology_artifact", postData.parasitology_artifact);
  formData.append("pus_cells", postData.pus_cells);
  formData.append("rbcs", postData.rbcs);
  formData.append("reaction", postData.reaction);
  formData.append("starch", postData.starch);
  formData.append("trophozoite", postData.trophozoite);
  formData.append("undigested_food_1", postData.undigested_food_1);
  formData.append("undigested_food_2", postData.undigested_food_2);
  formData.append("undigested_food_3", postData.undigested_food_3);
  formData.append("invoice_id", invoice_id);
  axiosInstance.put(
    `api/CustomerTestResult/stoolresult_detail/${postData.id}/`,
    formData
  );
};

export const PostCbc3Result = (e, postData, invoice_id) => {
  e.preventDefault();
  debugger;
  let formData = new FormData();
  formData.append("GranH", postData.GranH);
  formData.append("GranM", postData.GranM);
  formData.append("HCT", postData.HCT);
  formData.append("HGB", postData.HGB);
  formData.append("LymH", postData.LymH);
  formData.append("LymM", postData.LymM);
  formData.append("MCH", postData.MCH);
  formData.append("MCHC", postData.MCHC);
  formData.append("MCV", postData.MCV);
  formData.append("MPV", postData.MPV);
  formData.append("MidH", postData.MidH);
  formData.append("MidM", postData.MidM);
  formData.append("PDW", postData.PDW);
  formData.append("PLT", postData.PLT);
  formData.append("Pct", postData.Pct);
  formData.append("RBC", postData.RBC);
  formData.append("RDW_CV", postData.RDW_CV);
  formData.append("RDW_SD", postData.RDW_SD);
  formData.append("WBC", postData.WBC);
  formData.append("comment", postData.comment);
  formData.append("other", postData.other);
  formData.append("invoice_id", invoice_id);
  axiosInstance.post(`api/CustomerTestResult/cbcdiff3result/`, formData);
};

export const UpdateCbc3Result = (e, postData, invoice_id) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("GranH", postData.GranH);
  formData.append("GranM", postData.GranM);
  formData.append("HCT", postData.HCT);
  formData.append("HGB", postData.HGB);
  formData.append("LymH", postData.LymH);
  formData.append("LymM", postData.LymM);
  formData.append("MCH", postData.MCH);
  formData.append("MCHC", postData.MCHC);
  formData.append("MCV", postData.MCV);
  formData.append("MPV", postData.MPV);
  formData.append("MidH", postData.MidH);
  formData.append("MidM", postData.MidM);
  formData.append("PDW", postData.PDW);
  formData.append("PLT", postData.PLT);
  formData.append("Pct", postData.Pct);
  formData.append("RBC", postData.RBC);
  formData.append("RDW_CV", postData.RDW_CV);
  formData.append("RDW_SD", postData.RDW_SD);
  formData.append("WBC", postData.WBC);
  formData.append("comment", postData.comment);
  formData.append("other", postData.other);
  formData.append("invoice_id", invoice_id);
  axiosInstance.put(
    `api/CustomerTestResult/cbcdiff3result_detail/${postData.id}/`,
    formData
  );
};
export const PostCbc5Result = (e, postData, invoice_id) => {
  e.preventDefault();
  debugger;
  let formData = new FormData();
  formData.append("ALYH", postData.ALYH);
  formData.append("ALYM", postData.ALYM);
  formData.append("BASH", postData.BASH);
  formData.append("BASM", postData.BASM);
  formData.append("CRP", postData.CRP);
  formData.append("EOSH", postData.EOSH);
  formData.append("EOSM", postData.EOSM);
  formData.append("HCT", postData.HCT);
  formData.append("HGB", postData.HGB);
  formData.append("Hs_CRP", postData.Hs_CRP);
  formData.append("LICH", postData.LICH);
  formData.append("LICM", postData.LICM);
  formData.append("LymH", postData.LymH);
  formData.append("LymM", postData.LymM);
  formData.append("MCH", postData.MCH);
  formData.append("MCHC", postData.MCHC);
  formData.append("MCV", postData.MCV);
  formData.append("MONH", postData.MONH);
  formData.append("MONM", postData.MONM);
  formData.append("MPV", postData.MPV);
  formData.append("NEUH", postData.NEUH);
  formData.append("NEUM", postData.NEUM);
  formData.append("NRBCH", postData.NRBCH);
  formData.append("NRBCM", postData.NRBCM);
  formData.append("PDW", postData.PDW);
  formData.append("PLT", postData.PLT);
  formData.append("P_LCC", postData.P_LCC);
  formData.append("P_LCR", postData.P_LCR);
  formData.append("Pct", postData.Pct);
  formData.append("RBC", postData.RBC);
  formData.append("RDW_CV", postData.RDW_CV);
  formData.append("RDW_SD", postData.RDW_SD);
  formData.append("WBC", postData.WBC);
  formData.append("comment", postData.comment);
  formData.append("crp_message", postData.crp_message);
  formData.append("plt_message", postData.plt_message);
  formData.append("rbc_message", postData.rbc_message);
  formData.append("wbc_message", postData.wbc_message);
  formData.append("invoice_id", invoice_id);
  axiosInstance.post(`api/CustomerTestResult/cbcdiff5result/`, formData);
};

export const UpdateCbc5Result = (e, postData, invoice_id) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("ALYH", postData.ALYH);
  formData.append("ALYM", postData.ALYM);
  formData.append("BASH", postData.BASH);
  formData.append("BASM", postData.BASM);
  formData.append("CRP", postData.CRP);
  formData.append("EOSH", postData.EOSH);
  formData.append("EOSM", postData.EOSM);
  formData.append("HCT", postData.HCT);
  formData.append("HGB", postData.HGB);
  formData.append("Hs_CRP", postData.Hs_CRP);
  formData.append("LICH", postData.LICH);
  formData.append("LICM", postData.LICM);
  formData.append("LymH", postData.LymH);
  formData.append("LymM", postData.LymM);
  formData.append("MCH", postData.MCH);
  formData.append("MCHC", postData.MCHC);
  formData.append("MCV", postData.MCV);
  formData.append("MONH", postData.MONH);
  formData.append("MONM", postData.MONM);
  formData.append("MPV", postData.MPV);
  formData.append("NEUH", postData.NEUH);
  formData.append("NEUM", postData.NEUM);
  formData.append("NRBCH", postData.NRBCH);
  formData.append("NRBCM", postData.NRBCM);
  formData.append("PDW", postData.PDW);
  formData.append("PLT", postData.PLT);
  formData.append("P_LCC", postData.P_LCC);
  formData.append("P_LCR", postData.P_LCR);
  formData.append("Pct", postData.Pct);
  formData.append("RBC", postData.RBC);
  formData.append("RDW_CV", postData.RDW_CV);
  formData.append("RDW_SD", postData.RDW_SD);
  formData.append("WBC", postData.WBC);
  formData.append("comment", postData.comment);
  formData.append("crp_message", postData.crp_message);
  formData.append("plt_message", postData.plt_message);
  formData.append("rbc_message", postData.rbc_message);
  formData.append("wbc_message", postData.wbc_message);
  formData.append("invoice_id", invoice_id);
  axiosInstance.put(
    `api/CustomerTestResult/cbcdiff5result_detail/${postData.id}/`,
    formData
  );
};

export const PostSemenResult = (e, postData, invoice_id) => {
  e.preventDefault();
  debugger;
  let formData = new FormData();
  formData.append("abnormal", postData.abnormal);
  formData.append("agglutination", postData.agglutination);
  formData.append("appearance", postData.appearance);
  formData.append("consistency", postData.consistency);
  formData.append("count_ml", postData.count_ml);
  formData.append("cytoplasmic_droplets", postData.cytoplasmic_droplets);
  formData.append("duration_of_abstinence", postData.duration_of_abstinence);
  formData.append("epithelial_cells", postData.epithelial_cells);
  formData.append("fructose", postData.fructose);
  formData.append("head_defects", postData.head_defects);
  formData.append("headless", postData.headless);
  formData.append("immotile", postData.immotile);
  formData.append(
    "interval_between_start_of_ejaculation_and_analysis",
    postData.interval_between_start_of_ejaculation_and_analysis
  );
  formData.append("liquefaction", postData.liquefaction);
  formData.append("miscellaneous", postData.miscellaneous);
  formData.append("neck_mid_piece_defects", postData.neck_mid_piece_defects);
  formData.append(
    "non_progressive_motility",
    postData.non_progressive_motility
  );
  formData.append("normal", postData.normal);
  formData.append("pus_cells", postData.pus_cells);
  formData.append("rapid_progression", postData.rapid_progression);
  formData.append("red_blood_cells", postData.red_blood_cells);
  formData.append("slow_progression", postData.slow_progression);
  formData.append("tail_defects", postData.tail_defects);
  formData.append("vitality", postData.vitality);
  formData.append("volume", postData.volume);
  formData.append("invoice_id", invoice_id);
  axiosInstance.post(`api/CustomerTestResult/semenresult/`, formData);
};

export const UpdateSemenResult = (e, postData, invoice_id) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("abnormal", postData.abnormal);
  formData.append("agglutination", postData.agglutination);
  formData.append("appearance", postData.appearance);
  formData.append("consistency", postData.consistency);
  formData.append("count_ml", postData.count_ml);
  formData.append("cytoplasmic_droplets", postData.cytoplasmic_droplets);
  formData.append("duration_of_abstinence", postData.duration_of_abstinence);
  formData.append("epithelial_cells", postData.epithelial_cells);
  formData.append("fructose", postData.fructose);
  formData.append("head_defects", postData.head_defects);
  formData.append("headless", postData.headless);
  formData.append("immotile", postData.immotile);
  formData.append(
    "interval_between_start_of_ejaculation_and_analysis",
    postData.interval_between_start_of_ejaculation_and_analysis
  );
  formData.append("liquefaction", postData.liquefaction);
  formData.append("miscellaneous", postData.miscellaneous);
  formData.append("neck_mid_piece_defects", postData.neck_mid_piece_defects);
  formData.append(
    "non_progressive_motility",
    postData.non_progressive_motility
  );
  formData.append("normal", postData.normal);
  formData.append("pus_cells", postData.pus_cells);
  formData.append("rapid_progression", postData.rapid_progression);
  formData.append("red_blood_cells", postData.red_blood_cells);
  formData.append("slow_progression", postData.slow_progression);
  formData.append("tail_defects", postData.tail_defects);
  formData.append("vitality", postData.vitality);
  formData.append("volume", postData.volume);
  formData.append("invoice_id", invoice_id);
  axiosInstance.put(
    `api/CustomerTestResult/semenresult_detail/${postData.id}/`,
    formData
  );
};
