import { Button, Container, Modal, TextField } from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import BaseAutoComplete from "../../../app/generalComponent/BaseAutoComplete";

import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import { useStyles, ReadOnlyInputProps } from "./ComponentsStyle/ModalStyle";
import { FormattedMessage } from "react-intl";

const initialFValues = {
  test: "",
  test_textInput: "",
  pricingtype: "",
  pricingtype_textInput: "",
  price: "",
};

export const ViewModal = ({ data, open, setOpen }) => {
  const classes = useStyles();
  const { values, setValues } = GeneralInputForm(
    initialFValues,
    null,
    false,
    false,
    null,
    null
  );

  useEffect(() => {
    if (data !== null) {
      setValues(data);
    }
  }, [data, setValues]);

  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
              }}
            >
              <FormattedMessage id="close" />
            </Button>
          </div>
          <Form className={classes.form}>
            <div className={classes.withOutSpaceRow}>
              <BaseAutoComplete
                label={<FormattedMessage id="pricingtype" />}
                name="pricingtype"
                variant="filled"
                size="small"
                customStyle={{ width: "100%" }}
                options={[]}
                fetchData={() => {}}
                inputValue={values.pricingtype_textInput}
                onSelectChange={async (newValue) => {
                  // await handleInputChange(
                  //   convertToDefEventPara(
                  //     "pricingtype",
                  //     newValue ? newValue.id : null
                  //   )
                  // );
                }}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <BaseAutoComplete
                label={<FormattedMessage id="test_name" />}
                name="test"
                variant="filled"
                size="small"
                customStyle={{ width: "100%" }}
                options={[]}
                fetchData={() => {}}
                inputValue={values.test_textInput}
                onSelectChange={async (newValue) => {
                  // await handleInputChange(
                  //   convertToDefEventPara("test", newValue ? newValue.id : null)
                  // );
                }}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={ReadOnlyInputProps}
                size="small"
                style={{ width: "100%", marginInline: 5 }}
                label={<FormattedMessage id="price" />}
                name="price"
                value={values.price ? values.price : ""}
                // required
                // onChange={handleInputChange}
                // {...(errors.price && {
                //   error: true,
                //   helperText: errors.price,
                // })}
              />
            </div>
          </Form>
        </Container>
      </Modal>
    </Fragment>
  );
};
