import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

// function sleep(delay = 0) {
//   return new Promise((resolve) => {
//     setTimeout(resolve, delay);
//   });
// }

export default function BaseAutoComplete({
  name,
  onSelectChange,
  customStyle,
  label,
  variant,
  size,
  inputValue,
  fetchData,
  options,
  optionLable,
  subOptionLable = null,
  otherInputProps,
  ...prop
}) {
  const [open, setOpen] = useState(false);
  const [changedInputValue, setChangedInputValue] = useState("");
  const loading = open && options.length < 0;

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Autocomplete
      {...prop}
      name={name}
      onChange={(event, newValue) => {
        onSelectChange(newValue);
      }}
      inputValue={changedInputValue ? changedInputValue : inputValue}
      onInputChange={(event, newInputValue) => {
        fetchData(newInputValue);
        setChangedInputValue(newInputValue);
      }}
      filterOptions={(options, state) => options}
      style={customStyle}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) =>
        subOptionLable
          ? option[optionLable][subOptionLable].toString()
          : option[optionLable].toString()
      }
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          size={size}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            style: otherInputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
// const filterOptions = createFilterOptions({
//     stringify: (option) => option.name + option.id,
//   });
