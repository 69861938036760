import {
  Button,
  Container,
  // FormHelperText,
  MenuItem,
  Modal,
  Snackbar,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import SaveIcon from "@material-ui/icons/Save";
// import DepartmentDialog from "./DepartmentDialog";
// import GroupDialog from "./GroupDialog";
import { GetCurrenciesAutoComplete, PostPayment } from "./PaymentAPI";
import {
  useStyles,
  InputProps,
  DateInputProps,
  ReadOnlyInputProps,
} from "./ComponentsStyle/ModalStyle";
import {
  Form,
  GeneralInputForm,
} from "../../app/generalComponent/GeneralInputForm";
import { useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useSelector } from "react-redux";
import { selectClientInfo } from "../Authentecation/AuthSlice";
import { FormattedMessage } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const initialFValues = {
  id: "",
  payment_date: new Date().toLocaleDateString(),
  payment_currency: "",
  payment_currency_exchange: "",
  payment_amount: "",
  back_currency: "",
  back_currency_exchange: "",
  back_amount: 0,
  loading: false,
};

export const AddPaymentModal = ({
  data,
  customer_payment_info,
  open,
  setOpen,
  refresh,
  tableRef,
}) => {
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const classes = useStyles();
  const client = useSelector(selectClientInfo);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("payment_currency" in fieldValues)
      temp.payment_currency = fieldValues.payment_currency
        ? ""
        : "Patient field is required.";
    if ("payment_currency_exchange" in fieldValues)
      temp.payment_currency_exchange = fieldValues.payment_currency_exchange
        ? ""
        : "Patient field is required.";
    if ("payment_amount" in fieldValues)
      temp.payment_amount = fieldValues.payment_amount
        ? ""
        : "Patient field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    convertToDefEventPara,
    resetForm,
  } = GeneralInputForm(initialFValues, null, true, false, validate, null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };

  const loadCurrencies = async () => {
    const response = await GetCurrenciesAutoComplete(client.id);
    const currencies = await response.data;
    console.log(currencies);
    setCurrencies(currencies);
    let currency = currencies[0].currency_name;
    let currency_exchange = await currencies.find(
      (item) => item.currency_name === currencies[0].currency_name
    )?.currency_for_CurrencyExchange_set[0].currency_exchange;
    setValues({
      ...values,
      payment_currency: currency,
      payment_currency_exchange: currency_exchange,
      back_currency: currency,
      back_currency_exchange: currency_exchange,
    });
  };

  const paymentCurrencyChange = async (e) => {
    let currency_exchange = await currencies.find(
      (item) => item.currency_name === e.target.value
    )?.currency_for_CurrencyExchange_set[0].currency_exchange;
    await setValues({
      ...values,
      payment_currency: e.target.value,
      payment_currency_exchange: currency_exchange,
    });
  };
  const backCurrencyChange = async (e) => {
    let currency_exchange = await currencies.find(
      (item) => item.currency_name === e.target.value
    )?.currency_for_CurrencyExchange_set[0].currency_exchange;
    await setValues({
      ...values,
      back_currency: e.target.value,
      back_currency_exchange: currency_exchange,
    });
  };

  useEffect(() => {
    loadCurrencies();
    console.log(data);
  }, [data]);
  return (
    <Fragment>
      <Modal open={open}>
        <Container
          className={classes.container}
          style={{ width: "75%", height: "75%" }}
        >
          <div className={classes.buttonContainerStyle}>
            {!values.id && (
              <Button
                variant="contained"
                style={{ marginInline: 20 }}
                onClick={async (event) => {
                  try {
                    setValues({ ...values, loading: true });
                    if (validate(values)) {
                      let response = await PostPayment(event, values, data);
                      setOpenSuccessAlert(true);
                      setValues({
                        ...values,
                        loading: false,
                        id: response.data.id,
                      });
                      refresh(data);
                    } else {
                      setOpenErrorAlert(true);
                      setValues({ ...values, loading: false });
                    }
                  } catch (error) {
                    setValues({ ...values, loading: false });
                  }
                }}
                startIcon={<SaveIcon />}
              >
                {values.loading ? (
                  <FormattedMessage id="loading" />
                ) : (
                  <FormattedMessage id="create" />
                )}
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
                setValues({
                  ...values,
                  id: "",
                  payment_amount: "",
                  back_amount: 0,
                });
                const scopedRef = tableRef.current;
                scopedRef.onQueryChange();
                refresh(data);
              }}
            >
              <FormattedMessage id="close" />
            </Button>
          </div>
          <Form className={classes.form}>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-select"
                variant="filled"
                size="small"
                InputProps={ReadOnlyInputProps}
                style={{
                  width: "100%",
                  marginInline: 5,
                }}
                label={<FormattedMessage id="total_amount" />}
                name="total_amount"
                value={
                  customer_payment_info
                    ? customer_payment_info.total_amount
                    : ""
                }
              />
              <TextField
                id="outlined-number"
                variant="filled"
                size="small"
                InputProps={ReadOnlyInputProps}
                style={{
                  width: "100%",
                  marginInline: 5,
                }}
                label={<FormattedMessage id="total_remain" />}
                name="remain_amount"
                value={
                  customer_payment_info
                    ? customer_payment_info.total_remain
                    : ""
                }
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  readOnly
                  fullWidth
                  variant="inline"
                  label={<FormattedMessage id="payment_date" />}
                  inputVariant="filled"
                  InputProps={DateInputProps}
                  format="dd/MM/yyyy"
                  name="payment_date"
                  value={new Date()}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-select"
                select
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%", marginInline: 5 }}
                label={<FormattedMessage id="payment_currency" />}
                name="payment_currency"
                value={values.payment_currency}
                onChange={paymentCurrencyChange}
                {...(errors.payment_currency && {
                  error: true,
                  helperText: errors.payment_currency,
                })}
                defaultValue={1}
              >
                {/* <MenuItem value={0}>
                  <em>None</em>
                </MenuItem> */}
                {currencies.map((option) => (
                  <MenuItem key={option.id} value={option.currency_name}>
                    {option.currency_name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-number"
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%", marginInline: 5 }}
                label={<FormattedMessage id="payment_currency_exchange" />}
                name="payment_currency_exchange"
                value={values.payment_currency_exchange}
                {...(errors.payment_currency_exchange && {
                  error: true,
                  helperText: errors.payment_currency_exchange,
                })}
              />

              <TextField
                id="outlined-number"
                variant="filled"
                size="small"
                type="number"
                InputProps={InputProps}
                style={{
                  width: "100%",
                  marginInline: 5,
                  backgroundColor: "lightblue",
                  borderRadius: 5,
                }}
                label={<FormattedMessage id="payment_amount" />}
                name="payment_amount"
                value={values.payment_amount}
                onChange={handleInputChange}
                {...(errors.payment_amount && {
                  error: true,
                  helperText: errors.payment_amount,
                })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-select"
                select
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%", marginInline: 5 }}
                label={<FormattedMessage id="back_currency" />}
                name="back_currency"
                value={values.back_currency}
                onChange={backCurrencyChange}
                {...(errors.back_currency && {
                  error: true,
                  helperText: errors.back_currency,
                })}
                defaultValue={1}
              >
                {/* <MenuItem value={0}>
                  <em>None</em>
                </MenuItem> */}
                {currencies.map((option) => (
                  <MenuItem key={option.id} value={option.currency_name}>
                    {option.currency_name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-number"
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%", marginInline: 5 }}
                label={<FormattedMessage id="back_currency_exchange" />}
                name="back_currency_exchange"
                value={values.back_currency_exchange}
                {...(errors.back_currency_exchange && {
                  error: true,
                  helperText: errors.back_currency_exchange,
                })}
              />
              <TextField
                id="outlined-number"
                variant="filled"
                size="small"
                type="number"
                InputProps={InputProps}
                style={{
                  width: "100%",
                  marginInline: 5,
                  backgroundColor: "lightblue",
                  borderRadius: 5,
                }}
                label={<FormattedMessage id="back_amount" />}
                name="back_amount"
                value={values.back_amount}
                onChange={handleInputChange}
                {...(errors.back_amount && {
                  error: true,
                  helperText: errors.back_amount,
                })}
              />
            </div>
          </Form>
          <Snackbar
            open={OpenSuccessAlert}
            autoHideDuration={1000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert onClose={handleClose} severity="success">
              Data saved
            </Alert>
          </Snackbar>

          <Snackbar
            open={openErrorAlert}
            autoHideDuration={1000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert onClose={handleClose} severity="error">
              Error saving data
            </Alert>
          </Snackbar>
        </Container>
      </Modal>
    </Fragment>
  );
};
