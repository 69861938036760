import { getFromLocalStorage } from "../../app/Utility/CacheStore";
import axiosInstance from "../../app/axiosInstance";

export const PostGroup = async (e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  const clientinfo = await getFromLocalStorage("clientinfo");

  formData.append("group", postData.group);
  formData.append("client", clientinfo.id);
  
  axiosInstance.post(`api/testManagement/group/`, formData);
};

export const GetGroupDetails = (id) =>
  axiosInstance
    .get(`/api/testManagement/group_detail/${id}/`)
    .then(function (response) {
      return response.data;
    });

export const EditGroup = (id, e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("group", postData.group);
  axiosInstance.put(`api/testManagement/group_detail/${id}/`, formData);
};

export const DeleteGroup = (id) =>
  axiosInstance.delete(`/api/testManagement/group_detail/${id}/`);
