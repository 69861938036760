import { getFromLocalStorage } from "../../app/Utility/CacheStore";
import axiosInstance from "../../app/axiosInstance";

export const GetTestAutoComplete = (search = "", client) =>
  axiosInstance.get(
    `/api/testManagement/test_autoComplete/?search=${search}&client=${client}`
  );
export const GetPricingTypeAutoComplete = (search = "", client) =>
  axiosInstance.get(
    `/api/pricingtype/pricingtype_autoComplete/?search=${search}&client=${client}`
  );
export const PostPricingList = async (e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  const clientinfo = await getFromLocalStorage("clientinfo");

  formData.append("test", postData.test);
  formData.append("pricingtype", postData.pricingtype);
  formData.append("price", postData.price);
  formData.append("client", clientinfo.id);

  axiosInstance.post(`api/pricinglist/pricinglist/`, formData);
};

export const GetPricingListDetails = (id) =>
  axiosInstance
    .get(`/api/pricinglist/pricinglist_detail/${id}/`)
    .then(function (response) {
      return response.data;
    });

export const EditPricingList = (id, e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("test", postData.test);
  formData.append("pricingtype", postData.pricingtype);
  formData.append("price", postData.price);
  axiosInstance.put(`api/pricinglist/pricinglist_detail/${id}/`, formData);
};

export const DeletePricingList = (id) =>
  axiosInstance.delete(`/api/pricinglist/pricinglist_detail/${id}/`);
