// import { useContext } from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { selectToken } from "../../features/Authentecation/AuthSlice";
const PrivateRoutes = () => {
  const token = useSelector(selectToken);
  const location = useLocation();


  return token !== null ? (
    <Outlet />
  ) : (
    <Navigate to="/Login" replace state={{ form: location }} />
  );
};

export default PrivateRoutes;
