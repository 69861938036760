import axiosInstance from "../../app/axiosInstance";
import { getFromLocalStorage } from "../../app/Utility/CacheStore";

export const GetCurrenciesAutoComplete = (client) =>
  axiosInstance.get(
    `/api/currencies/currencies_details_autoComplete/?client=${client}`
  );

export const GetCustomerPaymentInfo = (id) =>
  axiosInstance.get(
    `/api/customerpayments/customerPaymentInfo/?registration_id=${id}`
  );

export const PostPayment = async (e, postData, id) => {
  e.preventDefault();
  const clientinfo = await getFromLocalStorage("clientinfo");
  let formData = new FormData();
  formData.append("registration", id);
  formData.append("payment_currency", postData.payment_currency);
  formData.append(
    "payment_currency_exchange",
    postData.payment_currency_exchange
  );
  formData.append("payment_amount", postData.payment_amount);
  formData.append("back_currency", postData.back_currency);
  formData.append("back_currency_exchange", postData.back_currency_exchange);
  formData.append("back_amount", postData.back_amount);
  formData.append("client", clientinfo.id);
  return axiosInstance.post(`api/customerpayments/customerPayment/`, formData);
};

export const DeletePayment = (id) =>
  axiosInstance.delete(`/api/customerpayments/customerPayment_detail/${id}/`);
