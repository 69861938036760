import { Button, Paper, Snackbar, TextField } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import SaveIcon from "@material-ui/icons/Save";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AddIcon from "@material-ui/icons/Add";
import SubjectIcon from "@material-ui/icons/Subject";
import {
  GeneralInputForm,
  Form,
} from "../../app/generalComponent/GeneralInputForm";
import { useNavigate } from "react-router-dom";
import { InputProps, useStyles } from "./ModalStyle";
import { GetCurrencyAutoComplete } from "../CurrencyExchange/CurrencyExchangeAPI";
import BaseAutoComplete from "../../app/generalComponent/BaseAutoComplete";
import { EditClientInfo, GetClientInfo } from "./SettingApi";
import { baseURL } from "../../app/axiosInstance";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetClientInfoAsync,
  selectClientInfo,
} from "../Authentecation/AuthSlice";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const initialFValues = {
  branch_name: "",
  header_image: null,
  header_image_url: "",
  footer_image: null,
  footer_image_url: "",
};

const SettingPage = () => {
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);

  const classes = useStyles();
  const client = useSelector(selectClientInfo);
  const dispatch = useDispatch();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("branch_name" in fieldValues)
      temp.branch_name = fieldValues.branch_name
        ? ""
        : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    convertToDefEventPara,
    resetForm,
  } = GeneralInputForm(initialFValues, null, true, false, validate, null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };

  async function handleHeaderImageChange(e) {
    await setValues({
      ...values,
      header_image: e.target.files[0] ? e.target.files[0] : "",
      header_image_url: e.target.files[0]
        ? URL.createObjectURL(e.target.files[0])
        : "",
    });
    // handleInputChange(
    //   convertToDefEventPara(
    //     "header_image",
    //     e.target.files[0] ? e.target.files[0] : ""
    //   )
    // );
    // handleInputChange(
    //   convertToDefEventPara(
    //     "header_image_url",
    //     e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : ""
    //   )
    // );
    console.log(e.target.files);
    console.log(URL.createObjectURL(e.target.files[0]));
  }
  async function handleFooterImageChange(e) {
    await setValues({
      ...values,
      footer_image: e.target.files[0] ? e.target.files[0] : "",
      footer_image_url: e.target.files[0]
        ? URL.createObjectURL(e.target.files[0])
        : "",
    });
    // handleInputChange(
    //   convertToDefEventPara(
    //     "footer_image",
    //     e.target.files[0] ? e.target.files[0] : ""
    //   )
    // );
    // handleInputChange(
    //   convertToDefEventPara(
    //     "footer_image_url",
    //     e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : ""
    //   )
    // );
    console.log(e.target.files);
    console.log(URL.createObjectURL(e.target.files[0]));
  }

  const loadDetails = async () => {
    const response = await GetClientInfo(client?.id);
    const apiResultValues = {
      id: response.id,
      branch_name: response.branch_name,
      header_image_url: baseURL + response.header_image.slice(1),
      footer_image_url: baseURL + response.footer_image.slice(1),
    };
    // console.log(response);
    setValues(apiResultValues);
    console.log(apiResultValues);
  };

  useEffect(() => {
    loadDetails();
  }, []);

  return (
    <Fragment>
      <Paper>
        <Form className={classes.form}>
          <div className={classes.spaceAroundRow}>
            <TextField
              id="outlined-basic"
              variant="filled"
              fullWidth
              InputProps={InputProps}
              size="small"
              label="Branch Name"
              name="branch_name"
              value={values.branch_name}
              required
              onChange={handleInputChange}
              {...(errors.branch_name && {
                error: true,
                helperText: errors.branch_name,
              })}
            />
          </div>
          <div className={classes.withOutSpaceRow}>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              // multiple
              onChange={handleHeaderImageChange}
              type="file"
            />
            <label htmlFor="contained-button-file">
              <Button
                style={{ marginInline: 20 }}
                variant="contained"
                component="span"
                color="default"
                startIcon={<CloudUploadIcon />}
              >
                Upload Print Header Page
              </Button>
            </label>
          </div>
          <div className={classes.withOutSpaceRow}>
            <img
              alt="slider img"
              src={
                values.header_image_url !== ""
                  ? values.header_image_url
                  : require("../../app/images/empty_image.jpg")
              }
              style={{ objectFit: "contain" }}
              width={"100%"}
              height={300}
            />
          </div>
          <div className={classes.withOutSpaceRow}>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-footer-button-file"
              // multiple
              onChange={handleFooterImageChange}
              type="file"
            />
            <label htmlFor="contained-footer-button-file">
              <Button
                style={{ marginInline: 20 }}
                variant="contained"
                component="span"
                color="default"
                startIcon={<CloudUploadIcon />}
              >
                Upload Print Footer Page
              </Button>
            </label>
          </div>
          <div className={classes.withOutSpaceRow}>
            <img
              alt="slider img"
              src={
                values.footer_image_url !== ""
                  ? values.footer_image_url
                  : require("../../app/images/empty_image.jpg")
              }
              style={{ objectFit: "contain" }}
              width={"100%"}
              height={300}
            />
          </div>
          <div className={classes.withOutSpaceRow}>
            <Button
              variant="contained"
              style={{ marginInline: 20 }}
              onClick={async (event) => {
                console.log(values);
                if (validate(values)) {
                  await EditClientInfo(event, values, client?.id);
                  await dispatch(GetClientInfoAsync(client?.id));
                  setOpenSuccessAlert(true);
                } else {
                  setOpenErrorAlert(true);
                }
              }}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </div>
        </Form>
      </Paper>
      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default SettingPage;
