import React, { useState } from "react";

export function GeneralInputForm(
  initialFValues,
  initialAddNewValues,
  validateOnChange = false,
  validateAddOnChange = false,
  validate,
  validateAddNew,
) {
  const [values, setValues] = useState(initialFValues);
  const [addNewValues, setAddNewValues] = useState(initialAddNewValues);
  const [errors, setErrors] = useState({});
  const [addErrors, setAddErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };
  const handleAddNewInputChange = (e) => {
    const { name, value } = e.target;
    setAddNewValues({
      ...addNewValues,
      [name]: value,
    });
    if (validateAddOnChange) validateAddNew({ [name]: value });
  };
  const handleInputListChange = (event, index, listName) => {
    if (event.target.value.length === 0) {
      return;
    }
    // const index = values[listName].findIndex((item) => {
    //   return item.id === id;
    // });
    const item = Object.assign({}, values[listName][index]);
    item[event.target.name] = event.target.value;

    const items = Object.assign([], values[listName]);
    items[index] = item;

    handleInputChange(convertToDefEventPara(listName, items));
  };

  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });
  const resetForm = () => {
    setValues(initialFValues);
    setAddNewValues(initialAddNewValues);
    setErrors({});
    setAddErrors({});
  };

  return {
    values,
    addNewValues,
    setAddNewValues,
    setValues,
    errors,
    setErrors,
    addErrors,
    setAddErrors,
    handleInputChange,
    handleAddNewInputChange,
    convertToDefEventPara,
    handleInputListChange,
    resetForm,
  };
}

export function Form(props) {
  const { children, ...other } = props;
  return (
    <form autoComplete="off" {...other}>
      {props.children}
    </form>
  );
}
