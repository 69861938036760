import {
  Button,
  Container,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Snackbar,
  Tab,
  Tabs,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";

import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import MuiAlert from "@material-ui/lab/Alert";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import {
  useStyles,
  InputProps,
  InputlistProps,
  DateInputProps,
  ReadOnlyInputlistProps,
} from "./ComponentsStyle/ModalStyle";
import BaseAutoComplete from "../../../app/generalComponent/BaseAutoComplete";
import {
  GetCurrenciesAutoComplete,
  GetPurchaseInvoiceAutoComplete,
  GetTestAutoComplete,
  GetTestQtyAutoComplete,
  PostPurchase,
} from "../PurchaseReturnsApi";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useEffect } from "react";
import { GetVenderAutoComplete } from "../../JournalEntries/JournalEntriesAPI";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectClientInfo } from "../../Authentecation/AuthSlice";
import { FormattedMessage } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFValues = {
  description: "",
  invoice_date: new Date().toLocaleDateString(),
  vender: "",
  currencey: "",
  currencey_exchange: "",
  purchase_invoice: "",
  purchase_return_items: [],
  total_amount: "",
};
const initialAddNewValues = {
  test: "",
  price: "",
  qty: "",
  total_amount: "",
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ marginBlock: 20 }}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export const ViewModal = ({ data, open, setOpen }) => {
  const classes = useStyles();
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [testOptions, setTestOptions] = React.useState([]);
  const [venderOptions, setVenderOptions] = React.useState([]);
  const [purchaseInvoiceOptions, setPurchaseInvoiceOptions] = React.useState(
    []
  );
  const client = useSelector(selectClientInfo);
  const [tabIndex, setTabIndex] = React.useState(0);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const validateAddNew = (fieldValues = addNewValues) => {
    let temp = { ...addErrors };
    if ("test" in fieldValues)
      temp.test = fieldValues.test ? "" : "This field is required.";

    setAddErrors({
      ...temp,
    });
    if (fieldValues === addNewValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    addNewValues,
    errors,
    setErrors,
    addErrors,
    setAddErrors,
    handleInputChange,
    convertToDefEventPara,
    handleInputListChange,
    handleAddNewInputChange,
    resetForm,
  } = GeneralInputForm(
    initialFValues,
    initialAddNewValues,
    true,
    true,
    validate,
    validateAddNew
  );

  const fetchTestAutoCompleteData = async (search = "") => {
    const response = await GetTestAutoComplete(
      search,
      values.purchase_invoice ? values.purchase_invoice.id : 0
    );
    const test = await response.data;
    console.log(test);
    setTestOptions(test);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };
  const isFound = (item) =>
    values.purchase_return_items.some((element) => {
      if (element.test.id === item.id) {
        return true;
      }

      return false;
    });

  const handleDeleteListItem = (index) => {
    let newList = Object.assign([], values.purchase_return_items);
    newList.splice(index, 1);
    setValues({
      ...values,
      purchase_return_items: newList,
    });
  };

  const handleChangeIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  const currencyChange = async (e) => {
    let currencey_exchange = await currencies.find(
      (item) => item.currency_name === e.target.value
    )?.currency_for_CurrencyExchange_set[0].currency_exchange;
    await setValues({
      ...values,
      currencey: e.target.value,
      currencey_exchange: currencey_exchange,
    });
  };
  const fetchVenderAutoCompleteData = async (search = "") => {
    const response = await GetVenderAutoComplete(search, client.id);
    const venders = await response.data;
    console.log(venders);
    setVenderOptions(venders);
  };
  const fetchInvoicePurchaseAutoCompleteData = async (search = "") => {
    const response = await GetPurchaseInvoiceAutoComplete(
      values.vender ? values.vender?.id : 0,
      client.id
    );
    const venders = await response.data;
    console.log(venders);
    setPurchaseInvoiceOptions(venders);
  };
  const loadCurrencies = async (search = "") => {
    const response = await GetCurrenciesAutoComplete(search, client.id);
    const currencies = await response.data;
    console.log(currencies);
    setCurrencies(currencies);
  };
  const totalCalculation = (list) => {
    let testList = list;
    let total = 0;
    for (var item of testList) {
      total = parseFloat(total.toFixed(3)) + parseFloat(item.total_amount);
    }
    return total;
  };
  const handleQtyChange = async (index, name, value) => {
    let listName = "purchase_return_items";
    if (value.length === 0) {
      return;
    }
    const item = Object.assign({}, values[listName][index]);
    let response = await GetTestQtyAutoComplete(
      item["test"]["id"],
      values.purchase_invoice.id
    );
    if (response.data.success && response.data.Qty >= parseFloat(value)) {
      item[name] = value;
      item["total_amount"] = value * item["price"];

      const items = Object.assign([], values[listName]);
      items[index] = item;

      let total = await totalCalculation(items);

      setValues({
        ...values,
        purchase_return_items: items,
        total_amount: total,
      });
    }
  };

  useEffect(() => {
    loadCurrencies();
  }, []);

  useEffect(() => {
    if (data !== null) {
      setValues(data);
    }
  }, [data, setValues]);

  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
                resetForm();
              }}
            >
              {<FormattedMessage id="close" />}
            </Button>
          </div>
          <Form className={classes.form}>
            <h4>{<FormattedMessage id="purchase_return_details" />}</h4>
            <br />
            <div className={classes.spaceAroundRow}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  variant="dialog"
                  label={<FormattedMessage id="invoice_date" />}
                  inputVariant="filled"
                  InputProps={DateInputProps}
                  format="dd/MM/yyyy"
                  name="invoice_date"
                  value={values.invoice_date}
                  onChange={(e) => {
                    handleInputChange(
                      convertToDefEventPara(
                        "invoice_date",
                        e ? e.toLocaleDateString() : ""
                      )
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
              <BaseAutoComplete
                label={<FormattedMessage id="vender_name" />}
                name="partner"
                variant="filled"
                size="small"
                customStyle={{ width: "100%", marginInline: 5 }}
                options={venderOptions}
                optionLable="vender_name"
                fetchData={fetchVenderAutoCompleteData}
                inputValue={values.vender ? values.vender.vender_name : ""}
                onSelectChange={async (newValue) => {
                  await handleInputChange(
                    convertToDefEventPara("vender", newValue ? newValue : null)
                  );
                }}
              />
              <BaseAutoComplete
                label={<FormattedMessage id="invoice_id" />}
                name="purchase_invoice"
                variant="filled"
                size="small"
                customStyle={{ width: "100%", marginInline: 5 }}
                options={purchaseInvoiceOptions}
                optionLable="id"
                fetchData={fetchInvoicePurchaseAutoCompleteData}
                inputValue={
                  values.purchase_invoice
                    ? values.purchase_invoice.id.toString()
                    : ""
                }
                onSelectChange={async (newValue) => {
                  await handleInputChange(
                    convertToDefEventPara(
                      "purchase_invoice",
                      newValue ? newValue : null
                    )
                  );
                }}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-select"
                select
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%", marginInline: 5 }}
                label={<FormattedMessage id="currencey" />}
                name="currencey"
                value={values.currencey}
                onChange={currencyChange}
                {...(errors.currencey && {
                  error: true,
                  helperText: errors.currencey,
                })}
                defaultValue={1}
              >
                {/* <MenuItem value={0}>
                  <em>None</em>
                </MenuItem> */}
                {currencies.map((option) => (
                  <MenuItem key={option.id} value={option.currency_name}>
                    {option.currency_name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-number"
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%", marginInline: 5 }}
                label={<FormattedMessage id="currencey_exchange" />}
                name="currencey_exchange"
                value={values.currencey_exchange}
                {...(errors.currencey_exchange && {
                  error: true,
                  helperText: errors.currencey_exchange,
                })}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                fullWidth
                InputProps={InputProps}
                size="small"
                style={{ marginInline: 5 }}
                label={<FormattedMessage id="description" />}
                name="description"
                value={values.description}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                required
                onChange={handleInputChange}
                {...(errors.description && {
                  error: true,
                  helperText: errors.description,
                })}
              />
            </div>
            <Tabs
              value={tabIndex}
              onChange={handleChangeIndex}
              aria-label="simple tabs example"
              // variant="fullWidth"
              TabIndicatorProps={{ className: classes.indicator }}
              indicatorColor="primary"
              style={{ marginBlock: 20 }}
            >
              <Tab label={<FormattedMessage id="purchase_return_list_details" />} {...a11yProps(0)} />
              <Tab label={<FormattedMessage id="journal_entry" />} {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
             
              <br />

              <div>
                <ul className={classes.ul}>
                  {values.purchase_return_items.map((item, index) => (
                    <li key={index} className={classes.li}>
                      <div className={classes.withOutSpaceRow}>
                        <TextField
                          id="outlined-basic"
                          variant="filled"
                          InputProps={InputlistProps}
                          size="small"
                          style={{ width: "42.5%", marginInline: 5 }}
                          label={<FormattedMessage id="test_name" />}
                          name="test"
                          value={item.test.english_name}
                        />
                        <TextField
                          id="outlined-basic"
                          variant="filled"
                          InputProps={InputlistProps}
                          size="small"
                          style={{ width: "42.5%", marginInline: 5 }}
                          label={<FormattedMessage id="price" />}
                          name="price"
                          value={item.price}
                        />
                        <TextField
                          // type={"number"}
                          id="outlined-basic"
                          variant="filled"
                          InputProps={InputlistProps}
                          size="small"
                          style={{ width: "42.5%", marginInline: 5 }}
                          label={<FormattedMessage id="qty" />}
                          name="qty"
                          value={item.qty}
                          // onChange={(e) =>
                          //   handleQtyChange(
                          //     index,
                          //     e.target.name,
                          //     e.target.value
                          //   )
                          // }
                        />
                        <TextField
                          id="outlined-basic"
                          variant="filled"
                          InputProps={InputlistProps}
                          size="small"
                          style={{ width: "42.5%", marginInline: 5 }}
                          label={<FormattedMessage id="total_amount" />}
                          name="total_amount"
                          value={item.total_amount}
                          // onChange={(e) =>
                          //   handleInputListChange(
                          //     e,
                          //     item.id,
                          //     "purchase_return_items"
                          //   )
                          // }
                        />
                        {/* <IconButton
                        aria-label="Delete"
                        size="medium"
                        color="primary"
                        onClick={handleDeleteListItem.bind(this, index)}
                      >
                        <DeleteOutline fontSize="inherit" />
                      </IconButton> */}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <br />
              <div>
                <ul className={classes.ul}>
                  {values.journal?.journal_entry_items?.map((item, index) => (
                    <li key={index} className={classes.li}>
                      <div className={classes.withOutSpaceRow}>
                        <TextField
                          id="outlined-basic"
                          variant="filled"
                          InputProps={ReadOnlyInputlistProps}
                          size="small"
                          style={{ width: "42.5%", marginInline: 5 }}
                          label={<FormattedMessage id="account" />}
                          name="account"
                          value={item.account ? item.account.account_name : ""}
                        />
                        <TextField
                          id="outlined-basic"
                          variant="filled"
                          InputProps={ReadOnlyInputlistProps}
                          size="small"
                          style={{ marginInline: 5 }}
                          label={<FormattedMessage id="partner" />}
                          name="partner"
                          value={item.partner ? item.partner.vender_name : ""}
                        />
                        <TextField
                          id="outlined-basic"
                          variant="filled"
                          InputProps={ReadOnlyInputlistProps}
                          size="small"
                          style={{ marginInline: 5 }}
                          label={<FormattedMessage id="label" />}
                          name="label"
                          value={item.label ? item.label : ""}
                        />
                        <TextField
                          type="number"
                          id="outlined-basic"
                          variant="filled"
                          InputProps={ReadOnlyInputlistProps}
                          size="small"
                          style={{ marginInline: 5 }}
                          label={<FormattedMessage id="debit" />}
                          name="debit"
                          value={parseFloat(item.debit)}
                        />
                        <TextField
                          type="number"
                          id="outlined-basic"
                          variant="filled"
                          InputProps={ReadOnlyInputlistProps}
                          size="small"
                          style={{ marginInline: 5 }}
                          label={<FormattedMessage id="credit" />}
                          name="credit"
                          value={parseFloat(item.credit)}
                        />
                      </div>
                    </li>
                  ))}
                  <li className={classes.li}>
                    <div
                      className={classes.withOutSpaceRow}
                      style={{ justifyContent: "flex-end" }}
                    >
                      <TextField
                        id="outlined-basic"
                        variant="filled"
                        InputProps={InputlistProps}
                        size="small"
                        style={{ marginInline: 5 }}
                        label={<FormattedMessage id="total_debit" />}
                        name="total_debit"
                        value={values.journal?.journal_entry_items?.reduce(
                          (total, item) =>
                            (total =
                              parseFloat(total) + parseFloat(item.debit)),
                          0
                        )}
                      />
                      <TextField
                        id="outlined-basic"
                        variant="filled"
                        InputProps={InputlistProps}
                        size="small"
                        style={{ marginInline: 5 }}
                        label={<FormattedMessage id="total_credit" />}
                        name="total_credit"
                        value={values.journal?.journal_entry_items?.reduce(
                          (total, item) =>
                            (total =
                              parseFloat(total) + parseFloat(item.credit)),
                          0
                        )}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </TabPanel>
          </Form>
        </Container>
      </Modal>
      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
