import {
  Button,
  Container,
  FormControlLabel,
  Modal,
  Snackbar,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";

import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import MuiAlert from "@material-ui/lab/Alert";
import {
  useStyles,
  InputProps,
  InputlistProps,
} from "./ComponentsStyle/ModalStyle";
import { FormattedMessage } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFValues = {
  id: "",
  offer_name: "",
  description: "",
  offeritems: [],
  is_active: true,
};

export const ViewModal = ({ data, open, setOpen }) => {
  const classes = useStyles();
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);

  const { values, setValues, errors, handleInputChange, resetForm } =
    GeneralInputForm(initialFValues, null, false, false, null, null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };

  useEffect(() => {
    if (data !== null) {
      setValues(data);
    }
  }, [data, setValues]);

  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
                resetForm();
              }}
            >
              {<FormattedMessage id="close" />}
            </Button>
          </div>
          <Form className={classes.form}>
            <h4>{<FormattedMessage id="offer_details" />}</h4>
            <br />
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "42.5%", marginInline: 5 }}
                label={<FormattedMessage id="offer_name" />}
                name="offer_name"
                value={values.offer_name}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                required
                onChange={handleInputChange}
                {...(errors.offer_name && {
                  error: true,
                  helperText: errors.offer_name,
                })}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "42.5%", marginInline: 5 }}
                label={<FormattedMessage id="description" />}
                name="description"
                value={values.description}
                // InputLabelProps={{ shrink: values.department ? true : false }}
              />
              <div style={{ width: "15%", marginInline: 5 }}>
                <FormControlLabel
                  control={
                    <Switch
                      name="is_active"
                      checked={values.is_active}
                      color="primary"
                    />
                  }
                  label={<FormattedMessage id="is_active" />}
                />
              </div>
            </div>
            <h4>{<FormattedMessage id="offer_list_details" />}</h4>
            <br />
            <div>
              <ul className={classes.ul}>
                {values.offeritems.map((item, index) => (
                  <li key={index} className={classes.li}>
                    <div>
                      <TextField
                        id="outlined-basic"
                        variant="filled"
                        InputProps={InputlistProps}
                        size="small"
                        style={{ width: "42.5%", marginInline: 5 }}
                        label={<FormattedMessage id="test_name" />}
                        name="test"
                        value={item.test}
                      />
                      <TextField
                        id="outlined-basic"
                        variant="filled"
                        InputProps={InputlistProps}
                        size="small"
                        style={{ width: "42.5%", marginInline: 5 }}
                        label={<FormattedMessage id="price" />}
                        name="offer_item_price"
                        value={item.offer_item_price}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Form>
        </Container>
      </Modal>
      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
