import React, { Fragment, useState } from "react";
import { Fab, Tooltip } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import BaseTable, { tableIcons } from "../../app/generalComponent/BaseTable";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { AddModal } from "./Components/AddModal";
import AddIcon from "@material-ui/icons/Add";
import { useStyles } from "./Components/ComponentsStyle/ModalStyle";
import { ViewModal } from "./Components/ViewModal";
import { DeleteUser, GetUserDetails } from "./UsersAPI";
import { EditModal } from "./Components/EditModal";
import AlertDialog from "../../app/generalComponent/DeletingAlert";
import { FormattedMessage } from "react-intl";

// import { jsPDF } from "jspdf";

function UsersPage() {
  const classes = useStyles();
  const [viewOpen, setViewOpen] = useState(false);
  const [editeOpen, setEditeOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [apiResultValues, setApiResultValues] = useState(null);
  const theme = useTheme();
  const tableRef = React.createRef();

  const LoadDetails = async (id) => {
    if (id !== null) {
      const response = await GetUserDetails(id);
      const apiResultValues = {
        id: response.data.id,
        full_name: response.data.full_name,
        client: response.data.client,
        email: response.data.email,
        is_staff: response.data.is_staff,
        is_active: response.data.is_active,
        is_superuser: response.data.is_superuser,
        groups: response.data.groups,
        user_permissions: response.data.user_permissions,
      };
      // console.log(response);
      setApiResultValues(apiResultValues);
      console.log(apiResultValues);
    }
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };
  const handelConfirmDelete = () => {
    console.log("id " + rowId);
    DeleteUser(rowId);
    setDeleteDialogOpen(false);
  };

  function renderSwitchTrueFalse(param) {
    switch (param) {
      case true:
        return (
          <img
            src={require("../../app/images/accept.png")}
            alt="product_image"
            height="30"
            width="30"
            loading="lazy"
            style={{
              objectFit: "contain",
            }}
          />
        );
      case false:
        return (
          <img
            src={require("../../app/images/remove.png")}
            alt="product_image"
            height="30"
            width="30"
            loading="lazy"
            style={{
              objectFit: "contain",
            }}
          />
        );

      default:
        return (
          <img
            src={require("../../app/images/remove.png")}
            alt="product_image"
            height="30"
            width="30"
            loading="lazy"
            style={{
              objectFit: "contain",
            }}
          />
        );
    }
  }

  const columns = [
    {
      title: "#",
      render: (rowData) => rowData.tableData.id + 1,
      width: 10,
      cellStyle: { color: "red", textAlign: "right" },
      sorting: false,
    },
    {
      title: <FormattedMessage id="full_name" />,
      field: "full_name",
      cellStyle: { whiteSpace: "nowrap" },
    },
    {
      title: <FormattedMessage id="email" />,
      field: "email",
      cellStyle: { whiteSpace: "nowrap" },
    },
    {
      title: <FormattedMessage id="last_login" />,
      field: "last_login",
      cellStyle: { whiteSpace: "nowrap" },
    },
    {
      title: <FormattedMessage id="is_active" />,
      field: "is_active",
      cellStyle: { whiteSpace: "nowrap" },
      render: (item) => {
        return renderSwitchTrueFalse(item.is_active);
      },
    },
  ];

  return (
    <Fragment>
      <Tooltip title="Add" aria-label="add" onClick={() => setAddOpen(true)}>
        <Fab color="primary" className={classes.fab}>
          <AddIcon />
        </Fab>
      </Tooltip>
      <EditModal
        data={apiResultValues}
        open={editeOpen}
        setOpen={setEditeOpen}
      />
      <ViewModal data={apiResultValues} open={viewOpen} setOpen={setViewOpen} />
      <AddModal open={addOpen} setOpen={setAddOpen} />
      <AlertDialog
        open={deleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleConfirm={handelConfirmDelete}
      />

      <BaseTable
        refrence={tableRef}
        title={<FormattedMessage id="users" />}
        columns={columns}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = "http://206.189.137.141:8000/api/user/users/?";
            url += "per_page=" + query.pageSize;
            url += "&page=" + (query.page + 1);
            if (query.search) {
              url += `&search=${query.search}`;
            }
            if (query.orderBy) {
              if (query.orderDirection === "asc") {
                url += `&orderBy=${query.orderBy.field}`;
              } else {
                url += `&orderBy=-${query.orderBy.field}`;
              }
            }
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data,
                  page: result.page - 1,
                  totalCount: result.count,
                });
              });
          })
        }
        actions={[
          {
            icon: tableIcons.Refresh,
            tooltip: "Refresh",
            onClick: (event, rowData) => {
              const scopedRef = tableRef.current;
              scopedRef.onQueryChange();
            },
            isFreeAction: true,
          },
          {
            icon: tableIcons.View,
            tooltip: "View",
            onClick: (event, rowData) => {
              LoadDetails(rowData.id);
              setViewOpen(true);
            },
          },
          {
            icon: tableIcons.Edit,
            tooltip: "Edit",
            onClick: (event, rowData) => {
              LoadDetails(rowData.id);
              setEditeOpen(true);
            },
          },
          {
            icon: tableIcons.Delete,
            tooltip: "Delete",
            onClick: (event, rowData) => {
              setDeleteDialogOpen(true);
              setRowId(rowData.id);
            },
          },
        ]}
        options={{
          exportMenu: [
            // {
            //   label: "Export PDF",
            //   exportFunc: (cols, datas) => ExportPdf(cols, datas, "Test Data"),
            // },
            {
              label: <FormattedMessage id="Export_CSV" />,
              exportFunc: (cols, datas) =>
                ExportCsv(cols, datas, new Date().toLocaleDateString()),
            },
          ],
          exportButton: true,
          exportAllData: true,
          exportFileName: "TestData",
          pageSizeOptions: [5, 10, 20, 50, 100],
          maxBodyHeight: "55vh",
          paginationType: "stepped",
          paginationPosition: "bottom",
          searchFieldVariant: "outlined",
          searchFieldStyle: {
            fontSize: "small",
            maxHeight: 35,
            paddingInlineEnd: 0,
          },
          // columnsButton:true,
          headerStyle: {
            // background: theme.palette.primary.main,
            // color: "white",
            fontWeight: "bold",
          },
          rowStyle: (data) =>
            data.tableData.id % 2 === 0
              ? { background: theme.palette.common.white }
              : { background: theme.palette.grey[100] },
          // grouping:true
        }}
      />
    </Fragment>
  );
}

export default UsersPage;

// function CustomExportPdf(columns, data = [], filename = "data") {
//   try {
//     let finalData = data;
//     if (data.length && !Array.isArray(data[0])) {
//       if (typeof data[0] === "object") {
//         finalData = data.map((row) =>
//           columns.map((col) =>
//             col.exportTransformer
//               ? col.exportTransformer(row)
//               : row[col.field]
//           )
//         );
//       }
//     }
//       const content = {
//         startY: 50,
//         head: [columns.map((col) => col.title)],
//         body: finalData,
//       };
//       const unit = "pt";
//       const size = "A4";
//       const orientation = "landscape";
//       const doc = new jsPDF(orientation, unit, size);
//       doc.setFontSize(15);
//       doc.text(filename, 40, 40);
//       doc.autoTable(content);
//       doc.save(filename + Date.now().toString() + ".pdf");
//     // }
//   } catch (err) {
//     console.error(
//       `exporting pdf : ${err}`
//     );
//   }
// }
