import { Divider } from "@material-ui/core";
import { forwardRef, useEffect } from "react";
import Barcode from "react-barcode/lib/react-barcode";
import "./ComponentsStyle/PrintDoc.css";

export const PrintDoc = forwardRef((props, ref) => {
  const data = require("../NormalValue/DefaultCbcNormalValue.json");
  const data2 = require("../NormalValue/DefaultCbcDiff5NormalValue.json");
  const { values, client } = props;
  useEffect(() => {
    console.log(client);
  }, []);
  return (
    <div ref={ref}>
      {/* <img alt={""} src={logo} className={"watermark"} /> */}
      <table className="report-container">
        <thead>
          <tr>
            <td>
              <div className="header">
                <div>
                  <img
                    alt="header"
                    src={client.header_image_url}
                    style={{ objectFit: "cover" }}
                    width={"100%"}
                    height={"100px"}
                  />
                  <div className="header-print-info">
                    <div className="header-item">
                      <p className="item-title">Age / Gender</p>
                      <p className="item-detail">
                        {values.age} {values.age_type} - {values.gender}
                      </p>
                    </div>
                    <div className="header-item">
                      <p className="item-title">Visit No.</p>
                      <p className="item-detail">{values.invoice_id}</p>
                      <Barcode
                        value={
                          values?.invoice_id
                            ? values.invoice_id.toString()
                            : "0"
                        }
                        width={2}
                        height={20}
                        format={"CODE128"}
                        displayValue={false}
                        fontOptions={""}
                        font={"monospace"}
                        textAlign={"center"}
                        textPosition={"bottom"}
                        textMargin={2}
                        fontSize={12}
                        background={"#ffffff"}
                        lineColor={"#000000"}
                        margin={5}
                        marginTop={undefined}
                        marginBottom={undefined}
                        marginLeft={5}
                        marginRight={5}
                      />
                    </div>
                  </div>
                  <div className="header-print-info">
                    <div className="header-item">
                      <p className="item-title">Registration Date</p>
                      <p className="item-detail">{values.registration_date}</p>
                    </div>
                    <div className="header-item">
                      <p className="item-title">Patient Name</p>
                      <p className="item-detail">{values.patient_name}</p>
                    </div>
                  </div>
                  <div className="header-print-info">
                    <div className="header-item">
                      <p className="item-title">Reporting Date</p>
                      <p className="item-detail">
                        {new Date().toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      borderTopColor: "black",
                      borderTopWidth: 1,
                      borderTopStyle: "solid",
                      width: "99%",
                      marginInline: 5,
                    }}
                  />
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <td>
              <div className="footer-space">&nbsp;</div>
            </td>
          </tr>
        </tfoot>
        <tbody className="report-content">
          <tr>
            <td>
              <div className="main">
                {values.test_items?.map((item, index) => {
                  if (!item.test.more_result) {
                    return (
                      <div className="test" key={"print test" + index}>
                        <h4 className="report-title">
                          {item.test.report_title}
                        </h4>
                        <div className="result">
                          <p>Test Name</p>
                          <p style={{ fontWeight: "bold" }}>Result</p>
                          <p>Unit</p>
                          <p className="ref-range">Ref. Range</p>
                        </div>
                        <div className="result">
                          <p>{item.test.english_name}</p>
                          <p style={{ fontWeight: "bold" }}>{item.result[0].result}</p>
                          <p>{item.test.units}</p>
                          <p className="ref-range">
                            {item.test.normal[0].normal_value}
                          </p>
                        </div>
                      </div>
                    );
                  } else {
                    if (item.test.result_type === "Urine") {
                      return (
                        <div className="test">
                          <h4 className="report-title">
                            {item.test.report_title}
                          </h4>
                          <div className="result">
                            <p>Test Name</p>
                            <p style={{ fontWeight: "bold" }}>Result</p>
                            <p className="ref-range">Ref. Range</p>
                          </div>
                          <h5 className="sub-report-title">
                            Physical Examinations
                          </h5>
                          <div className="result">
                            <p>volume</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].volume}</p>
                            <p className="ref-range">1 - 1.5 L/24hrs</p>
                          </div>
                          <div className="result">
                            <p>colour</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].colour}</p>
                            <p className="ref-range">Yellow</p>
                          </div>
                          <div className="result">
                            <p>aspect</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].aspect}</p>
                            <p className="ref-range">Clear</p>
                          </div>
                          <h5 className="sub-report-title">
                            Chemical Examinations
                          </h5>
                          <div className="result">
                            <p>reaction</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].reaction}</p>
                            <p className="ref-range">Acidic</p>
                          </div>
                          <div className="result">
                            <p>Sp.Gravity</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].sp_gravity}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Glucose</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].glucose}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Acetone</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].acetone}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Protein</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].protein}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Bilirubin</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].bilirubin}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Urobilinogen</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].urobilinogen}</p>
                            <p className="ref-range">Normal Trace</p>
                          </div>
                          <div className="result">
                            <p>Blood</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].blood}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Leucocytes</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].leucocytes}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <h5 className="sub-report-title">
                            Microscopic Examinations
                          </h5>
                          <div className="result">
                            <p>Pus Cells</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].pus_cells}</p>
                            <p className="ref-range">0 - 5 / HPF</p>
                          </div>
                          <div className="result">
                            <p>R.B.C's</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].rbcs}</p>
                            <p className="ref-range">0 - 5 / HPF</p>
                          </div>
                          <div className="result">
                            <p>Epithelial Cells</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].epithelial}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Crystal</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].crystal_1}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p></p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].crystal_2}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Casts</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].cast}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Amorphous</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].amorphous}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Fungi</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].fungi}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Bacteria</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].bacteria}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Mucus</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].mucus}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Ova</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].ova}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Other</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].other}</p>
                            <p className="ref-range"></p>
                          </div>
                        </div>
                      );
                    }
                    if (item.test.result_type === "Stool") {
                      return (
                        <div className="test">
                          <h4 className="report-title">
                            {item.test.report_title}
                          </h4>
                          <div className="result">
                            <p>Test Name</p>
                            <p style={{ fontWeight: "bold" }}>Result</p>
                            <p className="ref-range">Ref. Range</p>
                          </div>
                          <h5 className="sub-report-title">
                            Physical Examinations
                          </h5>
                          <div className="result">
                            <p>Colour</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].colour}</p>
                            <p className="ref-range">Brownish</p>
                          </div>
                          <div className="result">
                            <p>Mucus</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].mucus}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Blood</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].blood}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Consistency</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].consistency}</p>
                            <p className="ref-range">Formed</p>
                          </div>
                          <div className="result">
                            <p>Reaction</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].reaction}</p>
                            <p className="ref-range">Alkaline</p>
                          </div>
                          <div className="result">
                            <p>Food Particles</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].food_particles}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Oder</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].odor}</p>
                            <p className="ref-range">Offensive</p>
                          </div>
                          <h5 className="sub-report-title">
                            Microscopic Examinations
                          </h5>
                          <div className="result">
                            <p>Pus Cells</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].pus_cells}</p>
                            <p className="ref-range">5 - 10 / HPF</p>
                          </div>
                          <div className="result">
                            <p>R.B.C's</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].rbcs}</p>
                            <p className="ref-range">0 - 5 / HPF</p>
                          </div>
                          <div className="result">
                            <p>Trophozoite</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].trophozoite}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Flagellates</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].flagellates}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Cycts</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].cycts}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Ova</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].ova}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Undigested Food</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].undigested_food_1}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p></p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].undigested_food_2}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p></p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].undigested_food_3}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Starch</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].starch}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Larve</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].larve}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                          <div className="result">
                            <p>Parasitology Artifact</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].parasitology_artifact}</p>
                            <p className="ref-range">Absent</p>
                          </div>
                        </div>
                      );
                    }
                    if (item.test.result_type === "CBC Diff 3") {
                      return (
                        <div className="test">
                          <h4 className="report-title">
                            {item.test.report_title}
                          </h4>
                          <div className="cbc-result">
                            <p>Test Name</p>
                            <p style={{ fontWeight: "bold" }}>Result</p>
                            <p>Unit</p>
                            <p>Ref. Range</p>
                          </div>
                          <div className="cbc-result">
                            <p>RBC</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].RBC}</p>
                            <p>{data.RBC.unit}</p>
                            <p>{data.RBC.L + " - " + data.RBC.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>WBC</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].RBC}</p>
                            <p>{data.WBC.unit}</p>
                            <p>{data.WBC.L + " - " + data.WBC.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>HGB</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].HGB}</p>
                            <p>{data.HGB.unit}</p>
                            <p>{data.HGB.L + " - " + data.HGB.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>HCT</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].HCT}</p>
                            <p>{data.HCT.unit}</p>
                            <p>{data.HCT.L + " - " + data.HCT.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>PLT</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].PLT}</p>
                            <p>{data.PLT.unit}</p>
                            <p>{data.PLT.L + " - " + data.PLT.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>Lym#</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].LymH}</p>
                            <p>{data.LymH.unit}</p>
                            <p>{data.LymH.L + " - " + data.LymH.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>Gran#</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].GranH}</p>
                            <p>{data.GranH.unit}</p>
                            <p>{data.GranH.L + " - " + data.GranH.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>Mid#</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].MidH}</p>
                            <p>{data.MidH.unit}</p>
                            <p>{data.MidH.L + " - " + data.MidH.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>MCV</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].MCV}</p>
                            <p>{data.MCV.unit}</p>
                            <p>{data.MCV.L + " - " + data.MCV.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>MCH</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].MCH}</p>
                            <p>{data.MCH.unit}</p>
                            <p>{data.MCH.L + " - " + data.MCH.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>MCHC</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].MCHC}</p>
                            <p>{data.MCHC.unit}</p>
                            <p>{data.MCHC.L + " - " + data.MCHC.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>Pct</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].Pct}</p>
                            <p>{data.Pct.unit}</p>
                            <p>{data.Pct.L + " - " + data.Pct.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>MPV</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].MPV}</p>
                            <p>{data.MPV.unit}</p>
                            <p>{data.MPV.L + " - " + data.MPV.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>Lym%</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].LymM}</p>
                            <p>{data.LymM.unit}</p>
                            <p>{data.LymM.L + " - " + data.LymM.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>Gran%</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].GranM}</p>
                            <p>{data.GranM.unit}</p>
                            <p>{data.GranM.L + " - " + data.GranM.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>Mid%</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].MidM}</p>
                            <p>{data.MidM.unit}</p>
                            <p>{data.MidM.L + " - " + data.MidM.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>RDW-CV</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].RDW_CV}</p>
                            <p>{data.RDW_CV.unit}</p>
                            <p>{data.RDW_CV.L + " - " + data.RDW_CV.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>PDW</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].PDW}</p>
                            <p>{data.PDW.unit}</p>
                            <p>{data.PDW.L + " - " + data.PDW.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>RDW-SD</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].RDW_SD}</p>
                            <p>{data.RDW_SD.unit}</p>
                            <p>{data.RDW_SD.L + " - " + data.RDW_SD.H}</p>
                          </div>
                        </div>
                      );
                    }
                    if (item.test.result_type === "CBC Diff 5") {
                      return (
                        <div className="test">
                          <h4 className="report-title">
                            {item.test.report_title}
                          </h4>
                          <div className="cbc-result">
                            <p>Test Name</p>
                            <p style={{ fontWeight: "bold" }}>Result</p>
                            <p>Unit</p>
                            <p>Ref. Range</p>
                          </div>
                          <div className="cbc-result">
                            <p>RBC</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].RBC}</p>
                            <p>{data2.RBC.unit}</p>
                            <p>{data2.RBC.L + " - " + data2.RBC.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>HGB</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].HGB}</p>
                            <p>{data2.HGB.unit}</p>
                            <p>{data2.HGB.L + " - " + data2.HGB.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>HCT</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].HCT}</p>
                            <p>{data2.HCT.unit}</p>
                            <p>{data2.HCT.L + " - " + data2.HCT.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>MCV</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].MCV}</p>
                            <p>{data2.MCV.unit}</p>
                            <p>{data2.MCV.L + " - " + data2.MCV.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>MC#</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].MCH}</p>
                            <p>{data2.MCH.unit}</p>
                            <p>{data2.MCH.L + " - " + data2.MCH.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>MCHC</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].MCHC}</p>
                            <p>{data2.MCHC.unit}</p>
                            <p>{data2.MCHC.L + " - " + data2.MCHC.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>RDW-CV</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].RDW_CV}</p>
                            <p>{data2.RDW_CV.unit}</p>
                            <p>{data2.RDW_CV.L + " - " + data2.RDW_CV.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>RDW-SD</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].RDW_SD}</p>
                            <p>{data2.RDW_SD.unit}</p>
                            <p>{data2.RDW_SD.L + " - " + data2.RDW_SD.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>PLT</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].PLT}</p>
                            <p>{data2.PLT.unit}</p>
                            <p>{data2.PLT.L + " - " + data2.PLT.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>MPV</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].MPV}</p>
                            <p>{data2.MPV.unit}</p>
                            <p>{data2.MPV.L + " - " + data2.MPV.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>PDW</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].PDW}</p>
                            <p>{data2.PDW.unit}</p>
                            <p>{data2.PDW.L + " - " + data2.PDW.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>Pct</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].Pct}</p>
                            <p>{data2.Pct.unit}</p>
                            <p>{data2.Pct.L + " - " + data2.Pct.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>P-LCR</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].P_LCR}</p>
                            <p>{data2.P_LCR.unit}</p>
                            <p>{data2.P_LCR.L + " - " + data2.P_LCR.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>P-LCC</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].P_LCC}</p>
                            <p>{data2.P_LCC.unit}</p>
                            <p>{data2.P_LCC.L + " - " + data2.P_LCC.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>CRP</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].CRP}</p>
                            <p>{data2.CRP.unit}</p>
                            <p>{data2.CRP.L + " - " + data2.CRP.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>Hs-CRP</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].Hs_CRP}</p>
                            <p>{data2.Hs_CRP.unit}</p>
                            <p>{data2.Hs_CRP.L + " - " + data2.Hs_CRP.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>WBC</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].WBC}</p>
                            <p>{data2.WBC.unit}</p>
                            <p>{data2.WBC.L + " - " + data2.WBC.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>NEU%</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].NEUM}</p>
                            <p>{data2.NEUM.unit}</p>
                            <p>{data2.NEUM.L + " - " + data2.NEUM.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>Lym%</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].LymM}</p>
                            <p>{data2.LymM.unit}</p>
                            <p>{data2.LymM.L + " - " + data2.LymM.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>%ON%</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].MONM}</p>
                            <p>{data2.MONM.unit}</p>
                            <p>{data2.MONM.L + " - " + data2.MONM.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>EOS%</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].EOSM}</p>
                            <p>{data2.EOSM.unit}</p>
                            <p>{data2.EOSM.L + " - " + data2.EOSM.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>BAS%</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].BASM}</p>
                            <p>{data2.BASM.unit}</p>
                            <p>{data2.BASM.L + " - " + data2.BASM.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>NEU#</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].NEUH}</p>
                            <p>{data2.NEUH.unit}</p>
                            <p>{data2.NEUH.L + " - " + data2.NEUH.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>Lym#</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].LymH}</p>
                            <p>{data2.LymH.unit}</p>
                            <p>{data2.LymH.L + " - " + data2.LymH.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>MON#</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].MONH}</p>
                            <p>{data2.MONH.unit}</p>
                            <p>{data2.MONH.L + " - " + data2.MONH.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>EOS#</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].EOSH}</p>
                            <p>{data2.EOSH.unit}</p>
                            <p>{data2.EOSH.L + " - " + data2.EOSH.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>BAS#</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].BASH}</p>
                            <p>{data2.BASH.unit}</p>
                            <p>{data2.BASH.L + " - " + data2.BASH.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>ALY#</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].ALYH}</p>
                            <p>{data2.ALYH.unit}</p>
                            <p>{data2.ALYH.L + " - " + data2.ALYH.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>ALY%</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].ALYM}</p>
                            <p>{data2.ALYM.unit}</p>
                            <p>{data2.ALYM.L + " - " + data2.ALYM.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>LIC#</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].LICH}</p>
                            <p>{data2.LICH.unit}</p>
                            <p>{data2.LICH.L + " - " + data2.LICH.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>LIC%</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].LICM}</p>
                            <p>{data2.LICM.unit}</p>
                            <p>{data2.LICM.L + " - " + data2.LICM.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>NRBC#</p>
                             <p style={{ fontWeight: "bold" }}>{item.result[0].NRBCH}</p>
                            <p>{data2.NRBCH.unit}</p>
                            <p>{data2.NRBCH.L + " - " + data2.NRBCH.H}</p>
                          </div>
                          <div className="cbc-result">
                            <p>NRBC%</p>
                            <p style={{ fontWeight: "bold" }}>{item.result[0].NRBCM}</p>
                            <p>{data2.NRBCM.unit}</p>
                            <p>{data2.NRBCM.L + " - " + data2.NRBCM.H}</p>
                          </div>
                        </div>
                      );
                    }
                  }
                })}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="footer">
        <img
          alt="footer"
          src={client.footer_image_url}
          style={{ objectFit: "cover" }}
          width={"100%"}
          height="100px"
        />
      </div>
    </div>
  );
});
