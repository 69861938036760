import {
  Button,
  Container,
  FormControlLabel,
  // FormHelperText,
  IconButton,
  MenuItem,
  Modal,
  Snackbar,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
// import DepartmentDialog from "./DepartmentDialog";
// import GroupDialog from "./GroupDialog";
import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import {
  GetDepartmentAutoComplete,
  GetGroupAutoComplete,
  PostTest,
} from "../TestAPI";
import { AddModal as DepartmentAddModal } from "../../Department/Components/AddModal";
import { AddModal as GroupAddModal } from "../../Group/Components/AddModal";
import { useStyles, InputProps } from "./ComponentsStyle/ModalStyle";
import BaseAutoComplete from "../../../app/generalComponent/BaseAutoComplete";
import { useSelector } from "react-redux";
import { selectClientInfo } from "../../Authentecation/AuthSlice";
import { FormattedMessage } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const initialFValues = {
  english_name: "",
  device_name: "",
  report_title: "",
  sample: "",
  symbol: "",
  units: "",
  department: "",
  department_textInput: "",
  group: "",
  group_textInput: "",
  volume: "",
  priority: "",
  time: "",
  time_type: "",
  is_prepration: false,
  is_active: false,
  more_result: false,
  result_type: "",
  precautons: "",
  defult_result: "",
  defult_comment: "",
};

const AddModal = ({ open, setOpen }) => {
  const [DepartmentDialogOpen, setDepartmentDialogOpen] = useState(false);
  const [GroupDialogOpen, setGroupDialogOpen] = useState(false);
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [departmentOptions, setDepartmentOptions] = React.useState([]);
  const [groupOptions, setGroupOptions] = React.useState([]);
  const client = useSelector(selectClientInfo);
  const classes = useStyles();
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("english_name" in fieldValues)
      temp.english_name = fieldValues.english_name
        ? ""
        : "This field is required.";
    // if ("is_prepration" in fieldValues)
    //   temp.is_prepration =
    //     fieldValues.is_prepration === true ? "" : "This field is required.";
    // if ('departmentId' in fieldValues)
    //     temp.departmentId = fieldValues.departmentId.length != 0 ? "" : "This field is required."
    // if ('email' in fieldValues)
    //     temp.email = (/$^|.+@.+..+/).test(fieldValues.email) ? "" : "Email is not valid."
    // if ('mobile' in fieldValues)
    //     temp.mobile = fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required."
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    convertToDefEventPara,
    resetForm,
  } = GeneralInputForm(initialFValues, null, true, false, validate, null);

  const fetchDepartmentAutoCompleteData = async (search = "") => {
    const response = await GetDepartmentAutoComplete(search, client.id);
    const department = await response.data;
    console.log(department);
    setDepartmentOptions(department);
  };

  const fetchGroupAutoCompleteData = async (search = "") => {
    const response = await GetGroupAutoComplete(search, client.id);
    const group = await response.data;
    console.log(group);
    setGroupOptions(group);
  };

  const handleDepartmentDialogOpen = () => {
    setDepartmentDialogOpen(true);
  };

  // const handleDepartmentDialogClose = () => {
  //   setDepartmentDialogOpen(false);
  // };

  const handleGroupDialogOpen = () => {
    setGroupDialogOpen(true);
  };

  // const handleGroupDialogClose = () => {
  //   setGroupDialogOpen(false);
  // };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };
  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <Button
              variant="contained"
              style={{ marginInline: 20 }}
              onClick={(event) => {
                console.log(values);
                if (validate(values)) {
                  PostTest(event, values);
                  setOpenSuccessAlert(true);
                } else {
                  setOpenErrorAlert(true);
                }
              }}
              startIcon={<SaveIcon />}
            >
              <FormattedMessage id="create" />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
                resetForm();
              }}
            >
              <FormattedMessage id="close" />
            </Button>
          </div>
          <Form className={classes.form}>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="test_name" />}
                name="english_name"
                value={values.english_name}
                required
                onChange={handleInputChange}
                {...(errors.english_name && {
                  error: true,
                  helperText: errors.english_name,
                })}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="symbol" />}
                name="symbol"
                value={values.symbol}
                onChange={handleInputChange}
                {...(errors.symbol && {
                  error: true,
                  helperText: errors.symbol,
                })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="device_name" />}
                name="device_name"
                value={values.device_name}
                onChange={handleInputChange}
                {...(errors.device_name && {
                  error: true,
                  helperText: errors.device_name,
                })}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="units" />}
                name="units"
                value={values.units}
                onChange={handleInputChange}
                {...(errors.units && { error: true, helperText: errors.units })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <div
                className={classes.withOutSpaceRow}
                style={{ width: "45%", marginInline: 5, marginBlock: 0 }}
              >
                <BaseAutoComplete
                  label={<FormattedMessage id="department" />}
                  name="department"
                  variant="filled"
                  size="small"
                  customStyle={{ width: "90%", marginInlineEnd: 5 }}
                  options={departmentOptions}
                  optionLable="department"
                  fetchData={fetchDepartmentAutoCompleteData}
                  inputValue={values.department_textInput}
                  onSelectChange={async (newValue) => {
                    await handleInputChange(
                      convertToDefEventPara(
                        "department",
                        newValue ? newValue.id : null
                      )
                    );
                  }}
                />
                <IconButton
                  aria-label="Department"
                  size="medium"
                  color="primary"
                  onClick={handleDepartmentDialogOpen}
                >
                  <AddIcon fontSize="inherit" />
                </IconButton>
              </div>
              <div
                className={classes.withOutSpaceRow}
                style={{ width: "45%", marginInline: 5, marginBlock: 0 }}
              >
                <BaseAutoComplete
                  label={<FormattedMessage id="group" />}
                  name="group"
                  variant="filled"
                  size="small"
                  customStyle={{ width: "90%", marginInlineEnd: 5 }}
                  options={groupOptions}
                  optionLable={"group"}
                  fetchData={fetchGroupAutoCompleteData}
                  inputValue={values.group_textInput}
                  onSelectChange={async (newValue) => {
                    await handleInputChange(
                      convertToDefEventPara(
                        "group",
                        newValue ? newValue.id : null
                      )
                    );
                  }}
                />
                <IconButton
                  aria-label="Department"
                  size="medium"
                  color="primary"
                  onClick={handleGroupDialogOpen}
                >
                  <AddIcon fontSize="inherit" />
                </IconButton>
              </div>
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="report_title" />}
                name="report_title"
                value={values.report_title}
                onChange={handleInputChange}
                {...(errors.report_title && {
                  error: true,
                  helperText: errors.report_title,
                })}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="sample" />}
                name="sample"
                value={values.sample}
                onChange={handleInputChange}
                {...(errors.sample && {
                  error: true,
                  helperText: errors.sample,
                })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "20%", marginInline: 5 }}
                label={<FormattedMessage id="volume" />}
                name="volume"
                value={values.volume}
                onChange={handleInputChange}
                {...(errors.volume && {
                  error: true,
                  helperText: errors.volume,
                })}
              />
              <TextField
                id="outlined-number"
                variant="filled"
                size="small"
                type="number"
                InputProps={InputProps}
                style={{ width: "20%", marginInline: 5 }}
                label={<FormattedMessage id="priority" />}
                name="priority"
                value={values.priority}
                onChange={handleInputChange}
                {...(errors.priority && {
                  error: true,
                  helperText: errors.priority,
                })}
              />
              <TextField
                id="outlined-number"
                variant="filled"
                size="small"
                type="number"
                InputProps={InputProps}
                style={{ width: "20%", marginInline: 5 }}
                label={<FormattedMessage id="time" />}
                name="time"
                value={values.time}
                onChange={handleInputChange}
                {...(errors.time && { error: true, helperText: errors.time })}
              />
              <TextField
                id="outlined-select"
                select
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "20%", marginInline: 5 }}
                label={<FormattedMessage id="time_type" />}
                name="time_type"
                value={values.time_type}
                onChange={handleInputChange}
                {...(errors.time_type && {
                  error: true,
                  helperText: errors.time_type,
                })}
                defaultValue={"Hour"}
              >
                <MenuItem value={"Hour"}>
                  <FormattedMessage id="Hour" />
                </MenuItem>
                <MenuItem value={"Day"}>
                  <FormattedMessage id="Day" />
                </MenuItem>
                <MenuItem value={"Week"}>
                  <FormattedMessage id="Week" />
                </MenuItem>
              </TextField>
              {/* <MenuItem value={0}>
                  <em>None</em>
                </MenuItem> */}

              {/* {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
            </div>
            <div className={classes.withOutSpaceRow}>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      name="is_prepration"
                      checked={values.is_prepration}
                      onChange={(e) =>
                        handleInputChange(
                          convertToDefEventPara(
                            "is_prepration",
                            e.target.checked
                          )
                        )
                      }
                      color="primary"
                    />
                  }
                  label={<FormattedMessage id="is_prepration" />}
                />
                {/* {errors.is_prepration && (
                  <FormHelperText error>{errors.is_prepration}</FormHelperText>
                )} */}
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      name="is_active"
                      checked={values.is_active}
                      onChange={(e) =>
                        handleInputChange(
                          convertToDefEventPara("is_active", e.target.checked)
                        )
                      }
                      color="primary"
                    />
                  }
                  label={<FormattedMessage id="is_active" />}
                />
                {/* {errors.is_active && (
                  <FormHelperText error>{errors.is_active}</FormHelperText>
                )} */}
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      name="more_result"
                      checked={values.more_result}
                      onChange={(e) =>
                        handleInputChange(
                          convertToDefEventPara("more_result", e.target.checked)
                        )
                      }
                      color="primary"
                    />
                  }
                  label={<FormattedMessage id="more_result" />}
                />
                {/* {errors.is_active && (
                  <FormHelperText error>{errors.is_active}</FormHelperText>
                )} */}
              </div>
            </div>
            {values.more_result && (
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-select"
                  select
                  variant="filled"
                  size="small"
                  // fullWidth
                  InputProps={InputProps}
                  style={{ width: "25%", marginInline: 5 }}
                  label={<FormattedMessage id="result_type" />}
                  name="result_type"
                  value={values.result_type}
                  onChange={handleInputChange}
                  {...(errors.result_type && {
                    error: true,
                    helperText: errors.result_type,
                  })}
                  defaultValue={"Urine"}
                >
                  <MenuItem value={"Urine"}>Urine</MenuItem>
                  <MenuItem value={"Stool"}>Stool</MenuItem>
                  <MenuItem value={"CBC Diff 3"}>CBC Diff 3</MenuItem>
                  <MenuItem value={"CBC Diff 5"}>CBC Diff 5</MenuItem>
                  <MenuItem value={"Semen"}>Semen</MenuItem>
                </TextField>
              </div>
            )}
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%" }}
                label={<FormattedMessage id="precautons" />}
                name="precautons"
                checked={values.precautons}
                onChange={handleInputChange}
                {...(errors.precautons && {
                  error: true,
                  helperText: errors.precautons,
                })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%" }}
                label={<FormattedMessage id="defult_result" />}
                name="defult_result"
                checked={values.defult_result}
                onChange={handleInputChange}
                {...(errors.defult_result && {
                  error: true,
                  helperText: errors.defult_result,
                })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%" }}
                label={<FormattedMessage id="defult_comment" />}
                name="defult_comment"
                checked={values.defult_comment}
                onChange={handleInputChange}
                {...(errors.defult_comment && {
                  error: true,
                  helperText: errors.defult_comment,
                })}
              />
            </div>
          </Form>
        </Container>
      </Modal>

      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
      <DepartmentAddModal
        open={DepartmentDialogOpen}
        setOpen={setDepartmentDialogOpen}
      />
      <GroupAddModal open={GroupDialogOpen} setOpen={setGroupDialogOpen} />
    </Fragment>
  );
};

export default AddModal;
