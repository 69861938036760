import {
  Button,
  Container,
  Modal,
  Snackbar,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import SaveIcon from "@material-ui/icons/Save";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import { InputProps, useStyles } from "./ComponentsStyle/ModalStyle";
import { PostClientInfo } from "../ClientAPI";
import { FormattedMessage } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFValues = {
  branch_name: "",
  header_image: null,
  header_image_url: "",
  footer_image: null,
  footer_image_url: "",
};

export const AddModal = ({ open, setOpen }) => {
  const classes = useStyles();
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("branch_name" in fieldValues)
      temp.branch_name = fieldValues.branch_name
        ? ""
        : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    GeneralInputForm(initialFValues, null, true, false, validate, null);

  async function handleHeaderImageChange(e) {
    await setValues({
      ...values,
      header_image: e.target.files[0] ? e.target.files[0] : "",
      header_image_url: e.target.files[0]
        ? URL.createObjectURL(e.target.files[0])
        : "",
    });
    // handleInputChange(
    //   convertToDefEventPara(
    //     "header_image",
    //     e.target.files[0] ? e.target.files[0] : ""
    //   )
    // );
    // handleInputChange(
    //   convertToDefEventPara(
    //     "header_image_url",
    //     e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : ""
    //   )
    // );
    console.log(e.target.files);
    console.log(URL.createObjectURL(e.target.files[0]));
  }

  async function handleFooterImageChange(e) {
    await setValues({
      ...values,
      footer_image: e.target.files[0] ? e.target.files[0] : "",
      footer_image_url: e.target.files[0]
        ? URL.createObjectURL(e.target.files[0])
        : "",
    });
    // handleInputChange(
    //   convertToDefEventPara(
    //     "footer_image",
    //     e.target.files[0] ? e.target.files[0] : ""
    //   )
    // );
    // handleInputChange(
    //   convertToDefEventPara(
    //     "footer_image_url",
    //     e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : ""
    //   )
    // );
    console.log(e.target.files);
    console.log(URL.createObjectURL(e.target.files[0]));
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };
  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <Button
              variant="contained"
              style={{ marginInline: 20 }}
              onClick={async (event) => {
                console.log(values);
                if (validate(values)) {
                  let result = await PostClientInfo(event, values);
                  if (result.success) {
                    setOpenSuccessAlert(true);
                  } else {
                    setOpenErrorAlert(true);
                  }
                } else {
                  setOpenErrorAlert(true);
                }
              }}
              startIcon={<SaveIcon />}
            >
              {<FormattedMessage id="create" />}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
                resetForm();
              }}
            >
              {<FormattedMessage id="close" />}
            </Button>
          </div>
          <Form className={classes.form}>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                fullWidth
                InputProps={InputProps}
                size="small"
                label={<FormattedMessage id="client" />}
                name="branch_name"
                value={values.branch_name}
                required
                onChange={handleInputChange}
                {...(errors.branch_name && {
                  error: true,
                  helperText: errors.branch_name,
                })}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                // multiple
                onChange={handleHeaderImageChange}
                type="file"
              />
              <label htmlFor="contained-button-file">
                <Button
                  style={{ marginInline: 20 }}
                  variant="contained"
                  component="span"
                  color="default"
                  startIcon={<CloudUploadIcon />}
                >
                  {<FormattedMessage id="header_image" />}
                </Button>
              </label>
            </div>
            <div className={classes.withOutSpaceRow}>
              <img
                alt="slider img"
                src={
                  values.header_image_url !== ""
                    ? values.header_image_url
                    : require("../../../app/images/empty_image.jpg")
                }
                style={{ objectFit: "contain" }}
                width={"100%"}
                height={300}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <input
                accept="image/*"
                className={classes.input}
                id="contained-footer-button-file"
                // multiple
                onChange={handleFooterImageChange}
                type="file"
              />
              <label htmlFor="contained-footer-button-file">
                <Button
                  style={{ marginInline: 20 }}
                  variant="contained"
                  component="span"
                  color="default"
                  startIcon={<CloudUploadIcon />}
                >
                  {<FormattedMessage id="footer_image" />}
                </Button>
              </label>
            </div>
            <div className={classes.withOutSpaceRow}>
              <img
                alt="slider img"
                src={
                  values.footer_image_url !== ""
                    ? values.footer_image_url
                    : require("../../../app/images/empty_image.jpg")
                }
                style={{ objectFit: "contain" }}
                width={"100%"}
                height={300}
              />
            </div>
          </Form>
        </Container>
      </Modal>

      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
