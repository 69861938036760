import { getFromLocalStorage } from "../../app/Utility/CacheStore";
import axiosInstance from "../../app/axiosInstance";

export const GetCurrencyAutoComplete = (search = "", client) =>
  axiosInstance.get(
    `/api/currencies/currencies_autoComplete/?search=${search}&client=${client}`
  );

export const PostCurrencyExchange = async (e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  const clientinfo = await getFromLocalStorage("clientinfo");

  formData.append("currency", postData.currency);
  formData.append("currency_exchange", postData.currency_exchange);
  formData.append("client", clientinfo.id);

  axiosInstance.post(`api/currencyexchange/currencyExchange/`, formData);
};

export const GetCurrencyExchangeDetails = (id) =>
  axiosInstance
    .get(`api/currencyexchange/currencyExchange_detail/${id}/`)
    .then(function (response) {
      return response.data;
    });

export const EditCurrencyExchange = (id, e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("currency", postData.currency);
  formData.append("currency_exchange", postData.currency_exchange);
  axiosInstance.put(
    `api/currencyexchange/currencyExchange_detail/${id}/`,
    formData
  );
};

export const DeleteCurrencyExchange = (id) =>
  axiosInstance.delete(`api/currencyexchange/currencyExchange_detail/${id}/`);
