import { getFromLocalStorage } from "../../app/Utility/CacheStore";
import axiosInstance from "../../app/axiosInstance";

export const GetTestAutoComplete = (search = "", client) =>
  axiosInstance.get(
    `/api/testManagement/test_autoComplete/?search=${search}&client=${client}`
  );

export const GetCurrenciesAutoComplete = (search = "", client) =>
  axiosInstance.get(
    `/api/currencies/currencies_details_autoComplete/?client=${client}`
  );

export const GetPurchaseDetails = (id) =>
  axiosInstance
    .get(`api/purchaseinvoice/purchase_invoice_detail/${id}/`)
    .then(function (response) {
      return response.data;
    });

export const PostPurchase = async (e, postData) => {
  e.preventDefault();
  const clientinfo = await getFromLocalStorage("clientinfo");

  let invoice_list = Object.assign([], postData.purchase_invoice_items);
  let invoice_list_for_Post = [];
  for (var item of invoice_list) {
    invoice_list_for_Post = invoice_list_for_Post.concat({
      test: item.test.id,
      price: item.price,
      qty: item.qty,
      total_amount: item.total_amount,
    });
  }
  let total_amount = invoice_list_for_Post.reduce(
    (total, item) =>
      (total = parseFloat(total) + parseFloat(item.total_amount)),
    0
  );
  let formData = {
    client: clientinfo.id,

    description: postData.description,
    vender: postData.vender.id,
    currencey: postData.currencey,
    currencey_exchange: postData.currencey_exchange,
    vender_invoice_number: postData.vender_invoice_number,
    invoice_date: postData.invoice_date,
    total_amount: total_amount,
    purchase_invoice_items: invoice_list_for_Post,
  };
  return axiosInstance.post(
    `api/purchaseinvoice/purchase_invoice/`,
    JSON.stringify(formData)
  );
};
export const EditPurchase = (id, e, postData) => {
  e.preventDefault();
  let invoice_list = Object.assign([], postData.purchase_invoice_items);
  let invoice_list_for_Post = [];
  for (var item of invoice_list) {
    invoice_list_for_Post = invoice_list_for_Post.concat({
      id: item.id,
      test: item.test.id,
      price: item.price,
      qty: item.qty,
      total_amount: item.total_amount,
    });
  }
  let total_amount = invoice_list_for_Post.reduce(
    (total, item) =>
      (total = parseFloat(total) + parseFloat(item.total_amount)),
    0
  );
  let formData = {
    description: postData.description,
    vender: postData.vender.id,
    currencey: postData.currencey,
    currencey_exchange: postData.currencey_exchange,
    vender_invoice_number: postData.vender_invoice_number,
    invoice_date: postData.invoice_date,
    total_amount: total_amount,
    purchase_invoice_items: invoice_list_for_Post,
  };
  axiosInstance.put(
    `api/purchaseinvoice/purchase_invoice_detail/${id}/`,
    JSON.stringify(formData)
  );
};
export const GetPricForTest = (id) =>
  axiosInstance.get(`/api/purchasepricinglist/get_price_for_test/${id}/`);
export const DeletePurchase = (id) =>
  axiosInstance.delete(`api/purchaseinvoice/purchase_invoice_detail/${id}/`);
