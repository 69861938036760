import {
  Button,
  Container,
  FormControlLabel,
  IconButton,
  Modal,
  Snackbar,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";

import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import MuiAlert from "@material-ui/lab/Alert";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import {
  useStyles,
  InputProps,
  InputlistProps,
} from "./ComponentsStyle/ModalStyle";
import { GetTestAutoComplete, PostOffers } from "../OffersAPI";
import BaseAutoComplete from "../../../app/generalComponent/BaseAutoComplete";
import { useSelector } from "react-redux";
import { selectClientInfo } from "../../Authentecation/AuthSlice";
import { FormattedMessage } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFValues = {
  offer_name: "",
  description: "",
  offeritems: [],
  is_active: true,
};
const initialAddNewValues = {
  test: "",
  test_textInput: "",
  price: "",
};
export const AddModal = ({ data, open, setOpen }) => {
  const classes = useStyles();
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [testOptions, setTestOptions] = React.useState([]);
  const client = useSelector(selectClientInfo);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("offer_name" in fieldValues)
      temp.offer_name = fieldValues.offer_name ? "" : "This field is required.";

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const validateAddNew = (fieldValues = addNewValues) => {
    let temp = { ...addErrors };
    if ("price" in fieldValues)
      temp.price = fieldValues.price ? "" : "This field is required.";
    if ("test" in fieldValues)
      temp.test = fieldValues.test ? "" : "This field is required.";

    setAddErrors({
      ...temp,
    });
    if (fieldValues === addNewValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    addNewValues,
    errors,
    setErrors,
    addErrors,
    setAddErrors,
    handleInputChange,
    convertToDefEventPara,
    handleInputListChange,
    handleAddNewInputChange,
    resetForm,
  } = GeneralInputForm(
    initialFValues,
    initialAddNewValues,
    true,
    true,
    validate,
    validateAddNew
  );

  const fetchTestAutoCompleteData = async (search = "") => {
    const response = await GetTestAutoComplete(search, client.id);
    const test = await response.data;
    console.log(test);
    setTestOptions(test);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };
  const handleAddNewValues = () => {
    if (validateAddNew(addNewValues)) {
      let newList = Object.assign([], values.offeritems);
      newList = newList.concat({
        offer_item_price: addNewValues.price,
        offer: values.id,
        test: addNewValues.test,
      });
      setValues({
        ...values,
        offeritems: newList,
      });
    }
  };
  const handleDeleteListItem = (index) => {
    let newList = Object.assign([], values.offeritems);
    newList.splice(index, 1);
    setValues({
      ...values,
      offeritems: newList,
    });
  };

  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <Button
              variant="contained"
              style={{ marginInline: 20 }}
              onClick={(event) => {
                console.log(values);
                console.log(addNewValues);
                if (validate(values)) {
                  PostOffers(event, values);
                  setOpenSuccessAlert(true);
                } else {
                  setOpenErrorAlert(true);
                }
              }}
              startIcon={<SaveIcon />}
            >
              <FormattedMessage id="create" />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
                resetForm();
              }}
            >
              <FormattedMessage id="close" />
            </Button>
          </div>
          <Form className={classes.form}>
            <h4>
              <FormattedMessage id="offer_details" />
            </h4>
            <br />
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "42.5%", marginInline: 5 }}
                label={<FormattedMessage id="offer_name" />}
                name="offer_name"
                value={values.offer_name}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                required
                onChange={handleInputChange}
                {...(errors.offer_name && {
                  error: true,
                  helperText: errors.offer_name,
                })}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "42.5%", marginInline: 5 }}
                label={<FormattedMessage id="description" />}
                name="description"
                value={values.description}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                required
                onChange={handleInputChange}
                {...(errors.description && {
                  error: true,
                  helperText: errors.description,
                })}
              />
              <div style={{ width: "15%", marginInline: 5 }}>
                <FormControlLabel
                  control={
                    <Switch
                      name="is_active"
                      checked={values.is_active}
                      onChange={(e) =>
                        handleInputChange(
                          convertToDefEventPara("is_active", e.target.checked)
                        )
                      }
                      color="primary"
                    />
                  }
                  label={<FormattedMessage id="is_active" />}
                />
                {/* {errors.is_prepration && (
                  <FormHelperText error>{errors.is_prepration}</FormHelperText>
                )} */}
              </div>
            </div>
            <h4>
              <FormattedMessage id="offer_list_details" />
            </h4>
            <br />
            <div className={classes.withOutSpaceRow}>
              <BaseAutoComplete
                label={<FormattedMessage id="test_name" />}
                name="test"
                variant="filled"
                size="small"
                customStyle={{ width: "42.5%", marginInline: 5 }}
                options={testOptions}
                optionLable="english_name"
                fetchData={fetchTestAutoCompleteData}
                inputValue={addNewValues.test_textInput}
                onSelectChange={async (newValue) => {
                  await handleAddNewInputChange(
                    convertToDefEventPara("test", newValue ? newValue.id : null)
                  );
                }}
              />
              <TextField
                type="number"
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "42.5%", marginInline: 5 }}
                label={<FormattedMessage id="price" />}
                name="price"
                value={addNewValues.price}
                onChange={handleAddNewInputChange}
                required
                {...(addErrors.price && {
                  error: true,
                  helperText: addErrors.price,
                })}
              />
              <IconButton
                aria-label="Add"
                size="medium"
                color="primary"
                onClick={handleAddNewValues}
              >
                <AddIcon fontSize="inherit" />
              </IconButton>
            </div>
            <div>
              <ul className={classes.ul}>
                {values.offeritems.map((item, index) => (
                  <li key={index} className={classes.li}>
                    <div>
                      <TextField
                        id="outlined-basic"
                        variant="filled"
                        InputProps={InputlistProps}
                        size="small"
                        style={{ width: "42.5%", marginInline: 5 }}
                        label={<FormattedMessage id="test_name" />}
                        name="test"
                        value={item.test}
                      />
                      <TextField
                        id="outlined-basic"
                        variant="filled"
                        InputProps={InputlistProps}
                        size="small"
                        style={{ width: "42.5%", marginInline: 5 }}
                        label={<FormattedMessage id="price" />}
                        name="offer_item_price"
                        value={item.offer_item_price}
                        onChange={(e) =>
                          handleInputListChange(e, item.id, "offeritems")
                        }
                      />
                      <IconButton
                        aria-label="Delete"
                        size="medium"
                        color="primary"
                        onClick={handleDeleteListItem.bind(this, index)}
                      >
                        <DeleteOutline fontSize="inherit" />
                      </IconButton>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Form>
        </Container>
      </Modal>
      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
