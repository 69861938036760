import axiosInstance from "../../app/axiosInstance";

export const GetAccountAutoComplete = (search = "") =>
  axiosInstance.get(
    `/api/accountguide/allaccount_autoComplete?search=${search}`
  );
export const GetVenderAutoComplete = (search = "",client) =>
  axiosInstance.get(`/api/venders/venders_autoComplete?search=${search}&client=${client}`);
export const PostJournalEntry = (e, postData) => {
  e.preventDefault();
  let journal_list = Object.assign([], postData.journal_entry_items);
  let journal_list_for_Post = [];
  for (var item of journal_list) {
    journal_list_for_Post = journal_list_for_Post.concat({
      account: item.account.id,
      partner: item.partner?item.partner.id:null,
      account_level: item.account.account_level,
      label: item.label,
      debit: item.debit,
      credit: item.credit,
    });
  }
  let formData = {
    journal_date: postData.journal_date,
    number: postData.number,
    reference: postData.reference,
    journal: postData.journal,
    total_amount: postData.total_debit,
    is_posted: postData.is_posted,
    journal_entry_items: journal_list_for_Post,
  };
  axiosInstance.post(
    `api/journalentries/journal_entry/`,
    JSON.stringify(formData)
  );
};

export const GetJournalEntryDetails = (id) =>
  axiosInstance
    .get(`api/journalentries/journal_entry_detail/${id}/`)
    .then(function (response) {
      return response.data;
    });

export const EditJournalEntry = (id, e, postData) => {
  e.preventDefault();
  let journal_list = Object.assign([], postData.journal_entry_items);
  let journal_list_for_Post = [];
  for (var item of journal_list) {
    journal_list_for_Post = journal_list_for_Post.concat({
      id: item.id,
      account: item.account.id,
      partner: item.partner?item.partner.id:null,
      account_level: item.account.account_level,
      label: item.label,
      debit: item.debit,
      credit: item.credit,
    });
  }
  let formData = {
    journal_date: postData.journal_date,
    number: postData.number,
    reference: postData.reference,
    journal: postData.journal,
    total_amount: postData.total_debit,
    is_posted: postData.is_posted,
    journal_entry_items: journal_list_for_Post,
  };
  axiosInstance.put(
    `api/journalentries/journal_entry_detail/${id}/`,
    JSON.stringify(formData)
  );
};

export const DeleteJournalEntry = (id) =>
  axiosInstance.delete(`api/journalentries/journal_entry_detail/${id}/`);
