import axios from "axios";

// export const baseURL = "http://206.189.137.141:8000/";
export const baseURL = "http://206.189.137.141:8000/";

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 500000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

export default axiosInstance;
