import {
  Button,
  Container,
  // FormHelperText,
  IconButton,
  MenuItem,
  Modal,
  Snackbar,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import {
  EditCustomerAccount,
  GetPricingTypeAutoComplete,
} from "../CustomerAccountAPI";
import { AddModal as PricingTypeAddModal } from "../../PricingType/Components/AddModal";
import {
  useStyles,
  InputProps,
  DateInputProps,
} from "./ComponentsStyle/ModalStyle";
import BaseAutoComplete from "../../../app/generalComponent/BaseAutoComplete";
import { useSelector } from "react-redux";
import { selectClientInfo } from "../../Authentecation/AuthSlice";
import { FormattedMessage } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const initialFValues = {
  id: "",
  patient_name: "",
  gender: "",
  age: "",
  age_type: "",
  phone_number: "",
  email: "",
  customer_pricing_type: "",
  customer_pricing_type_textInput: "",
  address: "",
  medical_info: "",
};

export const EditModal = ({ data, open, setOpen }) => {
  const [pricingTypeDialogOpen, setPricingTypeDialogOpen] = useState(false);
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [pricingTypeOptions, setPricingTypeOptions] = React.useState([]);
  const classes = useStyles();
  const client = useSelector(selectClientInfo);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("patient_name" in fieldValues)
      temp.patient_name = fieldValues.patient_name
        ? ""
        : "This field is required.";
    // if ("is_prepration" in fieldValues)
    //   temp.is_prepration =
    //     fieldValues.is_prepration === true ? "" : "This field is required.";
    // if ('departmentId' in fieldValues)
    //     temp.departmentId = fieldValues.departmentId.length != 0 ? "" : "This field is required."
    // if ('email' in fieldValues)
    //     temp.email = (/$^|.+@.+..+/).test(fieldValues.email) ? "" : "Email is not valid."
    // if ('mobile' in fieldValues)
    //     temp.mobile = fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required."
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    convertToDefEventPara,
    resetForm,
  } = GeneralInputForm(initialFValues, null, true, false, validate, null);

  const fetchPricingTypeAutoCompleteData = async (search = "") => {
    const response = await GetPricingTypeAutoComplete(search, client.id);
    const pricingtype = await response.data;
    console.log(pricingtype);
    setPricingTypeOptions(pricingtype);
  };

  const handlePricingTypeDialogOpen = () => {
    setPricingTypeDialogOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };

  useEffect(() => {
    if (data !== null) {
      setValues(data);
    }
  }, [data, setValues]);

  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <Button
              variant="contained"
              style={{ marginInline: 20 }}
              onClick={(event) => {
                console.log(values);
                if (validate(values)) {
                  EditCustomerAccount(data.id, event, values);
                  setOpenSuccessAlert(true);
                } else {
                  setOpenErrorAlert(true);
                }
              }}
              startIcon={<SaveIcon />}
            >
               <FormattedMessage id="create" />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
                resetForm();
              }}
            >
               <FormattedMessage id="close" />
            </Button>
          </div>
          <Form className={classes.form}>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="patient_name" />}
                name="patient_name"
                value={values.patient_name}
                required
                onChange={handleInputChange}
                {...(errors.patient_name && {
                  error: true,
                  helperText: errors.patient_name,
                })}
              />
              <TextField
                id="outlined-select"
                select
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "20%", marginInline: 5 }}
                label={<FormattedMessage id="gender" />}
                name="gender"
                value={values.gender}
                onChange={handleInputChange}
                {...(errors.gender && {
                  error: true,
                  helperText: errors.gender,
                })}
                defaultValue={1}
              >
                {/* <MenuItem value={0}>
                  <em>None</em>
                </MenuItem> */}
                <MenuItem value={"Male"}>
                  <FormattedMessage id="Male" />
                </MenuItem>
                <MenuItem value={"Female"}>
                  <FormattedMessage id="Female" />
                </MenuItem>
                {/* {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
              </TextField>
              <TextField
                id="outlined-number"
                variant="filled"
                size="small"
                type="number"
                InputProps={InputProps}
                style={{ width: "20%", marginInline: 5 }}
                label={<FormattedMessage id="age" />}
                name="age"
                value={values.age}
                onChange={handleInputChange}
                {...(errors.age && { error: true, helperText: errors.age })}
              />
              <TextField
                id="outlined-select"
                select
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "20%", marginInline: 5 }}
                label={<FormattedMessage id="age_type" />}
                name="age_type"
                value={values.age_type}
                onChange={handleInputChange}
                {...(errors.age_type && {
                  error: true,
                  helperText: errors.age_type,
                })}
                defaultValue={1}
              >
                {/* <MenuItem value={0}>
                  <em>None</em>
                </MenuItem> */}
                <MenuItem value={"Years"}>Years</MenuItem>
                <MenuItem value={"Week"}>Week</MenuItem>
                <MenuItem value={"Day"}>Day</MenuItem>
                {/* {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
              </TextField>
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="phone_number" />}
                name="phone_number"
                value={values.phone_number}
                onChange={handleInputChange}
                {...(errors.phone_number && {
                  error: true,
                  helperText: errors.phone_number,
                })}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="email" />}
                name="email"
                value={values.email}
                onChange={handleInputChange}
                {...(errors.email && {
                  error: true,
                  helperText: errors.email,
                })}
              />
            </div>

            <div className={classes.withOutSpaceRow}>
              <BaseAutoComplete
                label={<FormattedMessage id="customer_pricing_type" />}
                name="customer_pricing_type"
                variant="filled"
                size="small"
                customStyle={{ width: "90%", marginInlineEnd: 5 }}
                options={pricingTypeOptions}
                optionLable="pricingtype"
                fetchData={fetchPricingTypeAutoCompleteData}
                inputValue={values.customer_pricing_type_textInput}
                onSelectChange={async (newValue) => {
                  await handleInputChange(
                    convertToDefEventPara(
                      "customer_pricing_type",
                      newValue ? newValue.id : null
                    )
                  );
                }}
              />
              <IconButton
                aria-label="Pricing Type"
                size="medium"
                color="primary"
                onClick={handlePricingTypeDialogOpen}
              >
                <AddIcon fontSize="inherit" />
              </IconButton>
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%" }}
                label={<FormattedMessage id="medical_info" />}
                name="medical_info"
                checked={values.medical_info}
                onChange={handleInputChange}
                {...(errors.medical_info && {
                  error: true,
                  helperText: errors.medical_info,
                })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%" }}
                label={<FormattedMessage id="address" />}
                name="address"
                checked={values.address}
                onChange={handleInputChange}
                {...(errors.address && {
                  error: true,
                  helperText: errors.address,
                })}
              />
            </div>
          </Form>
        </Container>
      </Modal>

      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
      <PricingTypeAddModal
        open={pricingTypeDialogOpen}
        setOpen={setPricingTypeDialogOpen}
      />
    </Fragment>
  );
};
