import {
  Button,
  Container,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Snackbar,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";

import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import MuiAlert from "@material-ui/lab/Alert";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import {
  useStyles,
  InputProps,
  InputlistProps,
  DateInputProps,
} from "./ComponentsStyle/ModalStyle";
import BaseAutoComplete from "../../../app/generalComponent/BaseAutoComplete";
import {
  GetCurrenciesAutoComplete,
  GetRegistrationsAutoComplete,
  GetTestAutoComplete,
  PostRegistrationReturn,
} from "../CustomerRegistrationReturnApi";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectClientInfo } from "../../Authentecation/AuthSlice";
import { FormattedMessage } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFValues = {
  id: "",
  description: "",
  invoice_date: new Date().toLocaleDateString(),
  currencey: "",
  currencey_exchange: "",
  customer_registration: "",
  customer_registration_return_items: [],
  loading: false,
};
const initialAddNewValues = {
  test: "",
  price: "",
  // qty: "",
  // total_amount: "",
};
export const AddModal = ({ data, open, setOpen }) => {
  const classes = useStyles();
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [testOptions, setTestOptions] = React.useState([]);
  const [customerRegistrationOptions, setCustomerRegistrationOptions] =
    React.useState([]);
  const client = useSelector(selectClientInfo);

  const validate = (fieldValues = values) => {
    let temp = { ...addErrors };
    if ("currencey" in fieldValues)
      temp.currencey = fieldValues.currencey ? "" : "This field is required.";
    if ("customer_registration" in fieldValues)
      temp.customer_registration = fieldValues.customer_registration
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const validateAddNew = (fieldValues = addNewValues) => {
    let temp = { ...addErrors };
    if ("test" in fieldValues)
      temp.test = fieldValues.test ? "" : "This field is required.";

    setAddErrors({
      ...temp,
    });
    if (fieldValues === addNewValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    addNewValues,
    errors,
    setErrors,
    addErrors,
    setAddErrors,
    handleInputChange,
    convertToDefEventPara,
    handleInputListChange,
    handleAddNewInputChange,
    resetForm,
  } = GeneralInputForm(
    initialFValues,
    initialAddNewValues,
    true,
    true,
    validate,
    validateAddNew
  );

  const fetchTestAutoCompleteData = async (search = "") => {
    const response = await GetTestAutoComplete(
      search,
      values.customer_registration ? values.customer_registration.id : 0
    );
    const test = await response.data;
    console.log(test);
    setTestOptions(test);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };
  const isFound = (item) =>
    values.customer_registration_return_items.some((element) => {
      if (element.test.id === item.id) {
        return true;
      }

      return false;
    });

  const handleAddNewValues = async () => {
    if (validateAddNew(addNewValues)) {
      let newList = Object.assign(
        [],
        values.customer_registration_return_items
      );
      let exists = await isFound(addNewValues.test);
      if (!exists) {
        newList = newList.concat({
          total_amount:
            parseFloat(addNewValues.qty) * parseFloat(addNewValues.test.price),
          qty: addNewValues.qty,
          price: addNewValues.test.item_price,
          test: addNewValues.test,
        });
      } else {
        newList = newList.map((item) => {
          if (item.test.id === addNewValues.test.id) {
            let total_qty = parseFloat(addNewValues.qty) + parseFloat(item.qty);
            if (addNewValues.test.qty >= total_qty) {
              let newItem = {
                total_amount: total_qty * parseFloat(addNewValues.test.price),
                qty: total_qty,
                price: addNewValues.test.item_price,
                test: addNewValues.test,
              };
              return newItem;
            }
          }
          return item;
        });
      }
      setValues({
        ...values,
        customer_registration_return_items: newList,
      });
    }
  };
  const handleDeleteListItem = (index) => {
    let newList = Object.assign([], values.purchase_return_items);
    newList.splice(index, 1);
    setValues({
      ...values,
      purchase_return_items: newList,
    });
  };

  const currencyChange = async (e) => {
    let currencey_exchange = await currencies.find(
      (item) => item.currency_name === e.target.value
    )?.currency_for_CurrencyExchange_set[0].currency_exchange;
    await setValues({
      ...values,
      currencey: e.target.value,
      currencey_exchange: currencey_exchange,
    });
  };

  const fetchCustomerRegistrationAutoCompleteData = async (search = "") => {
    const response = await GetRegistrationsAutoComplete(search, client.id);
    const registrations = await response.data;
    console.log(registrations);
    setCustomerRegistrationOptions(registrations);
  };
  const loadCurrencies = async () => {
    const response = await GetCurrenciesAutoComplete(client.id);
    const currencies = await response.data;
    console.log(currencies);
    setCurrencies(currencies);
  };

  useEffect(() => {
    loadCurrencies();
  }, []);

  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            {!values.id && (
              <Button
                variant="contained"
                style={{ marginInline: 20 }}
                onClick={async (event) => {
                  try {
                    setValues({ ...values, loading: true });
                    console.log(values);
                    // console.log(addNewValues);
                    if (validate(values)) {
                      let response =await PostRegistrationReturn(event, values);
                      console.log(response)
                      if (response.data.id) {
                        setValues({
                          ...values,
                          id: response.data.id,
                          loading: false,
                        });
                      }
                      setOpenSuccessAlert(true);
                    } else {
                      setOpenErrorAlert(true);
                      setValues({ ...values, loading: false });
                    }
                  } catch (error) {
                    setOpenErrorAlert(true);
                    setValues({ ...values, loading: false });
                  }
                }}
                startIcon={<SaveIcon />}
              >
                {values.loading ? (
                  <FormattedMessage id="loading" />
                ) : (
                  <FormattedMessage id="create" />
                )}
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
                resetForm();
              }}
            >
              <FormattedMessage id="close" />
            </Button>
          </div>
          <Form className={classes.form}>
            <h4>
              <FormattedMessage id="customer_registration_return_details" />
            </h4>
            <br />
            <div className={classes.spaceAroundRow}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  variant="dialog"
                  label={<FormattedMessage id="invoice_date" />}
                  inputVariant="filled"
                  InputProps={DateInputProps}
                  format="dd/MM/yyyy"
                  name="invoice_date"
                  value={values.invoice_date}
                  onChange={(e) => {
                    handleInputChange(
                      convertToDefEventPara(
                        "invoice_date",
                        e ? e.toLocaleDateString() : ""
                      )
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
              {/* <BaseAutoComplete
                label="Partner"
                name="partner"
                variant="filled"
                size="small"
                customStyle={{ width: "100%", marginInline: 5 }}
                options={venderOptions}
                optionLable="vender_name"
                fetchData={fetchVenderAutoCompleteData}
                inputValue={values.vender ? values.vender.vender_name : ""}
                onSelectChange={async (newValue) => {
                  await handleInputChange(
                    convertToDefEventPara("vender", newValue ? newValue : null)
                  );
                }}
              /> */}
              <BaseAutoComplete
                label={<FormattedMessage id="invoice_id" />}
                name="customer_registration"
                variant="filled"
                size="small"
                customStyle={{ width: "100%", marginInline: 5 }}
                options={customerRegistrationOptions}
                optionLable="id"
                fetchData={fetchCustomerRegistrationAutoCompleteData}
                inputValue={
                  values.customer_registration
                    ? values.customer_registration.id.toString()
                    : ""
                }
                onSelectChange={async (newValue) => {
                  await handleInputChange(
                    convertToDefEventPara(
                      "customer_registration",
                      newValue ? newValue : null
                    )
                  );
                }}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-select"
                select
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%", marginInline: 5 }}
                label={<FormattedMessage id="currencey" />}
                name="currencey"
                value={values.currencey}
                onChange={currencyChange}
                {...(errors.currencey && {
                  error: true,
                  helperText: errors.currencey,
                })}
                defaultValue={1}
              >
                {/* <MenuItem value={0}>
                  <em>None</em>
                </MenuItem> */}
                {currencies.map((option) => (
                  <MenuItem key={option.id} value={option.currency_name}>
                    {option.currency_name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-number"
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%", marginInline: 5 }}
                label={<FormattedMessage id="currencey_exchange" />}
                name="currencey_exchange"
                value={values.currencey_exchange}
                {...(errors.currencey_exchange && {
                  error: true,
                  helperText: errors.currencey_exchange,
                })}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                fullWidth
                InputProps={InputProps}
                size="small"
                style={{ marginInline: 5 }}
                label={<FormattedMessage id="description" />}
                name="description"
                value={values.description}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                onChange={handleInputChange}
                {...(errors.description && {
                  error: true,
                  helperText: errors.description,
                })}
              />
            </div>
            <h4>
              <FormattedMessage id="customer_registration_return_list_details" />
            </h4>
            <br />
            <div className={classes.withOutSpaceRow}>
              <BaseAutoComplete
                label={<FormattedMessage id="test_name" />}
                name="test"
                variant="filled"
                size="small"
                customStyle={{ width: "42.5%", marginInline: 5 }}
                options={testOptions}
                optionLable="test"
                subOptionLable="english_name"
                fetchData={fetchTestAutoCompleteData}
                inputValue={
                  addNewValues.test ? addNewValues.test.english_name : ""
                }
                onSelectChange={async (newValue) => {
                  await handleAddNewInputChange(
                    convertToDefEventPara("test", newValue ? newValue : null)
                  );
                }}
              />
              <TextField
                type="number"
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "42.5%", marginInline: 5 }}
                label={<FormattedMessage id="price" />}
                name="item_price"
                value={addNewValues.test ? addNewValues.test.item_price : 0}
                // onChange={handleAddNewInputChange}
                required
                {...(addErrors.price && {
                  error: true,
                  helperText: addErrors.price,
                })}
              />
              {/* <TextField
                type="number"
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "42.5%", marginInline: 5 }}
                label="Qty"
                name="qty"
                value={addNewValues.qty}
                onChange={(e) => {
                  if (e.target.value <= addNewValues.test.qty) {
                    handleAddNewInputChange(e);
                  }
                }}
                required
                {...(addErrors.qty && {
                  error: true,
                  helperText: addErrors.qty,
                })}
              />
              <TextField
                type="number"
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "42.5%", marginInline: 5 }}
                label="Total"
                name="total_amount"
                value={(
                  parseFloat(addNewValues.qty) *
                  parseFloat(addNewValues.test ? addNewValues.test.price : 0)
                ).toString()}
                // onChange={handleAddNewInputChange}
                required
                {...(addErrors.total_amount && {
                  error: true,
                  helperText: addErrors.total_amount,
                })}
              /> */}
              <IconButton
                aria-label="Add"
                size="medium"
                color="primary"
                onClick={handleAddNewValues}
              >
                <AddIcon fontSize="inherit" />
              </IconButton>
            </div>
            <div>
              <ul className={classes.ul}>
                {values.customer_registration_return_items.map(
                  (item, index) => (
                    <li key={index} className={classes.li}>
                      <div className={classes.withOutSpaceRow}>
                        <TextField
                          id="outlined-basic"
                          variant="filled"
                          InputProps={InputlistProps}
                          size="small"
                          style={{ width: "42.5%", marginInline: 5 }}
                          label={<FormattedMessage id="test_name" />}
                          name="test"
                          value={item.test.test.english_name}
                        />
                        <TextField
                          id="outlined-basic"
                          variant="filled"
                          InputProps={InputlistProps}
                          size="small"
                          style={{ width: "42.5%", marginInline: 5 }}
                          label={<FormattedMessage id="price" />}
                          name="item_price"
                          value={item.test.item_price}
                          // onChange={(e) =>
                          //   handleInputListChange(
                          //     e,
                          //     item.id,
                          //     "purchase_return_items"
                          //   )
                          // }
                        />
                        {/* <TextField
                        id="outlined-basic"
                        variant="filled"
                        InputProps={InputlistProps}
                        size="small"
                        style={{ width: "42.5%", marginInline: 5 }}
                        label="Qty"
                        name="qty"
                        value={item.qty}
                        onChange={(e) =>
                          handleInputListChange(
                            e,
                            item.id,
                            "purchase_return_items"
                          )
                        }
                      /> */}
                        {/* <TextField
                        id="outlined-basic"
                        variant="filled"
                        InputProps={InputlistProps}
                        size="small"
                        style={{ width: "42.5%", marginInline: 5 }}
                        label="Total"
                        name="total_amount"
                        value={item.total_amount}
                        onChange={(e) =>
                          handleInputListChange(
                            e,
                            item.id,
                            "purchase_return_items"
                          )
                        }
                      /> */}
                        <IconButton
                          aria-label="Delete"
                          size="medium"
                          color="primary"
                          onClick={handleDeleteListItem.bind(this, index)}
                        >
                          <DeleteOutline fontSize="inherit" />
                        </IconButton>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          </Form>
        </Container>
      </Modal>
      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
