import { Button, Container, Modal, TextField } from "@material-ui/core";
import React, { Fragment, useEffect } from "react";

import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import { useStyles, ReadOnlyInputProps } from "./ComponentsStyle/ModalStyle";
import { FormattedMessage } from "react-intl";

const initialFValues = {
  currency_name: "",
  currency_symbol: "",
  country: "",
};

export const ViewModal = ({ data, open, setOpen }) => {
  const classes = useStyles();
  const { values, setValues } = GeneralInputForm(
    initialFValues,
    null,
    false,
    false,
    null,
    null
  );

  useEffect(() => {
    if (data !== null) {
      setValues(data);
    }
  }, [data, setValues]);

  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
              }}
            >
              <FormattedMessage id="close" />
            </Button>
          </div>
          <Form className={classes.form}>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={ReadOnlyInputProps}
                size="small"
                style={{ width: "100%", marginInline: 0 }}
                label={<FormattedMessage id="currency_name" />}
                name="currency_name"
                value={values.currency_name ? values.currency_name : ""}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={ReadOnlyInputProps}
                size="small"
                style={{ width: "100%", marginInline: 0 }}
                label={<FormattedMessage id="currency_symbol" />}
                name="currency_symbol"
                value={values.currency_symbol ? values.currency_symbol : ""}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={ReadOnlyInputProps}
                size="small"
                style={{ width: "100%", marginInline: 0 }}
                label={<FormattedMessage id="country" />}
                name="country"
                value={values.country ? values.country : ""}
              />
            </div>
          </Form>
        </Container>
      </Modal>
    </Fragment>
  );
};
