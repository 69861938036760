import React, { Fragment, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { DeleteNormal, GetNormalDetails } from "../TestAPI";
import AlertDialog from "../../../app/generalComponent/DeletingAlert";
import { Button, Modal, Container } from "@material-ui/core";
import { useStyles } from "../Components/ComponentsStyle/ModalStyle";
import BaseTable, { tableIcons } from "../../../app/generalComponent/BaseTable";
import { AddNormalModal } from "./AddNormalModal";
import { EditNormalModal } from "./EditNormalModal";
import { FormattedMessage } from "react-intl";

// import { jsPDF } from "jspdf";

export const NormalModal = ({ data, open, setOpen }) => {
  const [addOpen, setAddOpen] = useState(false);
  const [editeOpen, setEditeOpen] = useState(false);
  const [apiResultValues, setApiResultValues] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [rowId, setRowId] = useState(null);
  const classes = useStyles();
  const theme = useTheme();
  const tableRef = React.createRef();

  const LoadDetails = async (id) => {
    if (id !== null) {
      const response = await GetNormalDetails(id);
      const apiResultValues = {
        id: response.id,
        normal_value: response.normal_value,
        gender: response.gender,
        from_age: response.from_age,
        to_age: response.to_age,
        age_type: response.age_type,
        lower_limit: response.lower_limit,
        upper_limit: response.upper_limit,
        test: response.test ? response.test : "",
        lower_comment: response.lower_comment,
        upper_comment: response.upper_comment,
      };
      console.log(response);
      setApiResultValues(apiResultValues);
    }
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };
  const handelConfirmDelete = () => {
    console.log("id " + rowId);
    DeleteNormal(rowId);
    setDeleteDialogOpen(false);
  };
  const columns = [
    { title: <FormattedMessage id="gender" />, field: "gender" },
    { title: <FormattedMessage id="from_age" />, field: "from_age" },
    { title: <FormattedMessage id="to_age" />, field: "to_age" },
    { title: <FormattedMessage id="age_type" />, field: "age_type" },
    { title: <FormattedMessage id="normal_value" />, field: "normal_value" },
    { title: <FormattedMessage id="lower_limit" />, field: "lower_limit" },
    { title: <FormattedMessage id="upper_limit" />, field: "upper_limit" },
    { title: <FormattedMessage id="lower_comment" />, field: "lower_comment" },
    { title: <FormattedMessage id="upper_comment" />, field: "upper_comment" },
  ];

  return (
    <Modal open={open}>
      <Container className={classes.container}>
        <div className={classes.buttonContainerStyle}>
          <Button
            style={{ marginInline: 20 }}
            variant="contained"
            color=""
            onClick={() => setAddOpen(true)}
          >
            <FormattedMessage id="create_new" />
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            <FormattedMessage id="close" />
          </Button>
        </div>
        <AddNormalModal test_id={data} open={addOpen} setOpen={setAddOpen} />
        <EditNormalModal
          data={apiResultValues}
          open={editeOpen}
          setOpen={setEditeOpen}
        />

        <AlertDialog
          open={deleteDialogOpen}
          handleClose={handleDeleteDialogClose}
          handleConfirm={handelConfirmDelete}
        />
        <div style={{ marginBlock: 20 }} />
        <div style={{ padding: 10 }}>
          <BaseTable
            refrence={tableRef}
            title={<FormattedMessage id="normal_range" />}
            columns={columns}
            data={(query) =>
              new Promise((resolve, reject) => {
                let url = "http://206.189.137.141:8000/api/testManagement/normal/?";
                url += "test_id=" + data;
                url += "&per_page=" + query.pageSize;
                url += "&page=" + (query.page + 1);
                fetch(url)
                  .then((response) => response.json())
                  .then((result) => {
                    resolve({
                      data: result.data,
                      page: result.page - 1,
                      totalCount: result.count,
                    });
                  });
              })
            }
            actions={[
              {
                icon: tableIcons.Refresh,
                tooltip: "Refresh",
                onClick: (event, rowData) => {
                  const scopedRef = tableRef.current;
                  scopedRef.onQueryChange();
                },
                isFreeAction: true,
              },
              {
                icon: tableIcons.Edit,
                tooltip: "Edit",
                onClick: (event, rowData) => {
                  LoadDetails(rowData.id);
                  setEditeOpen(true);
                },
              },
              {
                icon: tableIcons.Delete,
                tooltip: "Delete",
                onClick: (event, rowData) => {
                  setDeleteDialogOpen(true);
                  setRowId(rowData.id);
                },
              },
            ]}
            options={{
              maxBodyHeight: "50vh",
              search: false,
              // columnsButton:true,
              headerStyle: {
                // background: theme.palette.primary.main,
                // color: "white",
                fontWeight: "bold",
              },
              rowStyle: (data) =>
                data.tableData.id % 2 === 0
                  ? { background: theme.palette.common.white }
                  : { background: theme.palette.grey[100] },
              // grouping:true
            }}
          />
        </div>
      </Container>
    </Modal>
  );
};

// function CustomExportPdf(columns, data = [], filename = "data") {
//   try {
//     let finalData = data;
//     if (data.length && !Array.isArray(data[0])) {
//       if (typeof data[0] === "object") {
//         finalData = data.map((row) =>
//           columns.map((col) =>
//             col.exportTransformer
//               ? col.exportTransformer(row)
//               : row[col.field]
//           )
//         );
//       }
//     }
//       const content = {
//         startY: 50,
//         head: [columns.map((col) => col.title)],
//         body: finalData,
//       };
//       const unit = "pt";
//       const size = "A4";
//       const orientation = "landscape";
//       const doc = new jsPDF(orientation, unit, size);
//       doc.setFontSize(15);
//       doc.text(filename, 40, 40);
//       doc.autoTable(content);
//       doc.save(filename + Date.now().toString() + ".pdf");
//     // }
//   } catch (err) {
//     console.error(
//       `exporting pdf : ${err}`
//     );
//   }
// }
