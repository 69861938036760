import {
  Button,
  Container,
  Modal,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { Fragment, useState } from "react";
import {
  Form,
  GeneralInputForm,
} from "../../../app/generalComponent/GeneralInputForm";
import {
  InputProps,
  ReadOnlyInputProps,
  useStyles,
} from "./ComponentsStyle/ModalStyle";
import MuiAlert from "@material-ui/lab/Alert";
import { useEffect } from "react";
import { PostCbc5Result, UpdateCbc5Result } from "../CustomerTestResultAPI";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFValues = {};

export const CbcExamDiff5 = ({
  open,
  setOpen,
  result,
  invoice_id,
  loadRefreshData,
}) => {
  const classes = useStyles();
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);


  const data = require("../NormalValue/DefaultCbcDiff5NormalValue.json");
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("x" in fieldValues)
      temp.x = fieldValues.x ? "" : "Patient field is required.";

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    GeneralInputForm(initialFValues, null, true, false, validate, null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };
  useEffect(() => {
    setValues(result);
    setInvoiceId(invoice_id);
  }, [result]);
  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <Form className={classes.form}>
            <h3>CBC Examination</h3>
            <div className={classes.withOutSpaceRow}></div>
            <div className={classes.withOutSpaceRow}>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="WBC"
                  name="WBC"
                  value={values.WBC}
                  required
                  onChange={handleInputChange}
                  {...(errors.WBC && {
                    error: true,
                    helperText: errors.WBC,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.WBC.L + " - " + "H " + data.WBC.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="RBC"
                  name="RBC"
                  value={values.RBC}
                  required
                  onChange={handleInputChange}
                  {...(errors.RBC && {
                    error: true,
                    helperText: errors.RBC,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.RBC.L + " - " + "H " + data.RBC.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="HGB"
                  name="HGB"
                  value={values.HGB}
                  required
                  onChange={handleInputChange}
                  {...(errors.HGB && {
                    error: true,
                    helperText: errors.HGB,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.HGB.L + " - " + "H " + data.HGB.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="HCT"
                  name="HCT"
                  value={values.HCT}
                  required
                  onChange={handleInputChange}
                  {...(errors.HCT && {
                    error: true,
                    helperText: errors.HCT,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.HCT.L + " - " + "H " + data.HCT.H}
                />
              </div>
            </div>
            <div className={classes.withOutSpaceRow}>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="MCV"
                  name="MCV"
                  value={values.MCV}
                  required
                  onChange={handleInputChange}
                  {...(errors.MCV && {
                    error: true,
                    helperText: errors.MCV,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.MCV.L + " - " + "H " + data.MCV.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="MCH"
                  name="MCH"
                  value={values.MCH}
                  required
                  onChange={handleInputChange}
                  {...(errors.MCH && {
                    error: true,
                    helperText: errors.MCH,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.MCH.L + " - " + "H " + data.MCH.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="MCHC"
                  name="MCHC"
                  value={values.MCHC}
                  required
                  onChange={handleInputChange}
                  {...(errors.MCHC && {
                    error: true,
                    helperText: errors.MCHC,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.MCHC.L + " - " + "H " + data.MCHC.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="RDW-CV"
                  name="RDW_CV"
                  value={values.RDW_CV}
                  required
                  onChange={handleInputChange}
                  {...(errors.RDW_CV && {
                    error: true,
                    helperText: errors.RDW_CV,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.RDW_CV.L + " - " + "H " + data.RDW_CV.H}
                />
              </div>
            </div>
            <div className={classes.withOutSpaceRow}>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="RDW_SD"
                  name="RDW_SD"
                  value={values.RDW_SD}
                  required
                  onChange={handleInputChange}
                  {...(errors.RDW_SD && {
                    error: true,
                    helperText: errors.RDW_SD,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.RDW_SD.L + " - " + "H " + data.RDW_SD.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="PLT"
                  name="PLT"
                  value={values.PLT}
                  required
                  onChange={handleInputChange}
                  {...(errors.PLT && {
                    error: true,
                    helperText: errors.PLT,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.PLT.L + " - " + "H " + data.PLT.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="MPV"
                  name="MPV"
                  value={values.MPV}
                  required
                  onChange={handleInputChange}
                  {...(errors.MPV && {
                    error: true,
                    helperText: errors.MPV,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.MPV.L + " - " + "H " + data.MPV.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="PDW"
                  name="PDW"
                  value={values.PDW}
                  required
                  onChange={handleInputChange}
                  {...(errors.PDW && {
                    error: true,
                    helperText: errors.PDW,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.PDW.L + " - " + "H " + data.PDW.H}
                />
              </div>
            </div>
            <div className={classes.withOutSpaceRow}>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="Pct"
                  name="Pct"
                  value={values.Pct}
                  required
                  onChange={handleInputChange}
                  {...(errors.Pct && {
                    error: true,
                    helperText: errors.Pct,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.Pct.L + " - " + "H " + data.Pct.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="P_LCR"
                  name="P_LCR"
                  value={values.P_LCR}
                  required
                  onChange={handleInputChange}
                  {...(errors.P_LCR && {
                    error: true,
                    helperText: errors.P_LCR,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.P_LCR.L + " - " + "H " + data.P_LCR.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="P_LCC"
                  name="P_LCC"
                  value={values.P_LCC}
                  required
                  onChange={handleInputChange}
                  {...(errors.P_LCC && {
                    error: true,
                    helperText: errors.P_LCC,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.P_LCC.L + " - " + "H " + data.P_LCC.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="CRP"
                  name="CRP"
                  value={values.CRP}
                  required
                  onChange={handleInputChange}
                  {...(errors.CRP && {
                    error: true,
                    helperText: errors.CRP,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.CRP.L + " - " + "H " + data.CRP.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="Hs_CRP"
                  name="Hs_CRP"
                  value={values.Hs_CRP}
                  required
                  onChange={handleInputChange}
                  {...(errors.Hs_CRP && {
                    error: true,
                    helperText: errors.Hs_CRP,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.Hs_CRP.L + " - " + "H " + data.Hs_CRP.H}
                />
              </div>
            </div>
            <div className={classes.withOutSpaceRow}>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="NEU%"
                  name="NEUM"
                  value={values.NEUM}
                  required
                  onChange={handleInputChange}
                  {...(errors.NEUM && {
                    error: true,
                    helperText: errors.NEUM,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.NEUM.L + " - " + "H " + data.NEUM.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="Lym%"
                  name="LymM"
                  value={values.LymM}
                  required
                  onChange={handleInputChange}
                  {...(errors.LymM && {
                    error: true,
                    helperText: errors.LymM,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.LymM.L + " - " + "H " + data.LymM.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="MON%"
                  name="MONM"
                  value={values.MONM}
                  required
                  onChange={handleInputChange}
                  {...(errors.MONM && {
                    error: true,
                    helperText: errors.MONM,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.MONM.L + " - " + "H " + data.MONM.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="EOS%"
                  name="EOSM"
                  value={values.EOSM}
                  required
                  onChange={handleInputChange}
                  {...(errors.EOSM && {
                    error: true,
                    helperText: errors.EOSM,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.EOSM.L + " - " + "H " + data.EOSM.H}
                />
              </div>
            </div>
            <div className={classes.withOutSpaceRow}>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="BAS%"
                  name="BASM"
                  value={values.BASM}
                  required
                  onChange={handleInputChange}
                  {...(errors.BASM && {
                    error: true,
                    helperText: errors.BASM,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.BASM.L + " - " + "H " + data.BASM.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="NEU#"
                  name="NEUH"
                  value={values.NEUH}
                  required
                  onChange={handleInputChange}
                  {...(errors.NEUH && {
                    error: true,
                    helperText: errors.NEUH,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.NEUH.L + " - " + "H " + data.NEUH.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="Lym#"
                  name="LymH"
                  value={values.LymH}
                  required
                  onChange={handleInputChange}
                  {...(errors.LymH && {
                    error: true,
                    helperText: errors.LymH,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.LymH.L + " - " + "H " + data.LymH.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="MON#"
                  name="MONH"
                  value={values.MONH}
                  required
                  onChange={handleInputChange}
                  {...(errors.MONH && {
                    error: true,
                    helperText: errors.MONH,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.MONH.L + " - " + "H " + data.MONH.H}
                />
              </div>
            </div>
            <div className={classes.withOutSpaceRow}>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="EOS#"
                  name="EOSH"
                  value={values.EOSH}
                  required
                  onChange={handleInputChange}
                  {...(errors.EOSH && {
                    error: true,
                    helperText: errors.EOSH,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.EOSH.L + " - " + "H " + data.EOSH.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="BAS#"
                  name="BASH"
                  value={values.BASH}
                  required
                  onChange={handleInputChange}
                  {...(errors.BASH && {
                    error: true,
                    helperText: errors.BASH,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.BASH.L + " - " + "H " + data.BASH.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="ALY#"
                  name="ALYH"
                  value={values.ALYH}
                  required
                  onChange={handleInputChange}
                  {...(errors.ALYH && {
                    error: true,
                    helperText: errors.ALYH,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.ALYH.L + " - " + "H " + data.ALYH.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="ALY%"
                  name="ALYM"
                  value={values.ALYM}
                  required
                  onChange={handleInputChange}
                  {...(errors.ALYM && {
                    error: true,
                    helperText: errors.ALYM,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.ALYM.L + " - " + "H " + data.ALYM.H}
                />
              </div>
            </div>
            <div className={classes.withOutSpaceRow}>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="LIC#"
                  name="LICH"
                  value={values.LICH}
                  required
                  onChange={handleInputChange}
                  {...(errors.LICH && {
                    error: true,
                    helperText: errors.LICH,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.LICH.L + " - " + "H " + data.LICH.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="LIC%"
                  name="LICM"
                  value={values.LICM}
                  required
                  onChange={handleInputChange}
                  {...(errors.LICM && {
                    error: true,
                    helperText: errors.LICM,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.LICM.L + " - " + "H " + data.LICM.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="NRBC#"
                  name="NRBCH"
                  value={values.NRBCH}
                  required
                  onChange={handleInputChange}
                  {...(errors.NRBCH && {
                    error: true,
                    helperText: errors.NRBCH,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.NRBCH.L + " - " + "H " + data.NRBCH.H}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ marginInline: 5 }}
                  label="NRBC%"
                  name="NRBCM"
                  value={values.NRBCM}
                  required
                  onChange={handleInputChange}
                  {...(errors.NRBCM && {
                    error: true,
                    helperText: errors.NRBCM,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={ReadOnlyInputProps}
                  label="Normal Value"
                  size="small"
                  value={"L " + data.NRBCM.L + " - " + "H " + data.NRBCM.H}
                />
              </div>
            </div>

            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%" }}
                label="WBC Message"
                name="wbc_message"
                value={values.wbc_message}
                onChange={handleInputChange}
                {...(errors.wbc_message && {
                  error: true,
                  helperText: errors.wbc_message,
                })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%" }}
                label="RBC Message"
                name="rbc_message"
                value={values.rbc_message}
                onChange={handleInputChange}
                {...(errors.rbc_message && {
                  error: true,
                  helperText: errors.rbc_message,
                })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%" }}
                label="PLT Message"
                name="plt_message"
                value={values.plt_message}
                onChange={handleInputChange}
                {...(errors.plt_message && {
                  error: true,
                  helperText: errors.plt_message,
                })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%" }}
                label="CRB Message"
                name="crp_message"
                value={values.crp_message}
                onChange={handleInputChange}
                {...(errors.crp_message && {
                  error: true,
                  helperText: errors.crp_message,
                })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%" }}
                label="Comment"
                name="comment"
                value={values.comment}
                onChange={handleInputChange}
                {...(errors.comment && {
                  error: true,
                  helperText: errors.comment,
                })}
              />
            </div>

            <div className={classes.spaceBetweenRow}>
              <div>
                <Button
                  variant="contained"
                  style={{ marginInline: 20, marginBlockEnd: 10 }}
                  onClick={(event) => {
                    console.log(values);
                    console.log(invoiceId);
                    if (validate(values)) {
                      if (values.id === undefined) {
                        PostCbc5Result(event, values, invoiceId);
                      } else {
                        UpdateCbc5Result(event, values, invoiceId);
                      }
                      loadRefreshData(invoiceId)

                      setOpenSuccessAlert(true);
                    } else {
                      setOpenErrorAlert(true);
                    }
                  }}
                >
                  Save
                </Button>
                <Button
                  style={{ marginBlockEnd: 10 }}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          </Form>
        </Container>
      </Modal>
      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
