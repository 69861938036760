import React, { Fragment, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { DeletePayment, GetCustomerPaymentInfo } from "./PaymentAPI";
import { Button, Modal, Container } from "@material-ui/core";
import { useStyles } from "./ComponentsStyle/ModalStyle";
import BaseTable, { tableIcons } from "../../app/generalComponent/BaseTable";
import { AddPaymentModal } from "./AddPaymentModal";
import AlertDialog from "../../app/generalComponent/DeletingAlert";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";

// import { jsPDF } from "jspdf";

export const PaymentModal = ({ data, open, setOpen }) => {
  const [addOpen, setAddOpen] = useState(false);
  const [customerPaymentInfo, setCustomerPaymentInfo] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [rowId, setRowId] = useState(null);
  const classes = useStyles();
  const theme = useTheme();
  const tableRef = React.createRef();

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };
  const handelConfirmDelete = () => {
    console.log("id " + rowId);
    DeletePayment(rowId);
    setDeleteDialogOpen(false);
  };
  const columns = [
    { title: <FormattedMessage id="payment_date" />, field: "payment_date" },
    {
      title: <FormattedMessage id="payment_currency" />,
      field: "payment_currency",
    },
    {
      title: <FormattedMessage id="payment_currency_exchange" />,
      field: "payment_currency_exchange",
    },
    {
      title: <FormattedMessage id="payment_amount" />,
      field: "payment_amount",
    },
    { title: <FormattedMessage id="back_currency" />, field: "back_currency" },
    {
      title: <FormattedMessage id="back_currency_exchange" />,
      field: "back_currency_exchange",
    },
    { title: <FormattedMessage id="back_amount" />, field: "back_amount" },
  ];
  const loadCustomerPaymentInfo = async (id = data) => {
    if (id !== undefined && id != null) {
      let response = await GetCustomerPaymentInfo(id);
      console.log(response.data);
      setCustomerPaymentInfo(response.data);
    }
  };
  useEffect(() => {
    loadCustomerPaymentInfo(data);
  }, [data]);
  return (
    <Modal open={open}>
      <Container className={classes.container}>
        <div className={classes.buttonContainerStyle}>
          <Button
            style={{ marginInline: 20 }}
            variant="contained"
            onClick={() => {
              loadCustomerPaymentInfo(data);
              setAddOpen(true);
            }}
          >
            <FormattedMessage id="create_new" />
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            <FormattedMessage id="close" />
          </Button>
        </div>
        <AddPaymentModal
          data={data}
          customer_payment_info={customerPaymentInfo}
          open={addOpen}
          setOpen={setAddOpen}
          refresh={loadCustomerPaymentInfo}
          tableRef={tableRef}
        />
        <AlertDialog
          open={deleteDialogOpen}
          handleClose={handleDeleteDialogClose}
          handleConfirm={handelConfirmDelete}
        />
        <div style={{ padding: 20 }}>
          <BaseTable
            refrence={tableRef}
            title={<FormattedMessage id="payment_list" />}
            columns={columns}
            data={(query) =>
              new Promise((resolve, reject) => {
                let url =
                  "http://206.189.137.141:8000/api/customerpayments/customerPayment/?";
                url += "registration_id=" + data;
                url += "&per_page=" + query.pageSize;
                url += "&page=" + (query.page + 1);
                fetch(url)
                  .then((response) => response.json())
                  .then((result) => {
                    resolve({
                      data: result.data,
                      page: result.page - 1,
                      totalCount: result.count,
                    });
                  });
              })
            }
            actions={[
              {
                icon: tableIcons.Refresh,
                tooltip: "Refresh",
                onClick: (event, rowData) => {
                  const scopedRef = tableRef.current;
                  scopedRef.onQueryChange();
                  loadCustomerPaymentInfo(data);
                },
                isFreeAction: true,
              },
              {
                icon: tableIcons.Delete,
                tooltip: "Delete",
                onClick: (event, rowData) => {
                  setDeleteDialogOpen(true);
                  setRowId(rowData.id);
                },
              },
            ]}
            options={{
              maxBodyHeight: "50vh",
              search: false,
              // columnsButton:true,
              headerStyle: {
                // background: theme.palette.primary.main,
                // color: "white",
                fontWeight: "bold",
              },
              rowStyle: (data) =>
                data.tableData.id % 2 === 0
                  ? { background: theme.palette.common.white }
                  : { background: theme.palette.grey[100] },
              // grouping:true
            }}
          />
        </div>
        <div className={classes.withOutSpaceRow}>
          <Button
            variant="contained"
            color="primary"
            className={classes.Button}
          >
            {<FormattedMessage id="total_amount" />}{" "}
            {customerPaymentInfo ? customerPaymentInfo.total_amount : 0}
          </Button>
          <Button
            variant="contained"
            className={classes.Button}
            style={{ backgroundColor: "green", color: "white" }}
          >
            {<FormattedMessage id="total_paid" />}{" "}
            {customerPaymentInfo ? customerPaymentInfo.total_paid : 0}
          </Button>
          <Button
            variant="contained"
            className={classes.Button}
            style={{ backgroundColor: "red", color: "white" }}
          >
            {<FormattedMessage id="total_remain" />}{" "}
            {customerPaymentInfo ? customerPaymentInfo.total_remain : 0}
          </Button>
        </div>
      </Container>
    </Modal>
  );
};

// function CustomExportPdf(columns, data = [], filename = "data") {
//   try {
//     let finalData = data;
//     if (data.length && !Array.isArray(data[0])) {
//       if (typeof data[0] === "object") {
//         finalData = data.map((row) =>
//           columns.map((col) =>
//             col.exportTransformer
//               ? col.exportTransformer(row)
//               : row[col.field]
//           )
//         );
//       }
//     }
//       const content = {
//         startY: 50,
//         head: [columns.map((col) => col.title)],
//         body: finalData,
//       };
//       const unit = "pt";
//       const size = "A4";
//       const orientation = "landscape";
//       const doc = new jsPDF(orientation, unit, size);
//       doc.setFontSize(15);
//       doc.text(filename, 40, 40);
//       doc.autoTable(content);
//       doc.save(filename + Date.now().toString() + ".pdf");
//     // }
//   } catch (err) {
//     console.error(
//       `exporting pdf : ${err}`
//     );
//   }
// }
