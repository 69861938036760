import React, { Fragment, useState } from "react";
import { Fab, Tooltip } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import BaseTable, { tableIcons } from "../../app/generalComponent/BaseTable";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { AddModal } from "./Components/AddModal";
import AddIcon from "@material-ui/icons/Add";
import { useStyles } from "./Components/ComponentsStyle/ModalStyle";
import { ViewModal } from "./Components/ViewModal";
import { DeleteCurrency, GetOfferDetails } from "./OffersAPI";
import { EditModal } from "./Components/EditModal";
import AlertDialog from "../../app/generalComponent/DeletingAlert";
import { useSelector } from "react-redux";
import { selectClientInfo } from "../Authentecation/AuthSlice";
import { FormattedMessage } from "react-intl";

// import { jsPDF } from "jspdf";

function OffersPage() {
  const classes = useStyles();
  const [viewOpen, setViewOpen] = useState(false);
  const [editeOpen, setEditeOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [apiResultValues, setApiResultValues] = useState(null);
  const theme = useTheme();
  const tableRef = React.createRef();
  const client = useSelector(selectClientInfo);

  const LoadDetails = async (id) => {
    if (id !== null) {
      const response = await GetOfferDetails(id);
      const apiResultValues = {
        id: response.id,
        offer_name: response.offer_name,
        description: response.description,
        is_active: response.is_active,
        offeritems: response.offeritems,
      };
      // console.log(response);
      setApiResultValues(apiResultValues);
      console.log(apiResultValues);
    }
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };
  const handelConfirmDelete = () => {
    console.log("id " + rowId);
    DeleteCurrency(rowId);
    setDeleteDialogOpen(false);
  };
  const columns = [
    { title: <FormattedMessage id="offer_name" />, field: "offer_name" },
    { title: <FormattedMessage id="is_active" />, field: "is_active" },
    { title: <FormattedMessage id="description" />, field: "description" },
    {
      title: <FormattedMessage id="created_time" />,
      field: "created_time",
      export: false,
    },
    {
      title: <FormattedMessage id="created_user" />,
      field: "created_user",
      export: false,
    },
    {
      title: <FormattedMessage id="updated_time" />,
      field: "updated_time",
      export: false,
    },
    {
      title: <FormattedMessage id="updated_user" />,
      field: "updated_user",
      export: false,
    },
  ];

  return (
    <Fragment>
      <Tooltip title="Add" aria-label="add" onClick={() => setAddOpen(true)}>
        <Fab color="primary" className={classes.fab}>
          <AddIcon />
        </Fab>
      </Tooltip>
      <EditModal
        data={apiResultValues}
        open={editeOpen}
        setOpen={setEditeOpen}
      />
      <ViewModal data={apiResultValues} open={viewOpen} setOpen={setViewOpen} />
      <AddModal open={addOpen} setOpen={setAddOpen} />
      <AlertDialog
        open={deleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleConfirm={handelConfirmDelete}
      />

      <BaseTable
        refrence={tableRef}
        title={<FormattedMessage id="offer" />}
        columns={columns}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = "http://206.189.137.141:8000/api/offers/offer/?";
            url += "per_page=" + query.pageSize;
            url += "&client=" + client.id;
            url += "&page=" + (query.page + 1);
            if (query.search) {
              url += `&search=${query.search}`;
            }
            if (query.orderBy) {
              if (query.orderDirection === "asc") {
                url += `&orderBy=${query.orderBy.field}`;
              } else {
                url += `&orderBy=-${query.orderBy.field}`;
              }
            }
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data,
                  page: result.page - 1,
                  totalCount: result.count,
                });
              });
          })
        }
        actions={[
          {
            icon: tableIcons.Refresh,
            tooltip: "Refresh",
            onClick: (event, rowData) => {
              const scopedRef = tableRef.current;
              scopedRef.onQueryChange();
            },
            isFreeAction: true,
          },
          {
            icon: tableIcons.View,
            tooltip: "View",
            onClick: (event, rowData) => {
              LoadDetails(rowData.id);
              setViewOpen(true);
            },
          },
          {
            icon: tableIcons.Edit,
            tooltip: "Edit",
            onClick: (event, rowData) => {
              LoadDetails(rowData.id);
              setEditeOpen(true);
            },
          },
          {
            icon: tableIcons.Delete,
            tooltip: "Delete",
            onClick: (event, rowData) => {
              setDeleteDialogOpen(true);
              setRowId(rowData.id);
            },
          },
        ]}
        options={{
          exportMenu: [
            // {
            //   label: "Export PDF",
            //   exportFunc: (cols, datas) => ExportPdf(cols, datas, "Test Data"),
            // },
            {
              label: <FormattedMessage id="Export_CSV" />,
              exportFunc: (cols, datas) =>
                ExportCsv(cols, datas, new Date().toLocaleDateString()),
            },
          ],
          exportButton: true,
          exportAllData: true,
          exportFileName: "TestData",
          pageSizeOptions: [5, 10, 20, 50, 100],
          maxBodyHeight: "55vh",
          paginationType: "stepped",
          paginationPosition: "bottom",
          searchFieldVariant: "outlined",
          searchFieldStyle: {
            fontSize: "small",
            maxHeight: 35,
            paddingInlineEnd: 0,
          },
          // columnsButton:true,
          headerStyle: {
            // background: theme.palette.primary.main,
            // color: "white",
            fontWeight: "bold",
          },
          rowStyle: (data) =>
            data.tableData.id % 2 === 0
              ? { background: theme.palette.common.white }
              : { background: theme.palette.grey[100] },
          // grouping:true
        }}
      />
    </Fragment>
  );
}

export default OffersPage;

// function CustomExportPdf(columns, data = [], filename = "data") {
//   try {
//     let finalData = data;
//     if (data.length && !Array.isArray(data[0])) {
//       if (typeof data[0] === "object") {
//         finalData = data.map((row) =>
//           columns.map((col) =>
//             col.exportTransformer
//               ? col.exportTransformer(row)
//               : row[col.field]
//           )
//         );
//       }
//     }
//       const content = {
//         startY: 50,
//         head: [columns.map((col) => col.title)],
//         body: finalData,
//       };
//       const unit = "pt";
//       const size = "A4";
//       const orientation = "landscape";
//       const doc = new jsPDF(orientation, unit, size);
//       doc.setFontSize(15);
//       doc.text(filename, 40, 40);
//       doc.autoTable(content);
//       doc.save(filename + Date.now().toString() + ".pdf");
//     // }
//   } catch (err) {
//     console.error(
//       `exporting pdf : ${err}`
//     );
//   }
// }
