import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseURL } from "../../app/axiosInstance";
import { storeToLocalStorage } from "../../app/Utility/CacheStore";
import { GetClientInfo } from "../Setting/SettingApi";
import { LoginUser } from "./AuthAPI";

const initialState = {
  loading: false,
  token: null,
  user: null,
  clientInfo: null,
  error: "",
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const loginAndThenGetClientInfo = (user) => async (dispatch) => {
  await dispatch(LoginAsync(user));
};

export const LoginAsync = createAsyncThunk("Auth/LoginAsync", async (user) => {
  const response = await LoginUser(user.username, user.password);
  // The value we return becomes the `fulfilled` action payload
  let result = {};
  if (response.token) {
    result = {
      id: response.client.id,
      branch_name: response.client.branch_name,
      header_image_url: baseURL + response.client.header_image.slice(1),
      footer_image_url: baseURL + response.client.footer_image.slice(1),
    };
    storeToLocalStorage("token", response.token);
    storeToLocalStorage("user", JSON.stringify(response.user));
    storeToLocalStorage("clientinfo", result);
  }
  return { token: response.token, user: response.user, clientInfo: result };
});

export const GetClientInfoAsync = createAsyncThunk(
  "auth/GetClientInfoAsync",
  async (id) => {
    const response = await GetClientInfo(id);
    let result = {
      id: response.id,
      branch_name: response.branch_name,
      header_image_url: baseURL + response.header_image.slice(1),
      footer_image_url: baseURL + response.footer_image.slice(1),
    };
    if (response) {
      storeToLocalStorage("clientinfo", result);
    }
    // The value we return becomes the `fulfilled` action payload
    return result;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    Login: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.clientInfo = action.payload.clientinfo;
      state.loading = false;
    },
    Logout: (state) => {
      state.token = null;
      state.user = null;
      state.clientInfo = null;
      state.loading = false;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //------------------------Login--------------------------//
      .addCase(LoginAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(LoginAsync.fulfilled, (state, action) => {
        state.token = action.payload.token;
        state.user = action.payload.user;
        state.clientInfo = action.payload.clientInfo;
        state.loading = false;
      })
      .addCase(LoginAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = "invalid email or password";
      })
      //------------------------GetUser--------------------------//
      .addCase(GetClientInfoAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetClientInfoAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.clientInfo = action.payload;
      })
      .addCase(GetClientInfoAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { Login, Logout } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUser = (state) => state.auth.user;
export const selectLoading = (state) => state.auth.loading;
export const selectToken = (state) => state.auth.token;
export const selectClientInfo = (state) => state.auth.clientInfo;
export const selectError = (state) => state.auth.error;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default authSlice.reducer;
