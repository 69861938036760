import { getFromLocalStorage } from "../../app/Utility/CacheStore";
import axiosInstance from "../../app/axiosInstance";

export const GetTestAutoComplete = (search = "",client) =>
  axiosInstance.get(
    `/api/testManagement/test_autoComplete/?search=${search}&client=${client}`
  );
export const PostOffers =async (e, postData) => {
  e.preventDefault();
  const clientinfo = await getFromLocalStorage("clientinfo");

  let formData = {
    client: clientinfo.id,
    offer_name:postData.offer_name,
    description:postData.description,
    is_active:postData.is_active,
    offeritems:postData.offeritems
  }
  axiosInstance.post(`api/offers/offer/`, JSON.stringify(formData));
};

export const GetOfferDetails = (id) =>
  axiosInstance.get(`api/offers/offer_detail/${id}/`).then(function (response) {return response.data}) ;

  export const EditOffers = (id,e,postData) => {
    e.preventDefault();
    let formData = {
      offer_name:postData.offer_name,
      description:postData.description,
      is_active:postData.is_active,
      offeritems:postData.offeritems
    }
    axiosInstance.put(`api/offers/offer_detail/${id}/`, JSON.stringify(formData));
  };

  export const DeleteCurrency = (id) =>
  axiosInstance.delete(`api/currencies/currency_detail/${id}/`);