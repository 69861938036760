import { getFromLocalStorage } from "../../app/Utility/CacheStore";
import axiosInstance from "../../app/axiosInstance";

export const GetTestAutoComplete = (search = "", id = 0) =>
  axiosInstance.get(
    `/api/purchasereturn/test_autoComplete/?search=${search}&invoice_id=${id}`
  );

export const GetTestQtyAutoComplete = (test_id = "", id = "") =>
  axiosInstance.get(
    `/api/purchasereturn/test_Qty_autoComplete/?test_id=${test_id}&invoice_id=${id}`
  );

export const GetPurchaseInvoiceAutoComplete = (search = 0,client) =>
  axiosInstance.get(
    `/api/purchasereturn/purchaseInvoice_autoComplete/?vender=${search}&client=${client}`
  );

export const GetCurrenciesAutoComplete = (search = "",client) =>
  axiosInstance.get(`/api/currencies/currencies_details_autoComplete/?client=${client}`);

export const GetPurchaseDetails = (id) =>
  axiosInstance
    .get(`api/purchasereturn/purchase_return_detail/${id}/`)
    .then(function (response) {
      return response.data;
    });

export const PostPurchase =async (e, postData) => {
  e.preventDefault();
  const clientinfo = await getFromLocalStorage("clientinfo");

  let invoice_list = Object.assign([], postData.purchase_return_items);
  let invoice_list_for_Post = [];
  for (var item of invoice_list) {
    invoice_list_for_Post = invoice_list_for_Post.concat({
      test: item.test.test.id,
      price: item.price,
      qty: item.qty,
      total_amount: item.total_amount,
    });
  }
  let total_amount = invoice_list_for_Post.reduce(
    (total, item) =>
      (total = parseFloat(total) + parseFloat(item.total_amount)),
    0
  );
  let formData = {
    client: clientinfo.id,
    description: postData.description,
    vender: postData.vender.id,
    currencey: postData.currencey,
    currencey_exchange: postData.currencey_exchange,
    purchase_invoice: postData.purchase_invoice.id,
    invoice_date: postData.invoice_date,
    total_amount: total_amount,
    purchase_return_items: invoice_list_for_Post,
  };
  axiosInstance.post(
    `api/purchasereturn/purchase_return/`,
    JSON.stringify(formData)
  );
};
export const EditPurchase = (id, e, postData) => {
  e.preventDefault();
  let invoice_list = Object.assign([], postData.purchase_return_items);
  let invoice_list_for_Post = [];
  for (var item of invoice_list) {
    invoice_list_for_Post = invoice_list_for_Post.concat({
      id: item.id,
      test: item.test.id,
      price: item.price,
      qty: item.qty,
      total_amount: item.total_amount,
    });
  }
  let total_amount = invoice_list_for_Post.reduce(
    (total, item) =>
      (total = parseFloat(total) + parseFloat(item.total_amount)),
    0
  );
  let formData = {
    description: postData.description,
    vender: postData.vender.id,
    currencey: postData.currencey,
    currencey_exchange: postData.currencey_exchange,
    purchase_invoice: postData.purchase_invoice.id,
    invoice_date: postData.invoice_date,
    total_amount: total_amount,
    purchase_return_items: invoice_list_for_Post,
  };
  axiosInstance.put(
    `api/purchasereturn/purchase_return_detail/${id}/`,
    JSON.stringify(formData)
  );
};

export const DeletePurchase = (id) =>
  axiosInstance.delete(`api/purchasereturn/purchase_return_detail/${id}/`);
