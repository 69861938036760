import {
  Button,
  Container,
  FormControlLabel,
  MenuItem,
  Modal,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useEffect } from "react";

import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import { useStyles, ReadOnlyInputProps } from "./ComponentsStyle/ModalStyle";
import BaseAutoComplete from "../../../app/generalComponent/BaseAutoComplete";
import { FormattedMessage } from "react-intl";

const initialFValues = {
  english_name: "",
  device_name: "",
  report_title: "",
  sample: "",
  symbol: "",
  units: "",
  department: "",
  department_textInput: "",
  group: "",
  group_textInput: "",
  volume: "",
  priority: "",
  time: "",
  time_type: "",
  is_prepration: false,
  is_active: false,
  more_result: false,
  result_type: "",
  precautons: "",
  defult_result: "",
  defult_comment: "",
};

export const ViewModal = ({ data, open, setOpen }) => {
  const classes = useStyles();
  const { values, setValues } = GeneralInputForm(
    initialFValues,
    null,
    false,
    false,
    null,
    null
  );

  useEffect(() => {
    if (data !== null) {
      setValues(data);
    }
  }, [data, setValues]);

  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
              }}
            >
              <FormattedMessage id="close" />
            </Button>
          </div>
          <Form className={classes.form}>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={ReadOnlyInputProps}
                size="small"
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="test_name" />}
                name="english_name"
                value={values.english_name ? values.english_name : ""}
                // required
                // onChange={handleInputChange}
                // {...(errors.english_name && {
                //   error: true,
                //   helperText: errors.english_name,
                // })}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                size="small"
                InputProps={ReadOnlyInputProps}
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="symbol" />}
                name="symbol"
                value={values.symbol ? values.symbol : ""}
                // onChange={handleInputChange}
                // {...(errors.symbol && {
                //   error: true,
                //   helperText: errors.symbol,
                // })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                size="small"
                InputProps={ReadOnlyInputProps}
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="device_name" />}
                name="device_name"
                value={values.device_name ? values.device_name : ""}
                // onChange={handleInputChange}
                // {...(errors.device_name && {
                //   error: true,
                //   helperText: errors.device_name,
                // })}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                size="small"
                InputProps={ReadOnlyInputProps}
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="units" />}
                name="units"
                value={values.units ? values.units : ""}
                // onChange={handleInputChange}
                // {...(errors.units && { error: true, helperText: errors.units })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <div
                className={classes.withOutSpaceRow}
                style={{ width: "45%", marginInline: 5, marginBlock: 0 }}
              >
                <BaseAutoComplete
                  label={<FormattedMessage id="department" />}
                  name="department"
                  variant="filled"
                  size="small"
                  customStyle={{ width: "90%", marginInlineEnd: 5 }}
                  options={[]}
                  fetchData={() => {}}
                  inputValue={values.department_textInput}
                  onSelectChange={async (newValue) => {
                    //  await handleInputChange(
                    //     convertToDefEventPara("department", newValue?newValue.id:null)
                    //   );
                  }}
                />
                {/* <IconButton
                  aria-label="Department"
                  size="medium"
                  color="primary"
                  onClick={handleDepartmentDialogOpen}
                >
                  <AddIcon fontSize="inherit" />
                </IconButton> */}
              </div>
              <div
                className={classes.withOutSpaceRow}
                style={{ width: "45%", marginInline: 5, marginBlock: 0 }}
              >
                <BaseAutoComplete
                  label={<FormattedMessage id="group" />}
                  name="group"
                  variant="filled"
                  size="small"
                  customStyle={{ width: "90%", marginInlineEnd: 5 }}
                  options={[]}
                  fetchData={() => {}}
                  inputValue={values.group_textInput}
                  onSelectChange={async (newValue) => {
                    //  await handleInputChange(
                    //     convertToDefEventPara("group", newValue?newValue.id:null)
                    //   );
                  }}
                />
                {/* <IconButton
                  aria-label="Department"
                  size="medium"
                  color="primary"
                  onClick={handleGroupDialogOpen}
                >
                  <AddIcon fontSize="inherit" />
                </IconButton> */}
              </div>
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                size="small"
                InputProps={ReadOnlyInputProps}
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="report_title" />}
                name="report_title"
                value={values.report_title ? values.report_title : ""}
                // onChange={handleInputChange}
                // {...(errors.report_title && {
                //   error: true,
                //   helperText: errors.report_title,
                // })}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                size="small"
                InputProps={ReadOnlyInputProps}
                style={{ width: "45%", marginInline: 5 }}
                label={<FormattedMessage id="sample" />}
                name="sample"
                value={values.sample ? values.sample : ""}
                // onChange={handleInputChange}
                // {...(errors.sample && {
                //   error: true,
                //   helperText: errors.sample,
                // })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                size="small"
                InputProps={ReadOnlyInputProps}
                style={{ width: "20%", marginInline: 5 }}
                label={<FormattedMessage id="volume" />}
                name="volume"
                value={values.volume ? values.volume : ""}
                // onChange={handleInputChange}
                // {...(errors.volume && {
                //   error: true,
                //   helperText: errors.volume,
                // })}
              />
              <TextField
                id="outlined-number"
                variant="filled"
                size="small"
                type="number"
                InputProps={ReadOnlyInputProps}
                style={{ width: "20%", marginInline: 5 }}
                label={<FormattedMessage id="priority" />}
                name="priority"
                value={values.priority ? values.priority : ""}
                // onChange={handleInputChange}
                // {...(errors.priority && {
                //   error: true,
                //   helperText: errors.priority,
                // })}
              />
              <TextField
                id="outlined-number"
                variant="filled"
                size="small"
                type="number"
                InputProps={ReadOnlyInputProps}
                style={{ width: "20%", marginInline: 5 }}
                label={<FormattedMessage id="time" />}
                name="time"
                value={values.time ? values.time : ""}
                // onChange={handleInputChange}
                // {...(errors.time && { error: true, helperText: errors.time })}
              />
              <TextField
                id="outlined-select"
                select
                variant="filled"
                size="small"
                InputProps={ReadOnlyInputProps}
                style={{ width: "20%", marginInline: 5 }}
                label={<FormattedMessage id="time_type" />}
                name="time_type"
                value={values.time_type ? values.time_type : ""}
                // onChange={handleInputChange}
                // {...(errors.time_type && {
                //   error: true,
                //   helperText: errors.time_type,
                // })}
                defaultValue={"Hour"}
              >
                <MenuItem value={"Hour"}>
                  <FormattedMessage id="Hour" />
                </MenuItem>
                <MenuItem value={"Day"}>
                  <FormattedMessage id="Day" />
                </MenuItem>
                <MenuItem value={"Week"}>
                  <FormattedMessage id="Week" />
                </MenuItem>
              </TextField>
            </div>
            <div className={classes.withOutSpaceRow}>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      name="is_prepration"
                      checked={values.is_prepration}
                      // onChange={(e) =>
                      //   handleInputChange(
                      //     convertToDefEventPara(
                      //       "is_prepration",
                      //       e.target.checked
                      //     )
                      //   )
                      // }
                      color="primary"
                    />
                  }
                  label={<FormattedMessage id="is_prepration" />}
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      name="is_active"
                      checked={values.is_active}
                      // onChange={(e) =>
                      //   handleInputChange(
                      //     convertToDefEventPara("is_active", e.target.checked)
                      //   )
                      // }
                      color="primary"
                    />
                  }
                  label={<FormattedMessage id="is_active" />}
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      name="more_result"
                      checked={values.more_result}
                      // onChange={(e) =>
                      //   handleInputChange(
                      //     convertToDefEventPara("more_result", e.target.checked)
                      //   )
                      // }
                      color="primary"
                    />
                  }
                  label={<FormattedMessage id="more_result" />}
                />
              </div>
            </div>
            {values.more_result && (
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-select"
                  select
                  variant="filled"
                  size="small"
                  // fullWidth
                  InputProps={ReadOnlyInputProps}
                  style={{ width: "25%", marginInline: 5 }}
                  label={<FormattedMessage id="result_type" />}
                  name="result_type"
                  value={values.result_type ? values.result_type : ""}
                  // onChange={handleInputChange}
                  // {...(errors.result_type && {
                  //   error: true,
                  //   helperText: errors.result_type,
                  // })}
                  defaultValue={"Urine"}
                >
                  <MenuItem value={"Urine"}>Urine</MenuItem>
                  <MenuItem value={"Stool"}>Stool</MenuItem>
                  <MenuItem value={"CBC Diff 3"}>CBC Diff 3</MenuItem>
                  <MenuItem value={"CBC Diff 5"}>CBC Diff 5</MenuItem>
                  <MenuItem value={"Semen"}>Semen</MenuItem>
                </TextField>
              </div>
            )}
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={ReadOnlyInputProps}
                style={{ width: "100%" }}
                label={<FormattedMessage id="precautons" />}
                name="precautons"
                value={values.precautons ? values.precautons : ""}
                // onChange={handleInputChange}
                // {...(errors.precautons && {
                //   error: true,
                //   helperText: errors.precautons,
                // })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={ReadOnlyInputProps}
                style={{ width: "100%" }}
                label={<FormattedMessage id="defult_result" />}
                name="defult_result"
                value={values.defult_result ? values.defult_result : ""}
                // onChange={handleInputChange}
                // {...(errors.defult_result && {
                //   error: true,
                //   helperText: errors.defult_result,
                // })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={ReadOnlyInputProps}
                style={{ width: "100%" }}
                label={<FormattedMessage id="defult_comment" />}
                name="defult_comment"
                value={values.defult_comment ? values.defult_comment : ""}
                // onChange={handleInputChange}
                // {...(errors.defult_comment && {
                //   error: true,
                //   helperText: errors.defult_comment,
                // })}
              />
            </div>
          </Form>
        </Container>
      </Modal>
    </Fragment>
  );
};
