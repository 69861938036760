import {
  Button,
  Container,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Snackbar,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";

import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import MuiAlert from "@material-ui/lab/Alert";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import {
  useStyles,
  InputProps,
  InputlistProps,
  DateInputProps,
} from "./ComponentsStyle/ModalStyle";
import BaseAutoComplete from "../../../app/generalComponent/BaseAutoComplete";
import {
  GetCurrenciesAutoComplete,
  GetTestAutoComplete,
  PostVender,
  UpdateVender,
} from "../VenderApi";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useEffect } from "react";
import { Update } from "@material-ui/icons";
import { FormattedMessage } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFValues = {
  vender_name: "",
  vender_type: "",
  address: "",
  phone_number: "",
  email: "",
};

export const EditModal = ({ data, open, setOpen }) => {
  const classes = useStyles();
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [testOptions, setTestOptions] = React.useState([]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("vender_name" in fieldValues)
      temp.vender_name = fieldValues.vender_name
        ? ""
        : "This field is required.";

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    addNewValues,
    errors,
    setErrors,
    addErrors,
    setAddErrors,
    handleInputChange,
    convertToDefEventPara,
    handleInputListChange,
    handleAddNewInputChange,
    resetForm,
  } = GeneralInputForm(initialFValues, null, true, false, validate, null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };

  useEffect(() => {
    if (data !== null) {
      setValues(data);
    }
  }, [data, setValues]);

  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <Button
              variant="contained"
              style={{ marginInline: 20 }}
              onClick={(event) => {
                console.log(values);
                console.log(addNewValues);
                if (validate(values)) {
                  UpdateVender(event, values);
                  setOpenSuccessAlert(true);
                } else {
                  setOpenErrorAlert(true);
                }
              }}
              startIcon={<SaveIcon />}
            >
              {<FormattedMessage id="create" />}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
                resetForm();
              }}
            >
              {<FormattedMessage id="close" />}
            </Button>
          </div>
          <Form className={classes.form}>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                fullWidth
                InputProps={InputProps}
                size="small"
                style={{ marginInline: 5 }}
                label={<FormattedMessage id="vender_name" />}
                name="vender_name"
                value={values.vender_name}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                required
                onChange={handleInputChange}
                {...(errors.vender_name && {
                  error: true,
                  helperText: errors.vender_name,
                })}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                fullWidth
                InputProps={InputProps}
                size="small"
                style={{ marginInline: 5 }}
                label={<FormattedMessage id="vender_type" />}
                name="vender_type"
                value={values.vender_type}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                onChange={handleInputChange}
                {...(errors.vender_type && {
                  error: true,
                  helperText: errors.vender_type,
                })}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                fullWidth
                InputProps={InputProps}
                size="small"
                style={{ marginInline: 5 }}
                label={<FormattedMessage id="address" />}
                name="address"
                value={values.address}
                onChange={handleInputChange}
                {...(errors.address && {
                  error: true,
                  helperText: errors.address,
                })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                fullWidth
                InputProps={InputProps}
                size="small"
                style={{ marginInline: 5 }}
                label={<FormattedMessage id="phone_number" />}
                name="phone_number"
                value={values.phone_number}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                onChange={handleInputChange}
                {...(errors.phone_number && {
                  error: true,
                  helperText: errors.phone_number,
                })}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                fullWidth
                InputProps={InputProps}
                size="small"
                style={{ marginInline: 5 }}
                label={<FormattedMessage id="email" />}
                name="email"
                value={values.email}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                onChange={handleInputChange}
                {...(errors.email && {
                  error: true,
                  helperText: errors.email,
                })}
              />
            </div>
          </Form>
        </Container>
      </Modal>
      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
