import React, { useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-table/react-table.css";
import "react-report-builder/lib/main.css";
import Dashboard from "./app/generalComponent/Dashboard";
import { ThemeProvider } from "@material-ui/core";
import { Customtheme } from "./app/theme";
import { RTL } from "./app/Utility/RTLSupport";
import { IntlProvider } from "react-intl";
import { getIntl } from "./app/Utility/i18n";
import Cookies from "js-cookie";

function App() {
  const [locale, setLocale] = useState("en");

  React.useEffect(() => {
    const storedLocale = Cookies.get("locale") || "en";
    setLocale(storedLocale);
  }, []);

  return (
    <IntlProvider locale={locale} messages={getIntl(locale).messages}>
      <RTL>
        <ThemeProvider theme={Customtheme}>
          <Dashboard />
        </ThemeProvider>
      </RTL>
    </IntlProvider>
  );
}

export default App;
