import { getFromLocalStorage } from "../../app/Utility/CacheStore";
import axiosInstance from "../../app/axiosInstance";

export const PostPricingType =async (e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  const clientinfo = await getFromLocalStorage("clientinfo");

  formData.append("pricingtype", postData.pricingtype);
  formData.append("client", clientinfo.id);

  axiosInstance.post(`api/pricingtype/pricingtype/`, formData);
};

export const GetPricingTypeDetails = (id) =>
  axiosInstance.get(`/api/pricingtype/pricingtype_detail/${id}/`).then(function (response) {return response.data}) ;

  export const EditPricingType = (id,e,postData) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("pricingtype", postData.pricingtype);
    axiosInstance.put(`api/pricingtype/pricingtype_detail/${id}/`, formData);
  };

  export const DeletePricingType = (id) =>
  axiosInstance.delete(`/api/pricingtype/pricingtype_detail/${id}/`);