import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import clsx from "clsx";

import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PersonIcon from "@material-ui/icons/Person";
import TestPage from "../../features/Test/TestPage";
import { useStyles } from "./generalComponentStyles/Dashboard";
import MainListItems from "./MenuListItems";
import TestDepartmentPage from "../../features/Department/TestDepartmentPage";
import TestGroupPage from "../../features/Group/TestGroupPage";
import CustomerAccountPage from "../../features/CustomerAccount/CustomerAccountPage";
import PricingTypePage from "../../features/PricingType/PricingTypePage";
import PricingListPage from "../../features/PricingList/PricingListPage";
import CurrenciesPage from "../../features/Currencies/CurrenciesPage";
import CurrencyExchangePage from "../../features/CurrencyExchange/CurrencyExchangePage";
import OffersPage from "../../features/Offers/OffersPage";
import CustomerTestResultPage from "../../features/CustomerTestResult/CustomerTestResultPage";
import UsersPage from "../../features/users/UsersPage";
import CustomerRegistrationPage from "../../features/CustomerRegistration/CustomerRegistrationPage";
import AccountsGuidePage from "../../features/AccountsGuide/AccountsGuidePage";
import PurchaseInvoicePage from "../../features/PurchaseInvoice/PurchaseInvoicePage";
import VenderPage from "../../features/Vender/VenderPage";
import JournalEntriesPage from "../../features/JournalEntries/JournalEntriesPage";
import PurchaseReturnsPage from "../../features/PurchaseReturns/PurchaseReturnsPage";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Logout,
  selectClientInfo,
  selectToken,
  selectUser,
} from "../../features/Authentecation/AuthSlice";
import LoginPage from "../../features/Authentecation/LoginPage";
import PrivateRoutes from "../Utility/PrivateRoutes";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  useTheme,
  withStyles,
} from "@material-ui/core";
import { removeFromLocalStorage } from "../Utility/CacheStore";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Settings from "@material-ui/icons/Settings";
import TextFormat from "@material-ui/icons/TextFormat";
import CloseIcon from "@material-ui/icons/Close";
import SettingPage from "../../features/Setting/SettingPage";
import PurchasePricingListPage from "../../features/PurchasePricingList/PurchasePricingListPage";
import CustomerRegistrationReturnPage from "../../features/CustomerRegistrationReturn/CustomerRegistrationReturnPage";
import BranchPage from "../../features/Client/ClientPage";
import { FormattedMessage } from "react-intl";
import Cookies from "js-cookie";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    marginBlock: 5,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © Nehal "}
      <Link color="inherit" href="mailto:saif.itbabylon@gmail.com">
        saif.itbabylon@gmail.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Language = ({ open, handleClose }) => {
  const [storedLocale, setStoredLocale] = React.useState("en");
  React.useEffect(() => {
    const cstoredLocale = Cookies.get("locale") || "en";
    setStoredLocale(cstoredLocale);
  }, []);
  return (
    <Dialog open={open}>
      <DialogTitle>
        <FormattedMessage id="toggleLocale" />
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel id="language-label">
            <FormattedMessage id="toggleLocale" />
          </InputLabel>
          <Select
            labelId="language-label"
            id="language-select"
            value={storedLocale}
            onChange={(e) => {
              setStoredLocale(e.target.value);
            }}
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="ar">عربي</MenuItem>
            {/* Add more languages as needed */}
          </Select>
        </FormControl>
        <Button
          onClick={() => {
            Cookies.set("locale", storedLocale, { expires: 365 });
            window.location.reload();
            handleClose(false);
          }}
          color="primary"
          style={{ marginBlock: 10 }}
        >
          <FormattedMessage id="close" />
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [openLanguage, setOpenLanguage] = React.useState(false);
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const client = useSelector(selectClientInfo);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handelLogout = async () => {
    await handleClose();
    await removeFromLocalStorage("token");
    await dispatch(Logout());
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  React.useEffect(() => {
    const storedLocale = Cookies.get("locale") || "en";
    if (storedLocale === "ar") {
      document.dir = "rtl";
    }
  }, []);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Language open={openLanguage} handleClose={setOpenLanguage} />
      {token && (
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {client ? client.branch_name : ""}
            </Typography>
            <div className={classes.userName}>
              <Typography component="h1" variant="h6" color="inherit" noWrap>
                {user ? user.full_name : ""}
              </Typography>
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                color="inherit"
                onClick={handleClick}
              >
                {/* <Badge badgeContent={4} color="secondary">
            </Badge> */}
                <PersonIcon className="MuiSvgIcon-fontSizeLarge" />
              </IconButton>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <StyledMenuItem onClick={handelLogout}>
                  <ListItemIcon>
                    <ExitToAppIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    navigate("/setting");
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Setting" />
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    handleClose();
                    setOpenLanguage(true);
                  }}
                >
                  <ListItemIcon>
                    <TextFormat fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Language" />
                </StyledMenuItem>
                <StyledMenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <CloseIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Close" />
                </StyledMenuItem>
              </StyledMenu>
            </div>
          </Toolbar>
        </AppBar>
      )}
      {token && (
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              <FormattedMessage id="menu" />
            </Typography>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <MainListItems isMenuOpen={open} />
        </Drawer>
      )}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Routes>
            <Route path="/" element={<PrivateRoutes />}>
              <Route exact path="/testmanagement" element={<TestPage />} />
              <Route
                exact
                path="/testmanagement/department"
                element={<TestDepartmentPage />}
              />
              <Route
                exact
                path="/testmanagement/group"
                element={<TestGroupPage />}
              />
              <Route
                exact
                path="/customerregistration"
                element={<CustomerRegistrationPage />}
              />
              <Route
                exact
                path="/customerregistrationreturns"
                element={<CustomerRegistrationReturnPage />}
              />
              <Route
                exact
                path="/customertestresult"
                element={<CustomerTestResultPage />}
              />
              <Route
                exact
                path="/customeraccount"
                element={<CustomerAccountPage />}
              />
              <Route exact path="/pricingtype" element={<PricingTypePage />} />
              <Route exact path="/pricinglist" element={<PricingListPage />} />
              <Route exact path="/offers" element={<OffersPage />} />
              <Route exact path="/currencies" element={<CurrenciesPage />} />
              <Route
                exact
                path="/currencyexchange"
                element={<CurrencyExchangePage />}
              />
              <Route exact path="/users" element={<UsersPage />} />
              <Route
                exact
                path="/accountsguide"
                element={<AccountsGuidePage />}
              />
              <Route
                exact
                path="/journalentries"
                element={<JournalEntriesPage />}
              />
              <Route
                exact
                path="/purchaseinvoice"
                element={<PurchaseInvoicePage />}
              />
              <Route
                exact
                path="/purchasereturns"
                element={<PurchaseReturnsPage />}
              />
              <Route
                exact
                path="/purchasepricinglist"
                element={<PurchasePricingListPage />}
              />
              <Route exact path="/venders" element={<VenderPage />} />
              <Route exact path="/setting" element={<SettingPage />} />
              <Route exact path="/branch" element={<BranchPage />} />
            </Route>
            <Route element={<LoginPage />} path="/Login" />
          </Routes>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
