import {
  Button,
  Container,
  FormControlLabel,
  Modal,
  Snackbar,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";

import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import MuiAlert from "@material-ui/lab/Alert";
import SaveIcon from "@material-ui/icons/Save";
import { useStyles, InputProps } from "./ComponentsStyle/ModalStyle";
import { UpdateStaff } from "../UsersAPI";
import { Permissions } from "./Permissions";
import BaseAutoComplete from "../../../app/generalComponent/BaseAutoComplete";
import { GetClientAutoComplete } from "../../Authentecation/AuthAPI";
import { FormattedMessage } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFValues = {
  id: 0,
  client: "",
  email: "",
  password: "",
  full_name: "",
  about: "",
  is_staff: true,
  is_active: true,
  is_superuser: false,
  groups: [],
  user_permissions: [],
};
export const EditModal = ({ data, open, setOpen }) => {
  const classes = useStyles();
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [clientOptions, setClientOptions] = React.useState([]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues)
      temp.email = fieldValues.email ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const onCreate = async (event) => {
    console.log(values);
    console.log(errors);
    if (validate(values)) {
      let result = await UpdateStaff(event, values);
      if (result.success) {
        setOpenSuccessAlert(true);
      } else {
        console.log(result);
        let temp = { ...errors };
        let data = result.data;
        for (var item in data) {
          for (let i = 0; i < data[`${item}`].length; i++) {
            temp[`${item}`] = data[`${item}`][i];
            console.log(data[`${item}`][i]);
          }
        }
        setErrors({
          ...temp,
        });
        setOpenErrorAlert(true);
      }
    } else {
      setOpenErrorAlert(true);
    }
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    convertToDefEventPara,
    resetForm,
  } = GeneralInputForm(initialFValues, null, true, false, validate, null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };

  const fetchClientAutoCompleteData = async (search = "") => {
    const response = await GetClientAutoComplete(search);
    const data = await response.data;
    console.log(data);
    setClientOptions(data);
  };

  useEffect(() => {
    if (data !== null) {
      setValues(data);
    }
  }, [data, setValues]);

  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <Button
              variant="contained"
              style={{ marginInline: 20 }}
              onClick={onCreate}
              startIcon={<SaveIcon />}
            >
              {<FormattedMessage id="create" />}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
                resetForm();
              }}
            >
              <FormattedMessage id="close" />
            </Button>
          </div>
          <Form className={classes.form}>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 5 }}
                label={<FormattedMessage id="email" />}
                name="email"
                value={values?.email}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                required
                onChange={handleInputChange}
                {...(errors.email && {
                  error: true,
                  helperText: errors.email,
                })}
              />
              <BaseAutoComplete
                label={<FormattedMessage id="client" />}
                name="client"
                variant="filled"
                size="small"
                customStyle={{ width: "100%" }}
                options={clientOptions}
                optionLable="branch_name"
                fetchData={fetchClientAutoCompleteData}
                inputValue={
                  values?.client?.branch_name ? values?.client?.branch_name : ""
                }
                onSelectChange={async (newValue) => {
                  await handleInputChange(
                    convertToDefEventPara("client", newValue ? newValue : null)
                  );
                }}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 5 }}
                label={<FormattedMessage id="full_name" />}
                name="full_name"
                value={values?.full_name}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                required
                onChange={handleInputChange}
                {...(errors.full_name && {
                  error: true,
                  helperText: errors.full_name,
                })}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 5 }}
                label={<FormattedMessage id="password" />}
                name="password"
                value={values?.password}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                required
                onChange={handleInputChange}
                {...(errors.password && {
                  error: true,
                  helperText: errors.password,
                })}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      name="is_active"
                      checked={values?.is_active}
                      onChange={(e) =>
                        handleInputChange(
                          convertToDefEventPara("is_active", e.target.checked)
                        )
                      }
                      color="primary"
                    />
                  }
                  label={<FormattedMessage id="is_active" />}
                />
              </div>
              <div>
                {/* <FormControlLabel
                  control={
                    <Switch
                      name="is_staff"
                      checked={values.is_staff}
                      onChange={(e) =>
                        handleInputChange(
                          convertToDefEventPara("is_staff", e.target.checked)
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Is Staff"
                /> */}
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      name="is_superuser"
                      checked={values?.is_superuser}
                      onChange={(e) =>
                        handleInputChange(
                          convertToDefEventPara(
                            "is_superuser",
                            e.target.checked
                          )
                        )
                      }
                      color="primary"
                    />
                  }
                  label={<FormattedMessage id="is_superuser" />}
                />
              </div>
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%" }}
                label={<FormattedMessage id="about" />}
                name="about"
                value={values?.about}
                onChange={handleInputChange}
                {...(errors.about && {
                  error: true,
                  helperText: errors.about,
                })}
              />
            </div>
            <h6>{<FormattedMessage id="permissions" />}</h6>
            <div className={classes.withOutSpaceRow}>
              {values?.id !== 0 && (
                <Permissions
                  right={values?.user_permissions}
                  setRight={(value) =>
                    handleInputChange(
                      convertToDefEventPara("user_permissions", value)
                    )
                  }
                />
              )}
            </div>
          </Form>
        </Container>
      </Modal>

      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
