import { getFromLocalStorage } from "../../app/Utility/CacheStore";
import axiosInstance from "../../app/axiosInstance";

export const PostVender=async (e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  const clientinfo = await getFromLocalStorage("clientinfo");

  formData.append("vender_name", postData.vender_name);
  formData.append("vender_type", postData.vender_type);
  formData.append("address", postData.address);
  formData.append("phone_number", postData.phone_number);
  formData.append("email", postData.email);
  formData.append("client", clientinfo.id);

  axiosInstance.post(`api/venders/vender/`, formData);
};

export const UpdateVender= (e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("vender_name", postData.vender_name);
  formData.append("vender_type", postData.vender_type);
  formData.append("address", postData.address);
  formData.append("phone_number", postData.phone_number);
  formData.append("email", postData.email);
  formData.append("payable_account", postData.payable_account);
  axiosInstance.put(`api/venders/vender_detail/${postData.id}/`, formData);
};

export const GetVenderDetails = (id) =>
  axiosInstance.get(`/api/venders/vender_detail/${id}/`).then(function (response) {return response.data}) ;

export const DeleteVender = (id) =>
  axiosInstance.delete(`/api/venders/vender_detail/${id}/`);