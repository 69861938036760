import { Button, Container, Modal, TextField } from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import BaseAutoComplete from "../../../app/generalComponent/BaseAutoComplete";

import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import {
  useStyles,
  ReadOnlyInputProps,
  InputProps,
} from "./ComponentsStyle/ModalStyle";
import { FormattedMessage } from "react-intl";

const initialFValues = {
  currency: "",
  currency_textInput: "",
  currency_exchange: "",
};

export const ViewModal = ({ data, open, setOpen }) => {
  const classes = useStyles();
  const { values, setValues } = GeneralInputForm(
    initialFValues,
    null,
    false,
    false,
    null,
    null
  );

  useEffect(() => {
    if (data !== null) {
      setValues(data);
    }
  }, [data, setValues]);

  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
              }}
            >
              <FormattedMessage id="close" />
            </Button>
          </div>
          <Form className={classes.form}>
            <div className={classes.withOutSpaceRow}>
              <BaseAutoComplete
                label={<FormattedMessage id="currency_name" />}
                name="currency"
                variant="filled"
                size="small"
                customStyle={{ width: "100%" }}
                options={[]}
                fetchData={() => {}}
                inputValue={values.currency_textInput}
                onSelectChange={async (newValue) => {
                  // await handleInputChange(
                  //   convertToDefEventPara(
                  //     "currency",
                  //     newValue ? newValue.id : null
                  //   )
                  // );
                }}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={ReadOnlyInputProps}
                size="small"
                style={{ width: "100%", marginInline: 0 }}
                label={<FormattedMessage id="currency_exchange" />}
                name="currency_exchange"
                value={values.currency_exchange ? values.currency_exchange : ""}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                // required
                // onChange={handleInputChange}
                // {...(errors.currency_exchange && {
                //   error: true,
                //   helperText: errors.currency_exchange,
                // })}
              />
            </div>
          </Form>
        </Container>
      </Modal>
    </Fragment>
  );
};
