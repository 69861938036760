import {
  Button,
  Container,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Snackbar,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";

import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import MuiAlert from "@material-ui/lab/Alert";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import {
  useStyles,
  InputProps,
  InputlistProps,
  DateInputProps,
} from "./ComponentsStyle/ModalStyle";
import BaseAutoComplete from "../../../app/generalComponent/BaseAutoComplete";
import {
  GetCurrenciesAutoComplete,
  GetPricForTest,
  GetTestAutoComplete,
  PostPurchase,
} from "../PurchaseInvoiceApi";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useEffect } from "react";
import { GetVenderAutoComplete } from "../../JournalEntries/JournalEntriesAPI";
import { useSelector } from "react-redux";
import { selectClientInfo } from "../../Authentecation/AuthSlice";
import { FormattedMessage } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFValues = {
  id: "",
  description: "",
  invoice_date: new Date().toLocaleDateString(),
  vender: "",
  currencey: "",
  currencey_exchange: "",
  vender_invoice_number: "",
  purchase_invoice_items: [],
  total_amount: "",
  loading: false,
};
const initialAddNewValues = {
  test: "",
  price: "",
  qty: "",
  total_amount: "",
};
export const AddModal = ({ data, open, setOpen }) => {
  const classes = useStyles();
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [testOptions, setTestOptions] = React.useState([]);
  const [venderOptions, setVenderOptions] = React.useState([]);
  const client = useSelector(selectClientInfo);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("vender" in fieldValues)
      temp.vender = fieldValues.vender ? "" : "This field is required.";
    if ("currencey_exchange" in fieldValues)
      temp.currencey_exchange = fieldValues.currencey_exchange
        ? ""
        : "This field is required.";
    if ("purchase_invoice_items" in fieldValues)
      temp.purchase_invoice_items =
        fieldValues.purchase_invoice_items.length > 0
          ? ""
          : "This field is required.";
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const validateAddNew = (fieldValues = addNewValues) => {
    let temp = { ...addErrors };
    if ("qty" in fieldValues)
      temp.qty = fieldValues.qty ? "" : "This field is required.";
    if ("test" in fieldValues)
      temp.test = fieldValues.test ? "" : "This field is required.";

    setAddErrors({
      ...temp,
    });
    if (fieldValues === addNewValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    addNewValues,
    errors,
    setErrors,
    addErrors,
    setAddErrors,
    handleInputChange,
    convertToDefEventPara,
    handleInputListChange,
    handleAddNewInputChange,
    resetForm,
  } = GeneralInputForm(
    initialFValues,
    initialAddNewValues,
    true,
    true,
    validate,
    validateAddNew
  );

  const fetchTestAutoCompleteData = async (search = "") => {
    const response = await GetTestAutoComplete(search, client.id);
    const test = await response.data;
    console.log(test);
    setTestOptions(test);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };

  const totalCalculation = (list) => {
    let testList = list;
    let total = 0;
    for (var item of testList) {
      total = parseFloat(total.toFixed(3)) + parseFloat(item.total_amount);
    }
    return total;
  };

  const isFound = (item) =>
    values.purchase_invoice_items.some((element) => {
      if (element.test.id === item.id) {
        return true;
      }

      return false;
    });

  const handleAddNewValues = async () => {
    if (validateAddNew(addNewValues)) {
      let response = await GetPricForTest(addNewValues.test.id);
      if (response.data.success & (response.data.data.price != null)) {
        let testPrice = response.data.data.price;
        let newList = Object.assign([], values.purchase_invoice_items);
        let exists = await isFound(addNewValues.test);
        if (!exists) {
          newList = newList.concat({
            total_amount: parseFloat(addNewValues.qty) * parseFloat(testPrice),
            qty: addNewValues.qty,
            price: testPrice,
            test: addNewValues.test,
          });
        } else {
          newList = newList.map((item) => {
            if (item.test.id === addNewValues.test.id) {
              let total_qty =
                parseFloat(addNewValues.qty) + parseFloat(item.qty);
              let newItem = {
                total_amount: total_qty * parseFloat(testPrice),
                qty: total_qty,
                price: testPrice,
                test: addNewValues.test,
              };
              return newItem;
            }
            return item;
          });
        }
        let totalAmount = totalCalculation(newList);
        setValues({
          ...values,
          purchase_invoice_items: newList,
          total_amount: totalAmount,
        });
      }
    }
  };
  const handleDeleteListItem = (index) => {
    let newList = Object.assign([], values.purchase_invoice_items);
    newList.splice(index, 1);
    setValues({
      ...values,
      purchase_invoice_items: newList,
    });
  };

  const currencyChange = async (e) => {
    let currencey_exchange = await currencies.find(
      (item) => item.currency_name === e.target.value
    )?.currency_for_CurrencyExchange_set[0].currency_exchange;
    await setValues({
      ...values,
      currencey: e.target.value,
      currencey_exchange: currencey_exchange,
    });
  };
  const fetchVenderAutoCompleteData = async (search = "") => {
    const response = await GetVenderAutoComplete(search, client.id);
    const venders = await response.data;
    console.log(venders);
    setVenderOptions(venders);
  };
  const loadCurrencies = async (search = "") => {
    const response = await GetCurrenciesAutoComplete(search, client.id);
    const currencies = await response.data;
    console.log(currencies);
    setCurrencies(currencies);
  };

  const handleQtyChange = async (index, e) => {
    let listName = "purchase_invoice_items";
    if (e.target.value.length === 0) {
      return;
    }
    const item = Object.assign({}, values[listName][index]);
    item[e.target.name] = e.target.value;
    item["total_amount"] = e.target.value * item["price"];

    const items = Object.assign([], values[listName]);
    items[index] = item;

    let total = await totalCalculation(items);

    setValues({
      ...values,
      purchase_invoice_items: items,
      total_amount: total,
    });
  };

  useEffect(() => {
    loadCurrencies();
  }, []);

  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <div className={classes.spaceBetweenRow} style={{ marginBlock: 0 }}>
              <div>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "green", color: "white" }}
                >
                  <FormattedMessage id="total_amount" /> {values.total_amount}
                </Button>
              </div>
              <div>
                {!values.id && (
                  <Button
                    variant="contained"
                    style={{ marginInline: 20 }}
                    onClick={async (event) => {
                      try {
                        setValues({ ...values, loading: true });
                        console.log(values);
                        console.log(addNewValues);
                        if (validate(values)) {
                          let result = await PostPurchase(event, values);
                          if (result.data.id) {
                            setValues({
                              ...values,
                              id: result.data.id,
                              loading: false,
                            });
                          }
                          setOpenSuccessAlert(true);
                        } else {
                          setOpenErrorAlert(true);
                          setValues({ ...values, loading: false });
                        }
                      } catch (error) {
                        setOpenErrorAlert(true);
                        setValues({ ...values, loading: false });
                      }
                    }}
                    startIcon={<SaveIcon />}
                  >
                    {values.loading ? (
                      <FormattedMessage id="loading" />
                    ) : (
                      <FormattedMessage id="create" />
                    )}
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setOpen(false);
                    resetForm();
                  }}
                >
                  <FormattedMessage id="close" />
                </Button>
              </div>
            </div>
          </div>
          <Form className={classes.form}>
            <h4>
              <FormattedMessage id="purchase_details" />
            </h4>
            <br />
            <div className={classes.spaceAroundRow}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  variant="dialog"
                  label={<FormattedMessage id="invoice_date" />}
                  inputVariant="filled"
                  InputProps={DateInputProps}
                  format="dd/MM/yyyy"
                  name="invoice_date"
                  value={values.invoice_date}
                  onChange={(e) => {
                    handleInputChange(
                      convertToDefEventPara(
                        "invoice_date",
                        e ? e.toLocaleDateString() : ""
                      )
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
              <BaseAutoComplete
                label={<FormattedMessage id="vender_name" />}
                name="partner"
                variant="filled"
                size="small"
                customStyle={{ width: "42.5%", marginInline: 5 }}
                options={venderOptions}
                optionLable="vender_name"
                fetchData={fetchVenderAutoCompleteData}
                inputValue={values.vender ? values.vender.vender_name : ""}
                onSelectChange={async (newValue) => {
                  await handleInputChange(
                    convertToDefEventPara("vender", newValue ? newValue : null)
                  );
                }}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                fullWidth
                InputProps={InputProps}
                size="small"
                style={{ marginInline: 5 }}
                label={<FormattedMessage id="description" />}
                name="description"
                value={values.description}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                onChange={handleInputChange}
                {...(errors.description && {
                  error: true,
                  helperText: errors.description,
                })}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-select"
                select
                variant="filled"
                size="small"
                required
                InputProps={InputProps}
                style={{ width: "100%", marginInline: 5 }}
                label={<FormattedMessage id="currencey" />}
                name="currencey"
                value={values.currencey}
                onChange={currencyChange}
                {...(errors.currencey && {
                  error: true,
                  helperText: errors.currencey,
                })}
                defaultValue={1}
              >
                {/* <MenuItem value={0}>
                  <em>None</em>
                </MenuItem> */}
                {currencies.map((option) => (
                  <MenuItem key={option.id} value={option.currency_name}>
                    {option.currency_name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-number"
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%", marginInline: 5 }}
                label={<FormattedMessage id="currencey_exchange" />}
                name="currencey_exchange"
                value={values.currencey_exchange}
                {...(errors.currencey_exchange && {
                  error: true,
                  helperText: errors.currencey_exchange,
                })}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                fullWidth
                InputProps={InputProps}
                size="small"
                style={{ marginInline: 5 }}
                label={<FormattedMessage id="vender_invoice_number" />}
                name="vender_invoice_number"
                value={values.vender_invoice_number}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                onChange={handleInputChange}
                {...(errors.vender_invoice_number && {
                  error: true,
                  helperText: errors.vender_invoice_number,
                })}
              />
            </div>
            <h4>
              <FormattedMessage id="purchase_list_details" />
            </h4>
            <br />
            <div className={classes.withOutSpaceRow}>
              <BaseAutoComplete
                label={<FormattedMessage id="test_name" />}
                name="test"
                variant="filled"
                size="small"
                customStyle={{ width: "42.5%", marginInline: 5 }}
                options={testOptions}
                optionLable="english_name"
                fetchData={fetchTestAutoCompleteData}
                inputValue={
                  addNewValues.test ? addNewValues.test.english_name : ""
                }
                onSelectChange={async (newValue) => {
                  await handleAddNewInputChange(
                    convertToDefEventPara("test", newValue ? newValue : null)
                  );
                }}
              />
              {/* <TextField
                type="number"
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "42.5%", marginInline: 5 }}
                label="Price"
                name="price"
                value={addNewValues.price}
                onChange={handleAddNewInputChange}
                required
                {...(addErrors.price && {
                  error: true,
                  helperText: addErrors.price,
                })}
              /> */}
              <TextField
                type="number"
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "42.5%", marginInline: 5 }}
                label={<FormattedMessage id="qty" />}
                name="qty"
                value={addNewValues.qty}
                onChange={handleAddNewInputChange}
                required
                {...(addErrors.qty && {
                  error: true,
                  helperText: addErrors.qty,
                })}
              />
              {/* <TextField
                type="number"
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "42.5%", marginInline: 5 }}
                label="Total"
                name="total_amount"
                value={(
                  parseFloat(addNewValues.qty) * parseFloat(addNewValues.price)
                ).toString()}
                // onChange={handleAddNewInputChange}
                required
                {...(addErrors.total_amount && {
                  error: true,
                  helperText: addErrors.total_amount,
                })}
              /> */}
              <IconButton
                aria-label="Add"
                size="medium"
                color="primary"
                onClick={handleAddNewValues}
              >
                <AddIcon fontSize="inherit" />
              </IconButton>
            </div>
            <div>
              <ul className={classes.ul}>
                {values.purchase_invoice_items.map((item, index) => (
                  <li key={index} className={classes.li}>
                    <div className={classes.withOutSpaceRow}>
                      <TextField
                        id="outlined-basic"
                        variant="filled"
                        InputProps={InputlistProps}
                        size="small"
                        style={{ width: "42.5%", marginInline: 5 }}
                        label={<FormattedMessage id="close" />}
                        name="test"
                        value={item.test.english_name}
                      />
                      <TextField
                        id="outlined-basic"
                        variant="filled"
                        InputProps={InputlistProps}
                        size="small"
                        style={{ width: "42.5%", marginInline: 5 }}
                        label={<FormattedMessage id="close" />}
                        name="price"
                        value={item.price}
                        // onChange={(e) =>
                        //   handleInputListChange(
                        //     e,
                        //     item.id,
                        //     "purchase_invoice_items"
                        //   )
                        // }
                      />
                      <TextField
                        id="outlined-basic"
                        variant="filled"
                        type={"number"}
                        InputProps={InputlistProps}
                        size="small"
                        style={{ width: "42.5%", marginInline: 5 }}
                        label={<FormattedMessage id="close" />}
                        name="qty"
                        value={item.qty}
                        onChange={handleQtyChange.bind(this, index)}
                      />
                      <TextField
                        id="outlined-basic"
                        variant="filled"
                        InputProps={InputlistProps}
                        size="small"
                        style={{ width: "42.5%", marginInline: 5 }}
                        label={<FormattedMessage id="close" />}
                        name="total_amount"
                        value={item.total_amount}
                        // onChange={(e) =>
                        //   handleInputListChange(
                        //     e,
                        //     item.id,
                        //     "purchase_invoice_items"
                        //   )
                        // }
                      />
                      <IconButton
                        aria-label="Delete"
                        size="medium"
                        color="primary"
                        onClick={handleDeleteListItem.bind(this, index)}
                      >
                        <DeleteOutline fontSize="inherit" />
                      </IconButton>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Form>
        </Container>
      </Modal>
      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
