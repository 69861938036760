import { getFromLocalStorage } from "../../app/Utility/CacheStore";
import axiosInstance from "../../app/axiosInstance";

export const GetTestAutoComplete = (search = "", client) =>
  axiosInstance.get(
    `/api/testManagement/test_autoComplete/?search=${search}&client=${client}`
  );

export const GetPricForTesteByPricingType = (pricingtype, id) =>
  axiosInstance.get(
    `/api/pricinglist/get_price_for_test_by_pricingtype/${pricingtype}/${id}/`
  );

export const GetPatientAutoComplete = (search = "", client) =>
  axiosInstance.get(
    `/api/customeraccount/customers_autoComplete/?search=${search}&client=${client}`
  );

export const GetOfferAutoComplete = (search = "", client) =>
  axiosInstance.get(
    `/api/offers/offer_autoComplete/?search=${search}&client=${client}`
  );

export const GetRegistrationDetails = (id) =>
  axiosInstance
    .get(`/api/customerregistration/registration_detail/${id}/`)
    .then(function (response) {
      return response.data;
    });

export const PostRegistration = async (e, postData) => {
  e.preventDefault();
  const clientinfo = await getFromLocalStorage("clientinfo");

  let test_list = Object.assign([], postData.patient_test_list);
  let test_list_for_Post = [];
  for (var item of test_list) {
    test_list_for_Post = test_list_for_Post.concat({
      test: item.test.id,
      item_price: item.item_price,
      result_date: item.result_date,
    });
  }
  let formData = {
    client: clientinfo.id,
    patient: postData.patient.id,
    visit_date: postData.visit_date,
    comment: postData.comment,
    total_amount: postData.total_amount,
    registrationitems: test_list_for_Post,
  };
  return axiosInstance.post(
    `api/customerregistration/registrations/`,
    JSON.stringify(formData)
  );
};

export const EditRegistration = (id, e, postData) => {
  e.preventDefault();
  let test_list = Object.assign([], postData.patient_test_list);
  let test_list_for_Post = [];
  for (var item of test_list) {
    test_list_for_Post = test_list_for_Post.concat({
      test: item.test.id,
      item_price: item.item_price,
      result_date: item.result_date,
    });
  }
  let formData = {
    patient: postData.patient.id,
    visit_date: postData.visit_date,
    comment: postData.comment,
    total_amount: postData.total_amount,
    registrationitems: test_list_for_Post,
  };
  axiosInstance.put(
    `api/customerregistration/registration_detail/${id}/`,
    JSON.stringify(formData)
  );
};

export const DeleteRegistration = (id) =>
  axiosInstance.delete(`/api/customerregistration/registration_detail/${id}/`);
