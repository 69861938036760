import { makeStyles } from "@material-ui/core";

export const InputProps = {
  disableUnderline: true,
  style: {
    borderRadius: 5,
    backgroundColor: "white",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#cccccc",
  },
};
export const useStyles = makeStyles((theme) => ({
  customButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
  },
  fab: {
    position: "fixed",
    bottom: 20,
    right: 20,
    maxwidth: "3%",
    maxheight: "6%",
    minWidth: "2%",
    minHeight: "4%",
    zIndex: 1,
  },
  pageHeaderContainer: {
    marginBlockEnd: 20,
    textAlign: "start",
  },
  root: {
    flexGrow: 1,
  },
  form: {
    width:"100%",
    // display: "flex",
    // flexDirection: "row",
    padding: theme.spacing(3),
    marginInline:theme.spacing(1),
    overflow:"scroll",
    height:"70vh"
  },
  withOutSpaceColumn: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBlock:5
  },
  withOutSpaceRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginBlockEnd:25
  },
  button:{
    marginInlineEnd:10
  },
}));
