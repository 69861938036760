import {
  Button,
  Container,
  Modal,
  Snackbar,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";

import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import MuiAlert from "@material-ui/lab/Alert";
import SaveIcon from "@material-ui/icons/Save";
import { useStyles, InputProps } from "./ComponentsStyle/ModalStyle";
import {
  EditCurrencyExchange,
  GetCurrencyAutoComplete,
} from "../CurrencyExchangeAPI";
import BaseAutoComplete from "../../../app/generalComponent/BaseAutoComplete";
import { selectClientInfo } from "../../Authentecation/AuthSlice";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFValues = {
  id: "",
  currency: "",
  currency_textInput: "",
  currency_exchange: "",
};

export const EditModal = ({ data, open, setOpen }) => {
  const classes = useStyles();
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [currencyOptions, setCurrencyOptions] = React.useState([]);
  const client = useSelector(selectClientInfo);

  const fetchCurrencyAutoCompleteData = async (search = "") => {
    const response = await GetCurrencyAutoComplete(search, client.id);
    const currencies = await response.data;
    console.log(currencies);
    setCurrencyOptions(currencies);
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("currency" in fieldValues)
      temp.currency = fieldValues.currency ? "" : "This field is required.";
    if ("currency_exchange" in fieldValues)
      temp.currency_exchange = fieldValues.currency_exchange
        ? ""
        : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    convertToDefEventPara,
    resetForm,
  } = GeneralInputForm(initialFValues, null, true, false, validate, null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };
  useEffect(() => {
    if (data !== null) {
      setValues(data);
    }
  }, [data, setValues]);

  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <Button
              variant="contained"
              style={{ marginInline: 20 }}
              onClick={(event) => {
                console.log(values);
                if (validate(values)) {
                  EditCurrencyExchange(data.id, event, values);
                  setOpenSuccessAlert(true);
                } else {
                  setOpenErrorAlert(true);
                }
              }}
              startIcon={<SaveIcon />}
            >
              <FormattedMessage id="create" />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
                resetForm();
              }}
            >
              <FormattedMessage id="close" />
            </Button>
          </div>
          <Form className={classes.form}>
            <div className={classes.withOutSpaceRow}>
              <BaseAutoComplete
                label={<FormattedMessage id="currency_name" />}
                name="currency"
                variant="filled"
                size="small"
                customStyle={{ width: "100%" }}
                options={currencyOptions}
                optionLable="currency_name"
                fetchData={fetchCurrencyAutoCompleteData}
                inputValue={values.currency_textInput}
                onSelectChange={async (newValue) => {
                  await handleInputChange(
                    convertToDefEventPara(
                      "currency",
                      newValue ? newValue.id : null
                    )
                  );
                }}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 0 }}
                label={<FormattedMessage id="currency_exchange" />}
                name="currency_exchange"
                value={values.currency_exchange ? values.currency_exchange : ""}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                required
                onChange={handleInputChange}
                {...(errors.currency_exchange && {
                  error: true,
                  helperText: errors.currency_exchange,
                })}
              />
            </div>
          </Form>
        </Container>
      </Modal>
      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
