import { getFromLocalStorage } from "../../app/Utility/CacheStore";
import axiosInstance from "../../app/axiosInstance";

export const GetTestAutoComplete = (search = "", id = 0) =>
  axiosInstance.get(
    `/api/customerregistrationreturns/test_autoComplete/?search=${search}&invoice_id=${id}`
  );

export const GetRegistrationsAutoComplete = (search = "", client) =>
  axiosInstance.get(
    `/api/customerregistrationreturns/customer_registration_autoComplete/?search=${search}&client=${client}`
  );

export const GetCurrenciesAutoComplete = (client) =>
  axiosInstance.get(
    `/api/currencies/currencies_details_autoComplete/?client=${client}`
  );

export const GetRegistrationReturnDetails = (id) =>
  axiosInstance
    .get(
      `api/customerregistrationreturns/customer_registration_return_detail/${id}/`
    )
    .then(function (response) {
      return response.data;
    });

export const PostRegistrationReturn = async (e, postData) => {
  e.preventDefault();
  const clientinfo = await getFromLocalStorage("clientinfo");

  let invoice_list = Object.assign(
    [],
    postData.customer_registration_return_items
  );
  let invoice_list_for_Post = [];
  for (var item of invoice_list) {
    invoice_list_for_Post = invoice_list_for_Post.concat({
      test: item.test.test.id,
      price: item.price,
      // qty: item.qty,
      // total_amount: item.total_amount,
    });
  }
  let total_amount = invoice_list_for_Post.reduce(
    (total, item) => (total = parseFloat(total) + parseFloat(item.price)),
    0
  );
  let formData = {
    client: clientinfo.id,
    description: postData.description,
    currencey: postData.currencey,
    currencey_exchange: postData.currencey_exchange,
    customer_registration: postData.customer_registration.id,
    invoice_date: postData.invoice_date,
    total_amount: total_amount,
    customer_registration_return_items: invoice_list_for_Post,
  };
  return axiosInstance.post(
    `api/customerregistrationreturns/customer_registration_return/`,
    JSON.stringify(formData)
  );
};
export const EditRegistrationReturn = (id, e, postData) => {
  e.preventDefault();
  let invoice_list = Object.assign(
    [],
    postData.customer_registration_return_items
  );
  let invoice_list_for_Post = [];
  for (var item of invoice_list) {
    invoice_list_for_Post = invoice_list_for_Post.concat({
      id: item.id,
      test: item.test.id,
      price: item.price,
      // qty: item.qty,
      // total_amount: item.total_amount,
    });
  }
  let total_amount = invoice_list_for_Post.reduce(
    (total, item) => (total = parseFloat(total) + parseFloat(item.price)),
    0
  );
  let formData = {
    description: postData.description,
    currencey: postData.currencey,
    currencey_exchange: postData.currencey_exchange,
    customer_registration: postData.customer_registration.id,
    invoice_date: postData.invoice_date,
    total_amount: total_amount,
    customer_registration_return_items: invoice_list_for_Post,
  };
  axiosInstance.put(
    `api/customerregistrationreturns/customer_registration_return_detail/${id}/`,
    JSON.stringify(formData)
  );
};

export const DeleteRegistrationReturn = (id) =>
  axiosInstance.delete(
    `api/customerregistrationreturns/customer_registration_return_detail/${id}/`
  );
