import { getFromLocalStorage } from "../../app/Utility/CacheStore";
import axiosInstance from "../../app/axiosInstance";

export const PostCurrencies =async (e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  const clientinfo = await getFromLocalStorage("clientinfo");

  formData.append("currency_name", postData.currency_name);
  formData.append("currency_symbol", postData.currency_symbol);
  formData.append("country", postData.country);
  formData.append("client", clientinfo.id);

  axiosInstance.post(`api/currencies/currencies/`, formData);
};

export const GetCurrencyDetails = (id) =>
  axiosInstance.get(`api/currencies/currency_detail/${id}/`).then(function (response) {return response.data}) ;

  export const EditCurrency = (id,e,postData) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("currency_name", postData.currency_name);
    formData.append("currency_symbol", postData.currency_symbol);
    formData.append("country", postData.country);
    axiosInstance.put(`api/currencies/currency_detail/${id}/`, formData);
  };

  export const DeleteCurrency = (id) =>
  axiosInstance.delete(`api/currencies/currency_detail/${id}/`);