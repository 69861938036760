import {
  Button,
  Container,
  FormHelperText,
  IconButton,
  Modal,
  Snackbar,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useRef, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import MuiAlert from "@material-ui/lab/Alert";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import {
  useStyles,
  InputProps,
  InputlistProps,
  DateInputProps,
  DateInputListProps,
} from "./ComponentsStyle/ModalStyle";
import {
  GetOfferAutoComplete,
  GetPatientAutoComplete,
  GetPricForTesteByPricingType,
  GetTestAutoComplete,
  PostRegistration,
} from "../CustomerRegistrationAPI";
import BaseAutoComplete from "../../../app/generalComponent/BaseAutoComplete";
import { AddModal as CustomerAccountAddModal } from "../../CustomerAccount/Components/AddModal";
import ReactToPrint from "react-to-print";
import Barcode from "react-barcode";
import { useSelector } from "react-redux";
import { selectClientInfo, selectUser } from "../../Authentecation/AuthSlice";
import { FormattedMessage } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFValues = {
  id: "",
  patient: "",
  patient_textInput: "",
  visit_date: new Date().toLocaleDateString(),
  comment: "",
  patient_test_list: [],
  total_amount: 0,
  loading: false,
};
const initialAddNewValues = {
  test: "",
  test_textInput: "",
  offer: "",
  offer_textInput: "",
  item_price: "",
  result_date: new Date().toLocaleDateString(),
};

const ComponentToPrint = React.forwardRef((props, ref) => {
  const { items, patient, id } = props;

  return (
    <div ref={ref}>
      {items?.map((item, index) => {
        return (
          <div>
            <Barcode
              value={id}
              width={4}
              height={80}
              format={"CODE128"}
              displayValue={false}
              fontOptions={""}
              font={"monospace"}
              textAlign={"center"}
              textPosition={"bottom"}
              textMargin={2}
              fontSize={20}
              background={"#ffffff"}
              lineColor={"#000000"}
              // margin={5}
              marginTop={undefined}
              marginBottom={undefined}
              marginLeft={undefined}
              marginRight={undefined}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
                marginInline: 10,
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 12,
                  marginInline: 5,
                }}
              >
                {"Sample:"}
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 12,
                }}
              >
                {item.test.sample} {"/"}
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 12,
                  marginInline: 5,
                }}
              >
                {"Id:"}
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 12,
                  marginInline: 5,
                }}
              >
                {id}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
                marginInline: 10,
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 12,
                  marginInline: 5,
                }}
              >
                {"Patient Name:"}
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 12,
                  marginInline: 5,
                }}
              >
                {patient.patient_name}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
});

const ComponentToPrint2 = React.forwardRef((props, ref) => {
  const { items, patient, totalAmount, id } = props;
  const user = useSelector(selectUser);
  const client = useSelector(selectClientInfo)
  return (
    <div ref={ref}>
      <div style={{ width: "80mm", maxWidth: "80mm" }}>
        <h3 style={{ textAlign: "center", marginBlock: 20 }}>{client?.branch_name}</h3>
        <div className="container">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                {id}
              </p>
            </div>
            <div>
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                :رقم الفاتورة
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <div className="col-md-9">
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                {patient?.patient_name}
              </p>
            </div>
            <div className="col-md-3">
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                :اسم الزبون
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <div className="col-md-9">
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                {user?.full_name}
              </p>
            </div>
            <div className="col-md-3">
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                :الكاشير
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <div className="col-md-9">
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                {new Date().toLocaleDateString()}
              </p>
            </div>
            <div className="col-md-3">
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                :التاريخ
              </p>
            </div>
          </div>
        </div>
        <div
          className="table-responsive"
          style={{ textAlign: "center", marginBlock: 20 }}
        >
          <table className="table">
            <thead>
              <tr>
                <th>الاجمالي</th>
                {/* <th>السعر</th> */}
                {/* <th>الكمية</th> */}
                <th>المادة</th>
              </tr>
            </thead>
            {items?.map((item, index) => {
              return (
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold", fontSize: 12 }}>
                      {item.item_price}
                    </td>
                    {/* <td style={{ fontWeight: "bold", fontSize: 12 }}>1000</td> */}
                    {/* <td style={{ fontWeight: "bold", fontSize: 12 }}>2</td> */}
                    <td style={{ fontWeight: "bold", fontSize: 12 }}>
                      {item.test.english_name}
                    </td>
                  </tr>
                  <tr></tr>
                </tbody>
              );
            })}
          </table>
        </div>
        <div className="container">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                {totalAmount}
              </p>
            </div>
            <div>
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                :المبلغ الصافي
              </p>
            </div>
          </div>
        </div>
        <h6
          style={{
            marginInline: 10,
            textAlign: "right",
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          شركة النهال للانظمة الادارية والمحاسبية
        </h6>
      </div>
    </div>
  );
});

export const AddModal = ({
  open,
  setOpen,
  setPaymentOpen,
  setApiPaymentValues,
}) => {
  const classes = useStyles();
  const [customerAccountDialogOpen, setCustomerAccountDialogOpen] =
    useState(false);
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [testOptions, setTestOptions] = React.useState([]);
  const [patientOptions, setPatientOptions] = React.useState([]);
  const [offerOptions, setOfferOptions] = React.useState([]);
  const [sampleItems, setSampleItems] = React.useState([]);
  const componentRef = useRef();
  const component2Ref = useRef();
  const client = useSelector(selectClientInfo);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("patient" in fieldValues)
      temp.patient = fieldValues.patient ? "" : "Patient field is required.";

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const validateAddNew = (fieldValues = addNewValues) => {
    let temp = { ...addErrors };
    if ("test" in fieldValues)
      temp.test = fieldValues.test
        ? fieldValues.test.stock > 0
          ? ""
          : "Test not has stock."
        : "";

    setAddErrors({
      ...temp,
    });
    if (fieldValues === addNewValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    addNewValues,
    errors,
    setErrors,
    addErrors,
    setAddErrors,
    handleInputChange,
    convertToDefEventPara,
    handleInputListChange,
    handleAddNewInputChange,
    resetForm,
  } = GeneralInputForm(
    initialFValues,
    initialAddNewValues,
    true,
    true,
    validate,
    validateAddNew
  );

  const totalCalculation = (list) => {
    let testList = list;
    let total = 0;
    for (var item of testList) {
      total = parseFloat(total.toFixed(3)) + parseFloat(item.item_price);
    }
    return total;
  };
  const fetchPatientAutoCompleteData = async (search = "") => {
    const response = await GetPatientAutoComplete(search, client.id);
    const patients = await response.data;
    console.log(patients);
    setPatientOptions(patients);
  };
  const fetchOfferAutoCompleteData = async (search = "") => {
    const response = await GetOfferAutoComplete(search, client.id);
    const offers = await response.data;
    console.log(offers);
    setOfferOptions(offers);
  };
  const fetchTestAutoCompleteData = async (search = "") => {
    const response = await GetTestAutoComplete(search, client.id);
    const test = await response.data;
    console.log(test);
    setTestOptions(test);
  };

  const handleCustomerAccountDialogOpen = () => {
    setCustomerAccountDialogOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };

  const isFound = (item) => {
    return values.patient_test_list.some((element) => {
      if (element.test.id === item.id) {
        return true;
      }

      return false;
    });
  };
  const handleAddNewValues = async () => {
    if (validate(values)) {
      if (validateAddNew(addNewValues)) {
        let response = await GetPricForTesteByPricingType(
          values.patient.customer_pricing_type,
          addNewValues.test.id
        );

        if (response.data.success & (response.data.data.price > 0)) {
          let testPrice = response.data.data.price;
          let newList = Object.assign([], values.patient_test_list);
          let exists = await isFound(addNewValues.test);
          if (!exists) {
            newList = newList.concat({
              test: addNewValues.test,
              test_textInput: addNewValues.test.english_name,
              item_price: testPrice,
              result_date: addNewValues.result_date,
            });
          }

          let totalAmount = totalCalculation(newList);
          setValues({
            ...values,
            patient_test_list: newList,
            total_amount: totalAmount,
          });
        } else {
          setAddErrors({ ...addErrors, test: "Item has no price yet." });
        }
      }
    }
  };

  const handleAddNewOfferValues = async () => {
    let offer_items = addNewValues.offer.offeritems;
    let newList = Object.assign([], values.patient_test_list);

    for (var item of offer_items) {
      let exists = await isFound(item.test);
      if (!exists) {
        newList = newList.concat({
          test: item.test,
          test_textInput: item.test.english_name,
          item_price: item.offer_item_price,
          result_date: addNewValues.result_date,
        });
      }
    }

    let totalAmount = totalCalculation(newList);

    setValues({
      ...values,
      patient_test_list: newList,
      total_amount: totalAmount,
    });
  };
  const handlePriceChange = (index, e) => {
    handleInputListChange(e, index, "patient_test_list");
  };
  const handleResultDateChange = (index, e) => {
    debugger;
    handleInputListChange(
      convertToDefEventPara("result_date", e ? e.toLocaleDateString() : ""),
      index,
      "patient_test_list"
    );
  };
  const updateTotals = () => {
    let totalAmount = totalCalculation(
      Object.assign([], values.patient_test_list)
    );
    setValues({
      ...values,
      total_amount: totalAmount,
    });
  };
  const handleDeleteListItem = (index) => {
    let newList = Object.assign([], values.patient_test_list);
    newList.splice(index, 1);
    let totalAmount = totalCalculation(newList);
    setValues({
      ...values,
      patient_test_list: newList,
      total_amount: totalAmount,
    });
  };

  return (
    <Fragment>
      <CustomerAccountAddModal
        open={customerAccountDialogOpen}
        setOpen={setCustomerAccountDialogOpen}
      />
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <div className={classes.spaceBetweenRow} style={{ marginBlock: 0 }}>
              <div>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "green", color: "white" }}
                >
                  <FormattedMessage id="total_amount" />{" "}
                  {values.total_amount.toString()}
                </Button>
              </div>
              <div>
                {!values.id && (
                  <Button
                    variant="contained"
                    style={{ marginInline: 20 }}
                    disabled={values?.loading}
                    onClick={async (event) => {
                      try {
                        setValues({ ...values, loading: true });
                        console.log(values);
                        console.log(addNewValues);
                        if (validate(values)) {
                          let result = await PostRegistration(event, values);
                          if (result.data.id) {
                            setValues({
                              ...values,
                              id: result.data.id,
                              loading: false,
                            });
                          }
                          setOpenSuccessAlert(true);
                        } else {
                          setOpenErrorAlert(true);
                          setValues({ ...values, loading: false });
                        }
                      } catch (error) {
                        setOpenErrorAlert(true);
                        setValues({ ...values, loading: false });
                      }
                    }}
                    startIcon={<SaveIcon />}
                  >
                    {values.loading ? (
                      <FormattedMessage id="loading" />
                    ) : (
                      <FormattedMessage id="create" />
                    )}
                  </Button>
                )}
                {values.id && (
                  <ReactToPrint
                    onBeforeGetContent={async () => {
                      let map = new Map();
                      let temp = [];
                      for (let iterator of values.patient_test_list) {
                        if (!map.has(iterator.test.sample)) {
                          map.set(iterator.test.sample, true);
                          temp.push(iterator);
                        }
                      }
                      await setSampleItems(temp);
                    }}
                    trigger={() => (
                      <Button
                        style={{ marginInlineEnd: 20 }}
                        variant="contained"
                        onClick={() => {}}
                      >
                        <FormattedMessage id="print_barcode" />
                      </Button>
                    )}
                    content={() => componentRef.current}
                    bodyClass={"printElement"}
                  />
                )}
                <div style={{ display: "none" }}>
                  <ComponentToPrint
                    ref={componentRef}
                    items={sampleItems ? sampleItems : ""}
                    patient={values?.patient ? values.patient : ""}
                    id={values.id}
                  />
                </div>
                {values.id && (
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        style={{ marginInlineEnd: 20 }}
                        variant="contained"
                        onClick={() => {}}
                      >
                        <FormattedMessage id="print_recipt" />
                      </Button>
                    )}
                    content={() => component2Ref.current}
                    bodyClass={"printElement"}
                  />
                )}
                <div style={{ display: "none" }}>
                  <ComponentToPrint2
                    ref={component2Ref}
                    items={
                      values.patient_test_list ? values.patient_test_list : ""
                    }
                    totalAmount={values.total_amount}
                    patient={values?.patient ? values.patient : ""}
                    id={values.id}
                  />
                </div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setOpen(false);
                    if (values.id) {
                      setApiPaymentValues(values.id);
                      setPaymentOpen(true);
                    }
                    resetForm();
                  }}
                >
                  <FormattedMessage id="close" />
                </Button>
              </div>
            </div>
          </div>
          <Form className={classes.form}>
            <h4>
              <FormattedMessage id="registration_details" />
            </h4>
            <br />
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "42.5%", marginInline: 5 }}
                label={<FormattedMessage id="company_name" />}
                name="company_name"
                value={client?.branch_name}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  readOnly
                  variant="inline"
                  label={<FormattedMessage id="visit_date" />}
                  inputVariant="filled"
                  InputProps={DateInputProps}
                  format="dd/MM/yyyy"
                  name="visit_date"
                  value={new Date()}
                />
              </MuiPickersUtilsProvider>
              {/* <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "42.5%", marginInline: 5 }}
                label="Patient Id"
                name="Patient_Id"
                value={values.patient ? values.patient.id : ""}
              /> */}
              <div style={{ width: "42.5%", marginInline: 5 }}>
                <BaseAutoComplete
                  label={<FormattedMessage id="patient_name" />}
                  name="patient"
                  variant="filled"
                  size="small"
                  // customStyle={{ width: "42.5%", marginInline: 5 }}
                  options={patientOptions}
                  optionLable="patient_name"
                  fetchData={fetchPatientAutoCompleteData}
                  inputValue={values.patient_textInput}
                  onSelectChange={async (newValue) => {
                    await handleInputChange(
                      convertToDefEventPara(
                        "patient",
                        newValue ? newValue : null
                      )
                    );
                  }}
                />
                {errors.patient && (
                  <div className={classes.withOutSpaceRow}>
                    <FormHelperText error>{errors.patient}</FormHelperText>
                  </div>
                )}
              </div>
              <IconButton
                aria-label="Customer Account"
                size="medium"
                color="primary"
                onClick={handleCustomerAccountDialogOpen}
              >
                <AddIcon fontSize="inherit" />
              </IconButton>
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={InputProps}
                style={{ width: "100%" }}
                label={<FormattedMessage id="comment" />}
                name="comment"
                checked={values.comment}
                onChange={handleInputChange}
                {...(errors.comment && {
                  error: true,
                  helperText: errors.comment,
                })}
              />
            </div>
            <h4>
              <FormattedMessage id="test_list_details" />
            </h4>
            <br />
            <div className={classes.withOutSpaceRow}>
              <div style={{ width: "42.5%", marginInline: 5 }}>
                <BaseAutoComplete
                  label={<FormattedMessage id="test_name" />}
                  name="test"
                  variant="filled"
                  size="small"
                  // customStyle={{ width: "42.5%", marginInline: 5 }}
                  options={testOptions}
                  optionLable="english_name"
                  fetchData={fetchTestAutoCompleteData}
                  inputValue={addNewValues.test_textInput}
                  onSelectChange={async (newValue) => {
                    await handleAddNewInputChange(
                      convertToDefEventPara("test", newValue ? newValue : null)
                    );
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleAddNewValues();
                    }
                  }}
                  onSelect={() => handleAddNewValues()}
                />
                {addErrors.test && (
                  <div className={classes.withOutSpaceRow}>
                    <FormHelperText error>{addErrors.test}</FormHelperText>
                  </div>
                )}
              </div>
              {/* <IconButton
                aria-label="Add"
                size="medium"
                color="primary"
                onClick={handleAddNewValues}
              >
                <AddIcon fontSize="inherit" />
              </IconButton> */}
              <BaseAutoComplete
                label={<FormattedMessage id="offer" />}
                name="offer"
                variant="filled"
                size="small"
                customStyle={{ width: "42.5%", marginInline: 5 }}
                options={offerOptions}
                optionLable="offer_name"
                fetchData={fetchOfferAutoCompleteData}
                inputValue={addNewValues.offer_textInput}
                onSelectChange={async (newValue) => {
                  await handleAddNewInputChange(
                    convertToDefEventPara("offer", newValue ? newValue : null)
                  );
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleAddNewOfferValues();
                  }
                }}
                onSelect={() => handleAddNewOfferValues()}
              />
              {/* <IconButton
                aria-label="Add"
                size="medium"
                color="primary"
                onClick={handleAddNewOfferValues}
              >
                <AddIcon fontSize="inherit" />
              </IconButton> */}
            </div>

            <div>
              <ul className={classes.ul}>
                {values.patient_test_list.map((item, index) => (
                  <li key={index} className={classes.li}>
                    <div className={classes.spaceAroundRowList}>
                      <TextField
                        id="outlined-basic"
                        variant="filled"
                        InputProps={InputlistProps}
                        size="small"
                        style={{ width: "42.5%", marginInline: 5 }}
                        label={<FormattedMessage id="test_name" />}
                        name="test_textInput"
                        value={item.test_textInput}
                      />
                      <TextField
                        id="outlined-basic"
                        variant="filled"
                        type={"number"}
                        InputProps={InputlistProps}
                        size="small"
                        style={{ width: "42.5%", marginInline: 5 }}
                        label={<FormattedMessage id="price" />}
                        name="item_price"
                        value={item.item_price}
                        onChange={handlePriceChange.bind(this, index)}
                        onBlur={(e) => updateTotals()}
                      />
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          // variant="inline"
                          label={<FormattedMessage id="result_date" />}
                          inputVariant="filled"
                          InputProps={DateInputListProps}
                          format="dd/MM/yyyy"
                          name="result_date"
                          value={
                            item.result_date ? item.result_date : new Date()
                          }
                          onChange={handleResultDateChange.bind(this, index)}
                        />
                      </MuiPickersUtilsProvider>
                      <IconButton
                        aria-label="Delete"
                        size="medium"
                        color="primary"
                        onClick={handleDeleteListItem.bind(this, index)}
                      >
                        <DeleteOutline fontSize="inherit" />
                      </IconButton>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Form>
        </Container>
      </Modal>
      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
