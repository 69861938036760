import { getFromLocalStorage } from "../../app/Utility/CacheStore";
import axiosInstance from "../../app/axiosInstance";

export const GetPricingTypeAutoComplete = (search = "", client) =>
  axiosInstance.get(
    `/api/pricingtype/pricingtype_autoComplete/?search=${search}&client=${client}`
  );

export const GetCustomerAccountDetails = (id) =>
  axiosInstance
    .get(`/api/customeraccount/customers_detail/${id}/`)
    .then(function (response) {
      return response.data;
    });

export const PostCustomerAccount = async (e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  const clientinfo = await getFromLocalStorage("clientinfo");

  formData.append(
    "patient_name",
    postData.patient_name ? postData.patient_name : ""
  );
  formData.append("gender", postData.gender ? postData.gender : "");
  formData.append("age", postData.age ? postData.age : "");
  formData.append("age_type", postData.age_type ? postData.age_type : "");
  formData.append(
    "phone_number",
    postData.phone_number ? postData.phone_number : ""
  );
  formData.append("email", postData.email ? postData.email : "");
  formData.append(
    "customer_pricing_type",
    postData.customer_pricing_type ? postData.customer_pricing_type : ""
  );
  formData.append("address", postData.address ? postData.address : "");
  formData.append(
    "medical_info",
    postData.medical_info ? postData.medical_info : ""
  );
  formData.append("client", clientinfo.id);

  return axiosInstance.post(`api/customeraccount/customers/`, formData);
};

export const EditCustomerAccount = (id, e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append(
    "patient_name",
    postData.patient_name ? postData.patient_name : ""
  );
  formData.append("gender", postData.gender ? postData.gender : "");
  formData.append("age", postData.age ? postData.age : "");
  formData.append("age_type", postData.age_type ? postData.age_type : "");
  formData.append(
    "phone_number",
    postData.phone_number ? postData.phone_number : ""
  );
  formData.append("email", postData.email ? postData.email : "");
  formData.append(
    "customer_pricing_type",
    postData.customer_pricing_type ? postData.customer_pricing_type : ""
  );
  formData.append("address", postData.address ? postData.address : "");
  formData.append(
    "medical_info",
    postData.medical_info ? postData.medical_info : ""
  );
  axiosInstance.put(`api/customeraccount/customers_detail/${id}/`, formData);
};

export const DeleteCustomerAccount = (id) =>
  axiosInstance.delete(`/api/customeraccount/customers_detail/${id}/`);
