import {
  Button,
  Container,
  Modal,
  TextField,
  Tab,
  Tabs,
} from "@material-ui/core";
import React, { Fragment, useEffect, useRef, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import MuiAlert from "@material-ui/lab/Alert";
import {
  useStyles,
  InputProps,
  InputlistProps,
  DateInputProps,
  DateInputListProps,
  ReadOnlyInputProps,
  ReadOnlyInputlistProps,
} from "./ComponentsStyle/ModalStyle";
import BaseAutoComplete from "../../../app/generalComponent/BaseAutoComplete";
import { AddModal as CustomerAccountAddModal } from "../../CustomerAccount/Components/AddModal";
import PropTypes from "prop-types";
import ReactToPrint from "react-to-print";
import Barcode from "react-barcode";
import { useSelector } from "react-redux";
import { selectClientInfo, selectUser } from "../../Authentecation/AuthSlice";
import { FormattedMessage } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFValues = {
  id: "",
  patient: "",
  patient_textInput: "",
  visit_date: new Date().toLocaleDateString(),
  comment: "",
  patient_test_list: [],
  total_amount: 0,
};
const initialAddNewValues = {
  test: "",
  test_textInput: "",
  offer: "",
  offer_textInput: "",
  item_price: "",
  result_date: new Date().toLocaleDateString(),
};

const ComponentToPrint = React.forwardRef((props, ref) => {
  const { items, patient, id } = props;

  return (
    <div ref={ref}>
      {items?.map((item, index) => {
        return (
          <div>
            <Barcode
              value={id}
              width={4}
              height={80}
              format={"CODE128"}
              displayValue={false}
              fontOptions={""}
              font={"monospace"}
              textAlign={"center"}
              textPosition={"bottom"}
              textMargin={2}
              fontSize={20}
              background={"#ffffff"}
              lineColor={"#000000"}
              // margin={5}
              marginTop={undefined}
              marginBottom={undefined}
              marginLeft={undefined}
              marginRight={undefined}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
                marginInline: 10,
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 12,
                  marginInline: 5,
                }}
              >
                {"Sample:"}
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 12,
                }}
              >
                {item.test.sample} {"/"}
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 12,
                  marginInline: 5,
                }}
              >
                {"Id:"}
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 12,
                  marginInline: 5,
                }}
              >
                {id}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
                marginInline: 10,
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 12,
                  marginInline: 5,
                }}
              >
                {"Patient Name:"}
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 12,
                  marginInline: 5,
                }}
              >
                {patient.patient_name}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
});
const ComponentToPrint2 = React.forwardRef((props, ref) => {
  const { items, patient, totalAmount, id } = props;
  const user = useSelector(selectUser);
  const client = useSelector(selectClientInfo);
  return (
    <div ref={ref}>
      <div style={{ width: "80mm", maxWidth: "80mm" }}>
        <h3 style={{ textAlign: "center", marginBlock: 20 }}>
          {client?.branch_name}
        </h3>
        <div className="container">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                {id}
              </p>
            </div>
            <div>
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                :رقم الفاتورة
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <div className="col-md-9">
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                {patient?.patient_name}
              </p>
            </div>
            <div className="col-md-3">
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                :اسم الزبون
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <div className="col-md-9">
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                {user?.full_name}
              </p>
            </div>
            <div className="col-md-3">
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                :الكاشير
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <div className="col-md-9">
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                {new Date().toLocaleDateString()}
              </p>
            </div>
            <div className="col-md-3">
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                :التاريخ
              </p>
            </div>
          </div>
        </div>
        <div
          className="table-responsive"
          style={{ textAlign: "center", marginBlock: 20 }}
        >
          <table className="table">
            <thead>
              <tr>
                <th>الاجمالي</th>
                {/* <th>السعر</th> */}
                {/* <th>الكمية</th> */}
                <th>المادة</th>
              </tr>
            </thead>
            {items?.map((item, index) => {
              return (
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold", fontSize: 12 }}>
                      {item.item_price}
                    </td>
                    {/* <td style={{ fontWeight: "bold", fontSize: 12 }}>1000</td> */}
                    {/* <td style={{ fontWeight: "bold", fontSize: 12 }}>2</td> */}
                    <td style={{ fontWeight: "bold", fontSize: 12 }}>
                      {item.test.english_name}
                    </td>
                  </tr>
                  <tr></tr>
                </tbody>
              );
            })}
          </table>
        </div>
        <div className="container">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                {totalAmount}
              </p>
            </div>
            <div>
              <p
                style={{
                  textAlign: "right",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                :المبلغ الصافي
              </p>
            </div>
          </div>
        </div>
        <h6
          style={{
            marginInline: 10,
            textAlign: "right",
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          شركة النهال للانظمة الادارية والمحاسبية
        </h6>
      </div>
    </div>
  );
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ marginBlock: 20 }}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export const ViewModal = ({ data, open, setOpen }) => {
  const classes = useStyles();
  const [customerAccountDialogOpen, setCustomerAccountDialogOpen] =
    useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [sampleItems, setSampleItems] = React.useState([]);
  const componentRef = useRef();
  const component2Ref = useRef();
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("patient" in fieldValues)
      temp.patient = fieldValues.patient ? "" : "Patient field is required.";

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const validateAddNew = (fieldValues = addNewValues) => {
    let temp = { ...addErrors };
    if ("test" in fieldValues)
      temp.test = fieldValues.test ? "" : "Test field is required.";

    setAddErrors({
      ...temp,
    });
    if (fieldValues === addNewValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    addNewValues,
    errors,
    setErrors,
    addErrors,
    setAddErrors,
  } = GeneralInputForm(
    initialFValues,
    initialAddNewValues,
    true,
    true,
    validate,
    validateAddNew
  );
  const handleChangeIndex = (event, newValue) => {
    setTabIndex(newValue);
  };
  useEffect(() => {
    if (data !== null) {
      setValues(data);
    }
  }, [data, setValues]);

  return (
    <Fragment>
      <CustomerAccountAddModal
        open={customerAccountDialogOpen}
        setOpen={setCustomerAccountDialogOpen}
      />
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <div className={classes.spaceBetweenRow} style={{ marginBlock: 0 }}>
              <div>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "green", color: "white" }}
                >
                  <FormattedMessage id="total_amount" />{" "}
                  {values.total_amount.toString()}
                </Button>
              </div>
              <div>
                <ReactToPrint
                  onBeforeGetContent={async () => {
                    let map = new Map();
                    let temp = [];
                    for (let iterator of values.patient_test_list) {
                      if (!map.has(iterator.test.sample)) {
                        map.set(iterator.test.sample, true);
                        temp.push(iterator);
                      }
                    }
                    await setSampleItems(temp);
                  }}
                  trigger={() => (
                    <Button
                      style={{ marginInlineEnd: 20 }}
                      variant="contained"
                      onClick={() => {}}
                    >
                      <FormattedMessage id="print_barcode" />
                    </Button>
                  )}
                  content={() => componentRef.current}
                  bodyClass={"printElement"}
                />
                <div style={{ display: "none" }}>
                  <ComponentToPrint
                    ref={componentRef}
                    items={sampleItems ? sampleItems : ""}
                    patient={values?.patient ? values.patient : ""}
                    id={values.id}
                  />
                </div>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      style={{ marginInlineEnd: 20 }}
                      variant="contained"
                      onClick={() => {}}
                    >
                      <FormattedMessage id="print_recipt" />
                    </Button>
                  )}
                  content={() => component2Ref.current}
                  bodyClass={"printElement"}
                />
                <div style={{ display: "none" }}>
                  <ComponentToPrint2
                    ref={component2Ref}
                    items={
                      values.patient_test_list ? values.patient_test_list : ""
                    }
                    totalAmount={values.total_amount}
                    patient={values?.patient ? values.patient : ""}
                    id={values.id}
                  />
                </div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <FormattedMessage id="close" />
                </Button>
              </div>
            </div>
          </div>
          <Form className={classes.form}>
            <h4>
              <FormattedMessage id="registration_details" />
            </h4>
            <br />
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "42.5%", marginInline: 5 }}
                label={<FormattedMessage id="company_name" />}
                name="company_name"
                value={values?.journal?.client?.branch_name}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  readOnly
                  variant="inline"
                  label={<FormattedMessage id="visit_date" />}
                  inputVariant="filled"
                  InputProps={DateInputProps}
                  format="dd/MM/yyyy"
                  name="visit_date"
                  value={new Date()}
                />
              </MuiPickersUtilsProvider>
              {/* <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={ReadOnlyInputProps}
                size="small"
                style={{ width: "42.5%", marginInline: 5 }}
                label="Patient Id"
                name="Patient_Id"
                value={values.patient ? values.patient.id : ""}
              /> */}
              <BaseAutoComplete
                label={<FormattedMessage id="patient_name" />}
                name="patient"
                variant="filled"
                size="small"
                customStyle={{ width: "42.5%", marginInline: 5 }}
                optionLable="patient_name"
                options={[]}
                fetchData={() => {}}
                inputValue={
                  values.patient_textInput
                    ? values.patient_textInput.toString()
                    : ""
                }
                onSelectChange={async (newValue) => {}}
              />
            </div>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={3}
                variant="filled"
                size="small"
                InputProps={ReadOnlyInputProps}
                style={{ width: "100%" }}
                label={<FormattedMessage id="comment" />}
                name="comment"
                value={values.comment ? values.comment : ""}
              />
            </div>
            <Tabs
              value={tabIndex}
              onChange={handleChangeIndex}
              aria-label="simple tabs example"
              // variant="fullWidth"
              TabIndicatorProps={{ className: classes.indicator }}
              indicatorColor="primary"
              style={{ marginBlock: 20 }}
            >
              <Tab
                label={<FormattedMessage id="test_list_details" />}
                {...a11yProps(0)}
              />
              <Tab
                label={<FormattedMessage id="journal_entry" />}
                {...a11yProps(1)}
              />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <br />
              <div>
                <ul className={classes.ul}>
                  {values.patient_test_list.map((item, index) => (
                    <li key={index} className={classes.li}>
                      <div className={classes.spaceAroundRowList}>
                        <TextField
                          id="outlined-basic"
                          variant="filled"
                          InputProps={InputlistProps}
                          size="small"
                          style={{ width: "42.5%", marginInline: 5 }}
                          label={<FormattedMessage id="test_name" />}
                          name="test"
                          value={item ? item.test.english_name : ""}
                        />
                        <TextField
                          id="outlined-basic"
                          variant="filled"
                          type={"number"}
                          InputProps={InputlistProps}
                          size="small"
                          style={{ width: "42.5%", marginInline: 5 }}
                          label={<FormattedMessage id="price" />}
                          name="item_price"
                          value={item ? item.item_price : ""}
                        />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            // variant="inline"
                            label={<FormattedMessage id="result_date" />}
                            inputVariant="filled"
                            readOnly
                            InputProps={DateInputListProps}
                            format="dd/MM/yyyy"
                            name="result_date"
                            value={
                              item.result_date ? item.result_date : new Date()
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <br />
              <div>
                <ul className={classes.ul}>
                  {values.journal?.journal_entry_items?.map((item, index) => (
                    <li key={index} className={classes.li}>
                      <div className={classes.withOutSpaceRow}>
                        <TextField
                          id="outlined-basic"
                          variant="filled"
                          InputProps={ReadOnlyInputlistProps}
                          size="small"
                          style={{ width: "42.5%", marginInline: 5 }}
                          label={<FormattedMessage id="account" />}
                          name="account"
                          value={item.account ? item.account.account_name : ""}
                        />
                        <TextField
                          id="outlined-basic"
                          variant="filled"
                          InputProps={ReadOnlyInputlistProps}
                          size="small"
                          style={{ marginInline: 5 }}
                          label={<FormattedMessage id="partner" />}
                          name="partner"
                          value={item.partner ? item.partner.vender_name : ""}
                        />
                        <TextField
                          id="outlined-basic"
                          variant="filled"
                          InputProps={ReadOnlyInputlistProps}
                          size="small"
                          style={{ marginInline: 5 }}
                          label={<FormattedMessage id="label" />}
                          name="label"
                          value={item.label ? item.label : ""}
                        />
                        <TextField
                          type="number"
                          id="outlined-basic"
                          variant="filled"
                          InputProps={ReadOnlyInputlistProps}
                          size="small"
                          style={{ marginInline: 5 }}
                          label={<FormattedMessage id="debit" />}
                          name="debit"
                          value={parseFloat(item.debit)}
                        />
                        <TextField
                          type="number"
                          id="outlined-basic"
                          variant="filled"
                          InputProps={ReadOnlyInputlistProps}
                          size="small"
                          style={{ marginInline: 5 }}
                          label={<FormattedMessage id="credit" />}
                          name="credit"
                          value={parseFloat(item.credit)}
                        />
                      </div>
                    </li>
                  ))}
                  <li className={classes.li}>
                    <div
                      className={classes.withOutSpaceRow}
                      style={{ justifyContent: "flex-end" }}
                    >
                      <TextField
                        id="outlined-basic"
                        variant="filled"
                        InputProps={InputlistProps}
                        size="small"
                        style={{ marginInline: 5 }}
                        label={<FormattedMessage id="total_debit" />}
                        name="total_debit"
                        value={values.journal?.journal_entry_items?.reduce(
                          (total, item) =>
                            (total =
                              parseFloat(total) + parseFloat(item.debit)),
                          0
                        )}
                      />
                      <TextField
                        id="outlined-basic"
                        variant="filled"
                        InputProps={InputlistProps}
                        size="small"
                        style={{ marginInline: 5 }}
                        label={<FormattedMessage id="total_credit" />}
                        name="total_credit"
                        value={values.journal?.journal_entry_items?.reduce(
                          (total, item) =>
                            (total =
                              parseFloat(total) + parseFloat(item.credit)),
                          0
                        )}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </TabPanel>
          </Form>
        </Container>
      </Modal>
    </Fragment>
  );
};
