import {
  Button,
  Container,
  Modal,
  Snackbar,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";

import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import MuiAlert from "@material-ui/lab/Alert";
import SaveIcon from "@material-ui/icons/Save";
import { useStyles, InputProps } from "./ComponentsStyle/ModalStyle";
import { EditGroup } from "../TestGroupAPI";
import { FormattedMessage } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFValues = {
  id: "",
  group: "",
};

export const EditModal = ({ data, open, setOpen }) => {
  const classes = useStyles();
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("group" in fieldValues)
      temp.group = fieldValues.group ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    GeneralInputForm(initialFValues, null, true, false, validate, null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };
  useEffect(() => {
    if (data !== null) {
      setValues(data);
    }
  }, [data, setValues]);

  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <Button
              variant="contained"
              style={{ marginInline: 20 }}
              onClick={(event) => {
                console.log(values);
                if (validate(values)) {
                  EditGroup(data.id, event, values);
                  setOpenSuccessAlert(true);
                } else {
                  setOpenErrorAlert(true);
                }
              }}
              startIcon={<SaveIcon />}
            >
              <FormattedMessage id="create" />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
                resetForm();
              }}
            >
              <FormattedMessage id="close" />
            </Button>
          </div>
          <Form className={classes.form}>
            <div className={classes.spaceAroundRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 5 }}
                label={<FormattedMessage id="group" />}
                name="group"
                value={values.group ? values.group : ""}
                required
                onChange={handleInputChange}
                {...(errors.group && {
                  error: true,
                  helperText: errors.group,
                })}
              />
            </div>
          </Form>
        </Container>
      </Modal>
      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
