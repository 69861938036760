import {
  Button,
  Container,
  Modal,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { Fragment, useState } from "react";
import {
  Form,
  GeneralInputForm,
} from "../../../app/generalComponent/GeneralInputForm";
import { InputProps, useStyles } from "./ComponentsStyle/ModalStyle";
import MuiAlert from "@material-ui/lab/Alert";
import { useEffect } from "react";
import { PostSemenResult, UpdateSemenResult } from "../CustomerTestResultAPI";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFValues = {};

export const SemenExam = ({
  open,
  setOpen,
  result,
  invoice_id,
  loadRefreshData,
}) => {
  const classes = useStyles();
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("x" in fieldValues)
      temp.x = fieldValues.x ? "" : "Patient field is required.";

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    GeneralInputForm(initialFValues, null, true, false, validate, null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };

  useEffect(() => {
    setValues(result);
    setInvoiceId(invoice_id);
  }, [result]);

  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <Form className={classes.form}>
            <h3>Semen Examination</h3>
            <div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Duration Of Abstinence"
                  name="duration_of_abstinence"
                  value={values.duration_of_abstinence}
                  required
                  onChange={handleInputChange}
                  {...(errors.duration_of_abstinence && {
                    error: true,
                    helperText: errors.duration_of_abstinence,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Liquefaction"
                  name="liquefaction"
                  value={values.liquefaction}
                  required
                  onChange={handleInputChange}
                  {...(errors.liquefaction && {
                    error: true,
                    helperText: errors.liquefaction,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Interval between start of ejaculation and analysis"
                  name="interval_between_start_of_ejaculation_and_analysis"
                  value={
                    values.interval_between_start_of_ejaculation_and_analysis
                  }
                  required
                  onChange={handleInputChange}
                  {...(errors.interval_between_start_of_ejaculation_and_analysis && {
                    error: true,
                    helperText:
                      errors.interval_between_start_of_ejaculation_and_analysis,
                  })}
                />
              </div>
              <h5>Gross</h5>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Appearance"
                  name="appearance"
                  value={values.appearance}
                  required
                  onChange={handleInputChange}
                  {...(errors.appearance && {
                    error: true,
                    helperText: errors.appearance,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Consistency"
                  name="consistency"
                  value={values.consistency}
                  required
                  onChange={handleInputChange}
                  {...(errors.consistency && {
                    error: true,
                    helperText: errors.consistency,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Volume"
                  name="volume"
                  value={values.volume}
                  required
                  onChange={handleInputChange}
                  {...(errors.volume && {
                    error: true,
                    helperText: errors.volume,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Fructose"
                  name="fructose"
                  value={values.fructose}
                  required
                  onChange={handleInputChange}
                  {...(errors.fructose && {
                    error: true,
                    helperText: errors.fructose,
                  })}
                />
              </div>
            </div>
            <div>
              <h5>Sperm Montility (%) (100 Spermatozoa)</h5>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Rapid Progression"
                  name="rapid_progression"
                  value={values.rapid_progression}
                  required
                  onChange={handleInputChange}
                  {...(errors.rapid_progression && {
                    error: true,
                    helperText: errors.rapid_progression,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Slow Progression"
                  name="slow_progression"
                  value={values.slow_progression}
                  required
                  onChange={handleInputChange}
                  {...(errors.slow_progression && {
                    error: true,
                    helperText: errors.slow_progression,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Non-Progressive Motility"
                  name="non_progressive_motility"
                  value={values.non_progressive_motility}
                  required
                  onChange={handleInputChange}
                  {...(errors.non_progressive_motility && {
                    error: true,
                    helperText: errors.non_progressive_motility,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Immotile"
                  name="immotile"
                  value={values.immotile}
                  required
                  onChange={handleInputChange}
                  {...(errors.immotile && {
                    error: true,
                    helperText: errors.immotile,
                  })}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Agglutination"
                  name="agglutination"
                  value={values.agglutination}
                  required
                  onChange={handleInputChange}
                  {...(errors.agglutination && {
                    error: true,
                    helperText: errors.agglutination,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Vitality"
                  name="vitality"
                  value={values.vitality}
                  required
                  onChange={handleInputChange}
                  {...(errors.vitality && {
                    error: true,
                    helperText: errors.vitality,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Count / ml"
                  name="count_ml"
                  value={values.count_ml}
                  required
                  onChange={handleInputChange}
                  {...(errors.count_ml && {
                    error: true,
                    helperText: errors.count_ml,
                  })}
                />
              </div>
            </div>
            <div>
              <h5>Morphology (%)</h5>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Normal"
                  name="normal"
                  value={values.normal}
                  required
                  onChange={handleInputChange}
                  {...(errors.normal && {
                    error: true,
                    helperText: errors.normal,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Abnormal"
                  name="abnormal"
                  value={values.abnormal}
                  required
                  onChange={handleInputChange}
                  {...(errors.abnormal && {
                    error: true,
                    helperText: errors.abnormal,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Head Defects"
                  name="head_defects"
                  value={values.head_defects}
                  required
                  onChange={handleInputChange}
                  {...(errors.head_defects && {
                    error: true,
                    helperText: errors.head_defects,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Tail Defects"
                  name="tail_defects"
                  value={values.tail_defects}
                  required
                  onChange={handleInputChange}
                  {...(errors.tail_defects && {
                    error: true,
                    helperText: errors.tail_defects,
                  })}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label={`Neck & Mid Piece Defects`}
                  name="neck_mid_piece_defects"
                  value={values.neck_mid_piece_defects}
                  required
                  onChange={handleInputChange}
                  {...(errors.neck_mid_piece_defects && {
                    error: true,
                    helperText: errors.neck_mid_piece_defects,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Cytoplasmic Droplets"
                  name="cytoplasmic_droplets"
                  value={values.cytoplasmic_droplets}
                  required
                  onChange={handleInputChange}
                  {...(errors.cytoplasmic_droplets && {
                    error: true,
                    helperText: errors.cytoplasmic_droplets,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Headless (Pinhead)"
                  name="headless"
                  value={values.headless}
                  required
                  onChange={handleInputChange}
                  {...(errors.headless && {
                    error: true,
                    helperText: errors.headless,
                  })}
                />
              </div>
              <div className={classes.withOutSpaceRow}>
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Pus Cells"
                  name="pus_cells"
                  value={values.pus_cells}
                  required
                  onChange={handleInputChange}
                  {...(errors.pus_cells && {
                    error: true,
                    helperText: errors.pus_cells,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Red Blood Cells"
                  name="red_blood_cells"
                  value={values.red_blood_cells}
                  required
                  onChange={handleInputChange}
                  {...(errors.red_blood_cells && {
                    error: true,
                    helperText: errors.red_blood_cells,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Epithelial Cells"
                  name="epithelial_cells"
                  value={values.epithelial_cells}
                  required
                  onChange={handleInputChange}
                  {...(errors.epithelial_cells && {
                    error: true,
                    helperText: errors.epithelial_cells,
                  })}
                />
                <TextField
                  id="outlined-basic"
                  variant="filled"
                  InputProps={InputProps}
                  size="small"
                  style={{ width: "33%", marginInline: 5 }}
                  label="Miscellaneous"
                  name="miscellaneous"
                  value={values.miscellaneous}
                  required
                  onChange={handleInputChange}
                  {...(errors.miscellaneous && {
                    error: true,
                    helperText: errors.miscellaneous,
                  })}
                />
              </div>
            </div>
            <div className={classes.spaceBetweenRow}>
              <div>
                <Button
                  variant="contained"
                  style={{ marginInline: 20, marginBlockEnd: 10 }}
                  onClick={(event) => {
                    console.log(values);
                    console.log(invoiceId);
                    if (validate(values)) {
                      if (values.id === undefined) {
                        PostSemenResult(event, values, invoiceId);
                      } else {
                        UpdateSemenResult(event, values, invoiceId);
                      }
                      loadRefreshData(invoiceId)
                      setOpenSuccessAlert(true);
                    } else {
                      setOpenErrorAlert(true);
                    }
                  }}
                >
                  Save
                </Button>
                <Button
                  style={{ marginBlockEnd: 10 }}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setOpen(false);
                    resetForm();
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          </Form>
        </Container>
      </Modal>
      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
