import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faCircleDot,
  faDatabase,
  faImagePortrait,
  faMoneyBill1Wave,
  faCartShopping,
  faGear,
  faCashRegister,
} from "@fortawesome/free-solid-svg-icons";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import { IconButton } from "@material-ui/core";
import { useStyles } from "./generalComponentStyles/MenuListItems";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/Authentecation/AuthSlice";
import { FormattedMessage } from "react-intl";

function SubMenuListItems({ mainRoute, routes, isMenuOpen }) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const handleSubMenuToggle = () => {
    setOpen(!open);
  };

  return (
    <div>
      <div className="ListItemWithSubRouteContainer">
        <ListItem button onClick={handleSubMenuToggle}>
          <ListItemIcon>{mainRoute.icon}</ListItemIcon>
          <ListItemText primary={<FormattedMessage id={mainRoute.name} />} />
          <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
        </ListItem>
      </div>
      <List
        className={clsx(
          classes.subRouteListHidden,
          isMenuOpen && open && classes.subRouteList
        )}
      >
        {routes.map((subRoute, index) => {
          return (
            typeof subRoute === "object" && (
              <ListItem
                key={"sublist" + index}
                button
                component={Link}
                to={subRoute.path}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText
                  primary={<FormattedMessage id={subRoute.name} />}
                />
              </ListItem>
            )
          );
        })}
      </List>
    </div>
  );
}

function MainListItems({ isMenuOpen }) {
  const user = useSelector(selectUser);

  const routes = [
    // {
    //   path: "/",
    //   name: "Dashboard",
    //   icon: <FontAwesomeIcon size="xl" icon={faGauge} />,
    // },
    {
      name: "customer_managements",
      icon: <FontAwesomeIcon size="xl" icon={faImagePortrait} />,
      subRoutes: [
        (user?.user_permissions?.filter(
          (x) => x.content_type?.app_label === "CustomerTestResult"
        ).length > 0 ||
          user?.is_superuser) && {
          path: "/customertestresult",
          name: "customer_test_result",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
        (user?.user_permissions?.filter(
          (x) => x.codename === "view_customeraccount"
        ).length > 0 ||
          user?.is_superuser) && {
          path: "/customeraccount",
          name: "customer_account",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
      ],
    },
    {
      name: "master_data",
      icon: <FontAwesomeIcon size="xl" icon={faDatabase} />,
      subRoutes: [
        (user?.user_permissions?.filter((x) => x.codename === "view_test")
          .length > 0 ||
          user?.is_superuser) && {
          path: "/testmanagement",
          name: "test_management",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
        (user?.user_permissions?.filter((x) => x.codename === "view_group")
          .length > 0 ||
          user?.is_superuser) && {
          path: "/testmanagement/group",
          name: "group_management",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
        (user?.user_permissions?.filter((x) => x.codename === "view_department")
          .length > 0 ||
          user?.is_superuser) && {
          path: "/testmanagement/department",
          name: "department_management",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
        (user?.user_permissions?.filter((x) => x.codename === "view_currency")
          .length > 0 ||
          user?.is_superuser) && {
          path: "/currencies",
          name: "currencies",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
        (user?.user_permissions?.filter(
          (x) => x.codename === "view_currencyexchange"
        ).length > 0 ||
          user?.is_superuser) && {
          path: "/currencyexchange",
          name: "currency_exchanges",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
      ],
    },
    {
      name: "sales_management",
      icon: <FontAwesomeIcon size="xl" icon={faCashRegister} />,
      subRoutes: [
        (user?.user_permissions?.filter(
          (x) => x.codename === "view_customerregistration"
        ).length > 0 ||
          user?.is_superuser) && {
          path: "/customerregistration",
          name: "customer_registration",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
        (user?.user_permissions?.filter(
          (x) => x.codename === "view_customerregistrationreturns"
        ).length > 0 ||
          user?.is_superuser) && {
          path: "/customerregistrationreturns",
          name: "customer_registration_return",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
        (user?.user_permissions?.filter(
          (x) => x.codename === "view_pricingtype"
        ).length > 0 ||
          user?.is_superuser) && {
          path: "/pricingtype",
          name: "pricing_type",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
        (user?.user_permissions?.filter(
          (x) => x.codename === "view_pricinglist"
        ).length > 0 ||
          user?.is_superuser) && {
          path: "/pricinglist",
          name: "pricing_list",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
        (user?.user_permissions?.filter((x) => x.codename === "view_offer")
          .length > 0 ||
          user?.is_superuser) && {
          path: "/offers",
          name: "offer",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
      ],
    },
    {
      name: "purchases_managements",
      icon: <FontAwesomeIcon size="xl" icon={faCartShopping} />,
      subRoutes: [
        (user?.user_permissions?.filter(
          (x) => x.codename === "view_purchaseinvoice"
        ).length > 0 ||
          user?.is_superuser) && {
          path: "/purchaseinvoice",
          name: "purchase_invoice",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
        (user?.user_permissions?.filter(
          (x) => x.codename === "view_purchasereturns"
        ).length > 0 ||
          user?.is_superuser) && {
          path: "/purchasereturns",
          name: "purchase_return",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
        (user?.user_permissions?.filter(
          (x) => x.codename === "view_purchasepricinglist"
        ).length > 0 ||
          user?.is_superuser) && {
          path: "/purchasepricinglist",
          name: "purchase_pricing_list",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
        (user?.user_permissions?.filter((x) => x.codename === "view_vender")
          .length > 0 ||
          user?.is_superuser) && {
          path: "/venders",
          name: "venders",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
      ],
    },
    {
      name: "financial_managements",
      icon: <FontAwesomeIcon size="xl" icon={faMoneyBill1Wave} />,
      subRoutes: [
        (user?.user_permissions?.filter(
          (x) => x.app_label?.app_label === "AccountsGuide"
        ).length > 0 ||
          user?.is_superuser) && {
          path: "/accountsguide",
          name: "accounting_chart",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
        (user?.user_permissions?.filter(
          (x) => x.codename === "view_journalentry"
        ).length > 0 ||
          user?.is_superuser) && {
          path: "/journalentries",
          name: "journal_entries",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
      ],
    },
    {
      name: "app_managements",
      icon: <FontAwesomeIcon size="xl" icon={faGear} />,
      subRoutes: [
        (user?.user_permissions?.filter((x) => x.codename === "view_users")
          .length > 0 ||
          user?.is_superuser) && {
          path: "/users",
          name: "users",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
        (user?.user_permissions?.filter((x) => x.codename === "view_client")
          .length > 0 ||
          user?.is_superuser) && {
          path: "/branch",
          name: "branch",
          icon: <FontAwesomeIcon size="xl" icon={faCircleDot} />,
        },
      ],
    },
  ];

  return (
    <List>
      {routes.map((route, index) => {
        if (route.subRoutes) {
          return (
            <SubMenuListItems
              key={"mainList" + index}
              mainRoute={route}
              routes={route.subRoutes}
              isMenuOpen={isMenuOpen}
            />
          );
        }
        return (
          <ListItem
            key={"mainlist" + index}
            button
            component={Link}
            to={route.path}
          >
            <ListItemIcon>{route.icon}</ListItemIcon>
            <ListItemText primary={route.name} />
          </ListItem>
        );
      })}
    </List>
  );
}
export default MainListItems;
