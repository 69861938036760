import React, { useState } from "react";
// import { useTheme } from "@material-ui/core/styles";
import AlertDialog from "../../app/generalComponent/DeletingAlert";
import { InputProps, useStyles } from "./Style/ListPageStyle";
// import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import SvgIcon from "@material-ui/core/SvgIcon";
import { alpha, withStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Collapse from "@material-ui/core/Collapse";
import { useSpring, animated } from "@react-spring/web"; // web.cjs is required for IE 11 support
import {
  DeleteSubAccount,
  GetAccounts,
  PostSubAccount,
  UpdateSubAccount,
} from "./AccountsGuideAPI";
import { Button, Paper, Snackbar, TextField } from "@material-ui/core";
import { GeneralInputForm } from "../../app/generalComponent/GeneralInputForm";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import BaseAutoComplete from "../../app/generalComponent/BaseAutoComplete";
import MuiAlert from "@material-ui/lab/Alert";
import { FormattedMessage } from "react-intl";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  content: {
    "& .MuiTreeItem-label": {
      textAlign: "left",
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}))((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
));

const renderTree = (tree, selected, setSelected, index) => {
  return (
    <StyledTreeItem
      onClick={() => {
        setSelected(tree);
        console.log(tree);
      }}
      key={tree.id.toString() + tree.account_name.toString()}
      nodeId={tree.id.toString() + tree.account_name.toString()}
      label={tree.account_number + " - " + tree.account_name}
    >
      {/* {tree.subCategories && tree.subCategories.map(renderTree)} */}
      {tree.hasOwnProperty("sub_account") &&
        tree.sub_account?.map((item, index) => {
          return renderTree(item, selected, setSelected, index);
        })}
    </StyledTreeItem>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const AccountsGuidePage = () => {
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [apiResultValues, setApiResultValues] = useState(null);
  const [mainAccountOptions, setMainAccountOptions] = useState([]);

  const classes = useStyles();

  const LoadData = async () => {
    const response = await GetAccounts();
    const apiResultValues = response.data;
    console.log(response.data);
    setApiResultValues(apiResultValues);
    console.log(apiResultValues);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };
  const handelConfirmDelete = () => {
    console.log("id " + values.id);
    DeleteSubAccount(values.id);
    LoadData();
    resetForm();
    setDeleteDialogOpen(false);
  };
  const initialFValues = {
    is_new: true,
    id: "",
    main_account: "",
    main_account_textInput: "",
    account_name: "",
    account_number: "",
    account_type: "",
    description: "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("account_name" in fieldValues)
      temp.account_name = fieldValues.account_name
        ? ""
        : "This field is required.";
    if ("main_account" in fieldValues)
      temp.main_account = fieldValues.main_account
        ? ""
        : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    convertToDefEventPara,
    resetForm,
  } = GeneralInputForm(initialFValues, null, true, false, validate, null);

  const fetchMainAutoCompleteData = async (search = "") => {
    const response = await GetAccounts();
    const accounts = await response.data;
    console.log(accounts);
    setMainAccountOptions(accounts);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };

  React.useEffect(() => {
    LoadData();
  }, []);

  return (
    <>
      <div className={classes.withOutSpaceRow}>
        <Paper className={classes.form}>
          <div className={classes.withOutSpaceRow}></div>
          <div className={classes.withOutSpaceRow}>
            <TreeView
              // className={classes.root}
              // defaultExpanded={["1"]}
              defaultCollapseIcon={<MinusSquare />}
              defaultExpandIcon={<PlusSquare />}
              defaultEndIcon={<CloseSquare />}
            >
              {apiResultValues?.map((item, index) => {
                return renderTree(
                  item,
                  values.account,
                  (value) => {
                    setValues({
                      ...values,
                      id: value.id,
                      main_account: value.hasOwnProperty("main_account")
                        ? value.main_account.id
                        : null,
                      main_account_textInput: value.hasOwnProperty(
                        "main_account"
                      )
                        ? value.main_account.account_name
                        : "",
                      account_name: value.account_name,
                      account_number: value.account_number,
                      account_type: value.account_type,
                      description: value.description,
                      is_new: false,
                    });
                  },
                  index
                );
              })}
            </TreeView>
          </div>
        </Paper>
        <Paper className={classes.form}>
          <div className={classes.withOutSpaceRow}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={(event) => {
                resetForm();
              }}
              startIcon={<AddIcon />}
            >
              {<FormattedMessage id="create_new" />}
            </Button>
          </div>
          <div className={classes.withOutSpaceRow}>
            <TextField
              id="outlined-basic"
              variant="filled"
              InputProps={InputProps}
              size="small"
              style={{ marginInline: 5, width: "100%" }}
              label={<FormattedMessage id="account_name" />}
              name="account_name"
              value={values.account_name ? values.account_name : ""}
              required
              onChange={handleInputChange}
              {...(errors.account_name && {
                error: true,
                helperText: errors.account_name,
              })}
            />
            <BaseAutoComplete
              label={<FormattedMessage id="main_account" />}
              name="main_account"
              variant="filled"
              size="small"
              fullWidth
              customStyle={{ width: "100%", marginInlineEnd: 5 }}
              options={mainAccountOptions}
              optionLable={"account_name"}
              fetchData={fetchMainAutoCompleteData}
              inputValue={values.main_account_textInput}
              onSelectChange={async (newValue) => {
                await handleInputChange(
                  convertToDefEventPara(
                    "main_account",
                    newValue ? newValue.id : null
                  )
                );
              }}
            />
          </div>
          <div className={classes.withOutSpaceRow}>
            <TextField
              id="outlined-basic"
              variant="filled"
              InputProps={InputProps}
              size="small"
              style={{ marginInline: 5, width: "100%" }}
              label={<FormattedMessage id="account_number" />}
              name="account_number"
              value={values.account_number ? values.account_number : ""}
              required
              onChange={handleInputChange}
              {...(errors.account_number && {
                error: true,
                helperText: errors.account_number,
              })}
            />
            <TextField
              id="outlined-basic"
              variant="filled"
              InputProps={InputProps}
              size="small"
              style={{ marginInline: 5, width: "100%" }}
              label={<FormattedMessage id="account_type" />}
              name="account_type"
              value={values.account_type ? values.account_type : ""}
              required
              onChange={handleInputChange}
              {...(errors.account_type && {
                error: true,
                helperText: errors.account_type,
              })}
            />
          </div>
          <div className={classes.withOutSpaceRow}>
            <TextField
              id="outlined-basic"
              variant="filled"
              InputProps={InputProps}
              size="small"
              style={{ marginInline: 5, width: "100%" }}
              label={<FormattedMessage id="description" />}
              name="description"
              value={values.description ? values.description : ""}
              required
              onChange={handleInputChange}
              {...(errors.description && {
                error: true,
                helperText: errors.description,
              })}
            />
          </div>
          {(values.main_account || values.is_new) && (
            <div className={classes.withOutSpaceRow}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={(event) => {
                  console.log(values);
                  if (validate(values)) {
                    if (values.is_new) {
                      PostSubAccount(event, values);
                      setOpenSuccessAlert(true);
                      LoadData();
                    } else {
                      UpdateSubAccount(event, values);
                      setOpenSuccessAlert(true);
                      LoadData();
                    }
                  } else {
                    setOpenErrorAlert(true);
                  }
                }}
                startIcon={<SaveIcon />}
              >
                {<FormattedMessage id="create" />}
              </Button>
              <Button
                variant="contained"
                className={classes.button}
                color="secondary"
                onClick={() => {
                  setDeleteDialogOpen(true);
                }}
              >
                {<FormattedMessage id="delete" />}
              </Button>
            </div>
          )}
        </Paper>
      </div>
      <AlertDialog
        open={deleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleConfirm={handelConfirmDelete}
      />
      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </>
  );
};

export default AccountsGuidePage;

// function CustomExportPdf(columns, data = [], filename = "data") {
//   try {
//     let finalData = data;
//     if (data.length && !Array.isArray(data[0])) {
//       if (typeof data[0] === "object") {
//         finalData = data.map((row) =>
//           columns.map((col) =>
//             col.exportTransformer
//               ? col.exportTransformer(row)
//               : row[col.field]
//           )
//         );
//       }
//     }
//       const content = {
//         startY: 50,
//         head: [columns.map((col) => col.title)],
//         body: finalData,
//       };
//       const unit = "pt";
//       const size = "A4";
//       const orientation = "landscape";
//       const doc = new jsPDF(orientation, unit, size);
//       doc.setFontSize(15);
//       doc.text(filename, 40, 40);
//       doc.autoTable(content);
//       doc.save(filename + Date.now().toString() + ".pdf");
//     // }
//   } catch (err) {
//     console.error(
//       `exporting pdf : ${err}`
//     );
//   }
// }
