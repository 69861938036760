import {
  Button,
  Container,
  FormControlLabel,
  IconButton,
  Modal,
  Snackbar,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";

import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import MuiAlert from "@material-ui/lab/Alert";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import {
  useStyles,
  InputProps,
  InputlistProps,
  DateInputProps,
  ReadOnlyInputlistProps,
} from "./ComponentsStyle/ModalStyle";
import {
  GetAccountAutoComplete,
  GetVenderAutoComplete,
  PostJournalEntry,
} from "../JournalEntriesAPI";
import BaseAutoComplete from "../../../app/generalComponent/BaseAutoComplete";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFValues = {
  journal_date: new Date().toLocaleDateString(),
  reference: "",
  journal: "",
  total_amount: "",
  is_posted: false,
  journal_entry_items: [],
  total_debit: 0,
  total_credit: 0,
};
const initialAddNewValues = {
  account: "",
  account_textInput: "",
  partner: "",
  partner_textInput: "",
  label: "",
  analytic_tags: "",
  debit: "",
  credit: "",
};
export const ViewModal = ({ data, open, setOpen }) => {
  const classes = useStyles();
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [accountsOptions, setAccountsOptions] = React.useState([]);
  const [venderOptions, setVenderOptions] = React.useState([]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const validateAddNew = (fieldValues = addNewValues) => {
    let temp = { ...addErrors };
    setAddErrors({
      ...temp,
    });
    if (fieldValues === addNewValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    setValues,
    addNewValues,
    setAddNewValues,
    errors,
    setErrors,
    addErrors,
    setAddErrors,
    handleInputChange,
    convertToDefEventPara,
    handleInputListChange,
    handleAddNewInputChange,
    resetForm,
  } = GeneralInputForm(
    initialFValues,
    initialAddNewValues,
    true,
    true,
    validate,
    validateAddNew
  );

  const fetchAccountsAutoCompleteData = async (search = "") => {
    const response = await GetAccountAutoComplete(search);
    const accounts = await response.data;
    console.log(accounts);
    setAccountsOptions(accounts);
  };
  const fetchVenderAutoCompleteData = async (search = "") => {
    const response = await GetVenderAutoComplete(search);
    const venders = await response.data;
    console.log(venders);
    setVenderOptions(venders);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };

  const total_debit_Calculation = (list) => {
    let testList = list;
    let total = 0;
    for (var item of testList) {
      total = parseFloat(total.toFixed(3)) + parseFloat(item.debit);
    }
    return total;
  };
  const total_credit_Calculation = (list) => {
    let testList = list;
    let total = 0;
    for (var item of testList) {
      total = parseFloat(total.toFixed(3)) + parseFloat(item.credit);
    }
    return total;
  };

  useEffect(() => {
    if (data !== null) {
      setValues(data);
    }
  }, [data, setValues]);

  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
                resetForm();
              }}
            >
              {<FormattedMessage id="close" />}
            </Button>
          </div>
          <Form className={classes.form}>
            <h4>{<FormattedMessage id="journal_details" />}</h4>
            <br />
            <div className={classes.spaceAroundRow}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  variant="dialog"
                  label={<FormattedMessage id="journal_date" />}
                  inputVariant="filled"
                  InputProps={DateInputProps}
                  format="dd/MM/yyyy"
                  name="journal_date"
                  value={values.journal_date}
                />
              </MuiPickersUtilsProvider>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                fullWidth
                style={{ marginInline: 5 }}
                label={<FormattedMessage id="reference" />}
                name="reference"
                value={values.reference ? values.reference : ""}
                // InputLabelProps={{ shrink: values.department ? true : false }}
              />
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                fullWidth
                style={{ marginInline: 5 }}
                label={<FormattedMessage id="journal" />}
                name="journal"
                value={values.journal ? values.journal : ""}
                // InputLabelProps={{ shrink: values.department ? true : false }}
              />
              <div style={{ width: "75%", marginInline: 5 }}>
                <FormControlLabel
                  control={
                    <Switch
                      // fullWidth
                      name="is_posted"
                      checked={values.is_posted}
                      color="primary"
                    />
                  }
                  label={<FormattedMessage id="is_posted" />}
                />
                {/* {errors.is_prepration && (
                  <FormHelperText error>{errors.is_prepration}</FormHelperText>
                )} */}
              </div>
            </div>
            <h4>{<FormattedMessage id="journal_items" />}</h4>
            <br />
            <div>
              <ul className={classes.ul}>
                {values.journal_entry_items?.map((item, index) => (
                  <li key={index} className={classes.li}>
                    <div className={classes.withOutSpaceRow}>
                      <TextField
                        id="outlined-basic"
                        variant="filled"
                        InputProps={ReadOnlyInputlistProps}
                        size="small"
                        style={{ width: "42.5%", marginInline: 5 }}
                        label={<FormattedMessage id="account" />}
                        name="account"
                        value={item.account ? item.account.account_name : ""}
                      />
                      <TextField
                        id="outlined-basic"
                        variant="filled"
                        InputProps={ReadOnlyInputlistProps}
                        size="small"
                        style={{ marginInline: 5 }}
                        label={<FormattedMessage id="partner" />}
                        name="partner"
                        value={item.partner ? item.partner.vender_name : ""}
                      />
                      <TextField
                        id="outlined-basic"
                        variant="filled"
                        InputProps={ReadOnlyInputlistProps}
                        size="small"
                        style={{ marginInline: 5 }}
                        label={<FormattedMessage id="label" />}
                        name="label"
                        value={item.label ? item.label : ""}
                      />
                      <TextField
                        type="number"
                        id="outlined-basic"
                        variant="filled"
                        InputProps={ReadOnlyInputlistProps}
                        size="small"
                        style={{ marginInline: 5 }}
                        label={<FormattedMessage id="debit" />}
                        name="debit"
                        value={parseFloat(item.debit)}
                        // onBlur={async (e) => {
                        //   let total = await total_debit_Calculation(
                        //     values.journal_entry_items
                        //   );
                        //   await setValues({
                        //     ...values,
                        //     total_debit: total,
                        //   });
                        // }}
                        // onChange={(e) =>
                        //   handleInputListChange(e, index, "journal_entry_items")
                        // }
                      />
                      <TextField
                        type="number"
                        id="outlined-basic"
                        variant="filled"
                        InputProps={ReadOnlyInputlistProps}
                        size="small"
                        style={{ marginInline: 5 }}
                        label={<FormattedMessage id="credit" />}
                        name="credit"
                        value={parseFloat(item.credit)}
                        // onBlur={async (e) => {
                        //   let total = await total_credit_Calculation(
                        //     values.journal_entry_items
                        //   );
                        //   await setValues({
                        //     ...values,
                        //     total_credit: total,
                        //   });
                        // }}
                        // onChange={(e) =>
                        //   handleInputListChange(e, index, "journal_entry_items")
                        // }
                      />
                    </div>
                  </li>
                ))}
                <li className={classes.li}>
                  <div
                    className={classes.withOutSpaceRow}
                    style={{ justifyContent: "flex-end" }}
                  >
                    <TextField
                      id="outlined-basic"
                      variant="filled"
                      InputProps={InputlistProps}
                      size="small"
                      style={{ marginInline: 5 }}
                      label={<FormattedMessage id="total_debit" />}
                      name="total_debit"
                      value={values.total_debit}
                    />
                    <TextField
                      id="outlined-basic"
                      variant="filled"
                      InputProps={InputlistProps}
                      size="small"
                      style={{ marginInline: 5 }}
                      label={<FormattedMessage id="total_credit" />}
                      name="total_credit"
                      value={values.total_credit}
                    />
                  </div>
                </li>
              </ul>
            </div>
          </Form>
        </Container>
      </Modal>
      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
