import { makeStyles, withStyles } from "@material-ui/core";

export const InputProps = {
  disableUnderline: true,
  style: {
    borderRadius: 5,
    // backgroundColor: "white",
    // borderWidth: 1,
    // borderStyle: "solid",
    // borderColor: "#cccccc",
  },
};
export const InputlistProps = {
  disableUnderline: true,
  style: {
    borderRadius: 0,
    backgroundColor: "white",
    // borderWidth: 1,
    // borderStyle: "solid",
    // borderColor: "#cccccc",
  },
};
export const ReadOnlyInputProps = {
  disableUnderline: true,
  readOnly: true,
  style: {
    borderRadius: 5,
    backgroundColor: "white",
    // borderWidth: 1,
    // borderStyle: "solid",
    // borderColor: "#cccccc",
  },
};

export const DateInputProps = {
  disableUnderline: true,
  readOnly: true,
  style: {
    borderRadius: 5,
    maxHeight: 50,
    // backgroundColor: "white",
    // borderWidth: 1,
    // borderStyle: "solid",
    // borderColor: "#cccccc",
  },
};
export const DateInputListProps = {
  disableUnderline: true,
  readOnly: true,
  style: {
    borderRadius: 5,
    maxHeight: 50,
    backgroundColor: "white",
    // backgroundColor: "white",
    // borderWidth: 1,
    // borderStyle: "solid",
    // borderColor: "#cccccc",
  },
};
export const GlobalCss = withStyles({
  "@global": {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    ".MuiPagination-ul": {
      justifyContent: "center",
    },
  },
})(() => null);

export const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: 20,
    right: 20,
    zIndex: 1,
  },
  refreshFab: {
    position: "fixed",
    bottom: 20,
    right: 90,
    zIndex: 1,
  },
  container: {
    width: "100%",
    height: "90%",
    overflowY: "scroll",
    backgroundColor: "white",
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      height: "100vh",
    },
  },
  form: {
    padding: theme.spacing(3),
    marginInline: theme.spacing(1),
    height: "80vh",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.5em",
      height: "0.5em",
      // backgroundColor: theme.palette.grey[300],
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: 5,
    },
  },
  spaceAroundRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: theme.spacing(3),
  },
  spaceBetweenRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
  },
  spaceAroundRowList: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  withOutSpaceRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(3),
    marginInline: theme.spacing(1),
  },
  ul: {
    listStyle: "none",
    paddingInline: 0,
  },
  li: {
    padding: 5,
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#cccccc",
    marginBottom: theme.spacing(1),
  },
  ButtonContainer: {
    display: "flex",
    width: "100%",
  },
}));
