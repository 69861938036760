import axiosInstance from "../../app/axiosInstance";

export const GetAccounts = () => {
  return axiosInstance
    .get(`/api/accountguide/account/`)
    .then(function (response) {
      return response.data;
    });
};

export const PostSubAccount= (e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("main_account", postData.main_account);
  formData.append("account_name", postData.account_name);
  formData.append("account_number", postData.account_number);
  formData.append("account_type", postData.account_type);
  formData.append("description", postData.description);
  axiosInstance.post(`api/accountguide/subaccount/`, formData);
};

export const UpdateSubAccount= (e, postData) => {
  e.preventDefault();
  let formData = new FormData();
  formData.append("main_account", postData.main_account);
  formData.append("account_name", postData.account_name);
  formData.append("account_number", postData.account_number);
  formData.append("account_type", postData.account_type);
  formData.append("description", postData.description);
  axiosInstance.put(`api/accountguide/subaccount_detail/${postData.id}/`, formData);
};

export const DeleteSubAccount = (id) =>
axiosInstance.delete(`api/accountguide/subaccount_detail/${id}/`);