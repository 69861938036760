import {
  Button,
  Container,
  IconButton,
  Modal,
  Snackbar,
  TextField,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";

import {
  GeneralInputForm,
  Form,
} from "../../../app/generalComponent/GeneralInputForm";
import { InputProps, useStyles } from "./ComponentsStyle/ModalStyle";
import {
  GetPricingTypeAutoComplete,
  GetTestAutoComplete,
  PostPricingList,
} from "../PricingListAPI";
import BaseAutoComplete from "../../../app/generalComponent/BaseAutoComplete";
import { useSelector } from "react-redux";
import { selectClientInfo } from "../../Authentecation/AuthSlice";
import { FormattedMessage } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const initialFValues = {
  test: "",
  test_textInput: "",
  pricingtype: "",
  pricingtype_textInput: "",
  price: "",
};

export const AddModal = ({ open, setOpen }) => {
  const classes = useStyles();
  const [OpenSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [testOptions, setTestOptions] = React.useState([]);
  const [pricingtypeOptions, setPricingtypeOptions] = React.useState([]);
  const client = useSelector(selectClientInfo);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("price" in fieldValues)
      temp.price = fieldValues.price ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const fetchTestAutoCompleteData = async (search = "") => {
    const response = await GetTestAutoComplete(search, client.id);
    const test = await response.data;
    console.log(test);
    setTestOptions(test);
  };
  const fetchPricingTypeAutoCompleteData = async (search = "") => {
    const response = await GetPricingTypeAutoComplete(search, client.id);
    const pricingtype = await response.data;
    console.log(pricingtype);
    setPricingtypeOptions(pricingtype);
  };
  const {
    values,
    // setValues,
    convertToDefEventPara,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = GeneralInputForm(initialFValues, null, true, false, validate, null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorAlert(false);
    setOpenSuccessAlert(false);
  };
  return (
    <Fragment>
      <Modal open={open}>
        <Container className={classes.container}>
          <div className={classes.buttonContainerStyle}>
            <Button
              variant="contained"
              style={{ marginInline: 20 }}
              onClick={(event) => {
                console.log(values);
                if (validate(values)) {
                  PostPricingList(event, values);
                  setOpenSuccessAlert(true);
                } else {
                  setOpenErrorAlert(true);
                }
              }}
              startIcon={<SaveIcon />}
            >
              <FormattedMessage id="create" />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpen(false);
                resetForm();
              }}
            >
              <FormattedMessage id="close" />
            </Button>
          </div>
          <Form className={classes.form}>
            <div className={classes.withOutSpaceRow}>
              <BaseAutoComplete
                label={<FormattedMessage id="pricingtype" />}
                name="pricingtype"
                variant="filled"
                size="small"
                customStyle={{ width: "100%" }}
                options={pricingtypeOptions}
                optionLable="pricingtype"
                fetchData={fetchPricingTypeAutoCompleteData}
                inputValue={values.pricingtype_textInput}
                onSelectChange={async (newValue) => {
                  await handleInputChange(
                    convertToDefEventPara(
                      "pricingtype",
                      newValue ? newValue.id : null
                    )
                  );
                }}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <BaseAutoComplete
                label={<FormattedMessage id="test_name" />}
                name="test"
                variant="filled"
                size="small"
                customStyle={{ width: "100%" }}
                options={testOptions}
                optionLable="english_name"
                fetchData={fetchTestAutoCompleteData}
                inputValue={values.test_textInput}
                onSelectChange={async (newValue) => {
                  await handleInputChange(
                    convertToDefEventPara("test", newValue ? newValue.id : null)
                  );
                }}
              />
            </div>
            <div className={classes.withOutSpaceRow}>
              <TextField
                id="outlined-basic"
                variant="filled"
                InputProps={InputProps}
                size="small"
                style={{ width: "100%", marginInline: 0 }}
                label={<FormattedMessage id="price" />}
                name="price"
                value={values.price}
                // InputLabelProps={{ shrink: values.department ? true : false }}
                required
                onChange={handleInputChange}
                {...(errors.price && {
                  error: true,
                  helperText: errors.price,
                })}
              />
            </div>
          </Form>
        </Container>
      </Modal>

      <Snackbar
        open={OpenSuccessAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Data saved
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error saving data
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
