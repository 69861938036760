// src/i18n.js
import { createIntl, createIntlCache } from "react-intl";

const cache = createIntlCache();

const messages = {
  en: {
    customer_managements: "Customer Managements",
    customer_test_result: "Customer Test Result",
    customer_account: "Customer Account",
    master_data: "Master Data",
    test_management: "Test Management",
    group_management: "Group Management",
    department_management: "Department Management",
    currencies: "currencies",
    currency_exchanges: "Currency Exchanges",
    currency_exchange: "Currency Exchange",
    sales_management: "Sales Management",
    customer_registration: "Customer Registration",
    customer_registration_return: "Customer Registration Return",
    pricing_type: "Pricing Type",
    pricing_list: "Sales Pricing List",
    offer: "Offer",
    purchases_managements: "Purchases Managements",
    purchase_invoice: "Purchase Invoice",
    purchase_return: "Purchase Return",
    purchase_pricing_list: "Purchase Pricing List",
    venders: "Venders",
    financial_managements: "Financial Managements",
    accounting_chart: "Accounting Chart",
    journal_entries: "Journal Entries",
    app_managements: "App Managements",
    users: "Users",
    branch: "Branch",
    menu: "Menu",
    patient_name: "Patient Name",
    gender: "Gender",
    age: "Age",
    age_type: "Type",
    type: "Type",
    phone_number: "Phone Number",
    email: "Email",
    customer_pricing_type: "Pricing Type",
    registration_date: "Registration Date",
    total_amount: "Total Amount",
    years: "Years",
    week: "Week",
    day: "Day",
    Male: "Male",
    Female: "Female",
    test_name: "Test Name",
    test_result: "Test Result",
    unit: "Unit",
    normal: "Normal",
    is_full: "completed",
    reTest: "retest",
    address: "Address",
    medical_info: "medical information",
    created_time: "Created Time",
    created_user: "Created User",
    updated_time: "Updated Time",
    updated_user: "Updated User",
    Export_CSV: "Export CSV",
    toggleLocale: "Toggle Locale",
    device_name: "Device Name",
    symbol: "Symbol",
    report_title: "Report Title",
    sample: "Sample",
    units: "Units",
    department: "Department",
    group: "Group",
    volume: "Volume",
    priority: "Priority",
    time: "Time",
    time_type: "Time Type",
    is_prepration: "Is Prepration",
    is_active: "Is Active",
    precautons: "Precautons",
    defult_result: "Defult Result",
    defult_comment: "Defult Comment",
    result_type: "Result Type",
    Hour: "Hour",
    Day: "Day",
    Week: "Week",
    Years: "Years",
    more_result: "Have More One Result",
    normal_range: "Normal Range",
    from_age: "From Age",
    to_age: "To Age",
    normal_value: "Normal Value",
    lower_limit: "Lower Limit",
    upper_limit: "Upper Limit",
    lower_comment: "Lower Comment",
    upper_comment: "Upper Comment",
    create: "Create",
    create_new: "Add New",
    close: "Close",
    print: "Print",
    print_barcode: "Print Barcode",
    print_recipt: "Print Recipt",
    currency_name: "Currency Name",
    currency_symbol: "Currency Symbol",
    country: "Country",
    visit_date: "Visit Date",
    comment: "Comment",
    registration_details: "Registration Details",
    test_list_details: "Test List Details",
    company_name: "Company Name",
    loading: "Loading...",
    payment_date: "Payment Date",
    payment_currency: "Payment Currency",
    payment_currency_exchange: "Payment Currency Exchange",
    payment_amount: "Payment Amount",
    back_currency: "Back Currency",
    back_currency_exchange: "Back Currency Exchange",
    back_amount: "Back Amount",
    payment_list: "Payment List",
    total_paid: "Paid Amount",
    total_remain: "Remain Amount",
    journal_entry: "Journal Entry",
    price: "Price",
    result_date: "Result Date",
    account: "Account",
    partner: "Partner",
    label: "Label",
    debit: "Debit",
    credit: "Credit",
    total_debit: "Total Credit",
    total_credit: "Total Credit",
    invoice_date: "Invoice Date",
    description: "Description",
    currencey: "Currencey",
    currencey_exchange: "Currencey Exchange",
    invoice_id: "Invoice Id",
    customer_registration_return_details:
      "Customer Registration Returns Details",
    customer_registration_return_list_details:
      "Customer Registration Returns List Details",
    pricingtype: "Pricing Type",
    offer_name: "Offer Name",
    offer_details: "Offer Details",
    offer_list_details: "Offer List Details",
    vender_name: "Vender Name",
    vender_invoice_number: "Vender Invoice Number",
    purchase_details: "Purchase Details",
    purchase_list_details: "Purchase List Details",
    qty: "Qty",
    purchase_return_details: "Purchase Return Details",
    purchase_return_list_details: "Purchase Return List Details",
    vender_type: "Vender Type",
    delete: "Delete",
    account_type: "Account Type",
    account_number: "Account Number",
    main_account: "Main Account",
    account_name: "Account Name",
    journal_date: "Journal Date",
    number: "Number",
    reference: "Reference",
    journal: "Journal",
    is_posted: "Is Posted",
    journal_details: "Journal Details",
    journal_items: "Journal Items",
    full_name: "Full Name",
    last_login: "Last Login",
    client: "Client",
    password: "Password",
    is_superuser: "Is Superuser",
    about: "About",
    permissions: "Permissions",
    header_image: "Header Image",
    footer_image: "Footer Image",
  },
  ar: {
    customer_managements: "الزبائن",
    customer_test_result: "نتائج الفحوصات",
    customer_account: "حسابات الزبائن",
    master_data: "المعلومات الاساسية",
    test_management: "الفحوصات",
    group_management: "مجموعات الفحوصات",
    department_management: "اقاسم الفحوصات",
    currencies: "العملات",
    currency_exchanges: "اسعار الصرف",
    currency_exchange: "سعر الصرف",
    sales_management: "المبيعات",
    customer_registration: "تسجيل فاتورة",
    customer_registration_return: "ارجاع فاتورة",
    pricing_type: "انواع التسعير",
    pricing_list: "قائمة تسعير المبيعات",
    offer: "العروض",
    purchases_managements: "المشتريات",
    purchase_invoice: "فاتورة شراء",
    purchase_return: "فاتورة ارجاع شراء",
    purchase_pricing_list: "قائمة تسعير المشتريات",
    venders: "المجهزين",
    financial_managements: "المالية",
    accounting_chart: "شجرة الحسابات",
    journal_entries: "دفتر اليومية",
    app_managements: "ادارة التطبيق",
    users: "المستخدمين",
    branch: "الافرع",
    menu: "القائمة",
    patient_name: "اسم المريض",
    gender: "الجنس",
    age: "العمر",
    age_type: "النوع",
    type: "النوع",
    phone_number: "رقم الهاتف",
    email: "البريد الالكتروني",
    customer_pricing_type: "فئة التسعير",
    registration_date: "تاريخ الزيارة",
    total_amount: "المبلغ الكلي",
    years: "سنة",
    week: "اسبوع",
    day: "يوم",
    Male: "ذكر",
    Female: "انثى",
    test_name: "اسم التحليل",
    test_result: "النتيجة",
    unit: "الوحدة",
    normal: "الحد الطبيعي",
    is_full: "مكتمل",
    reTest: "اعادة",
    address: "العنوان",
    medical_info: "المعلومات الطبية",
    created_time: "وقت الانشاء",
    created_user: "منشأ بواسطة",
    updated_time: "تاريخ التعديل",
    updated_user: "معدل بواسطة",
    Export_CSV: "استخراج اكسل",
    toggleLocale: "تبديل اللغة",
    device_name: "اسم الجهاز",
    symbol: "الرمز",
    report_title: "عنوان التقرير",
    sample: "العينة",
    units: "الوحدة",
    department: "القسم",
    group: "المجموعة",
    volume: "الحجم",
    priority: "الاولوية",
    time: "الوقت",
    time_type: "نوع الوقت",
    is_prepration: "يحتاج التحضير",
    is_active: "فعال",
    precautons: "احتياطات",
    defult_result: "النتيجة الافتراضية",
    defult_comment: "التعليق الافتراضي",
    result_type: "نوع النتيحة",
    Hour: "ساعة",
    Day: "يوم",
    Week: "اسبوع",
    Years: "سنة",
    more_result: "النتيجة تحتوي على اكثر من حقل",
    normal_range: "المعدلات الطبيعية",
    from_age: "من عمر",
    to_age: "الى عمر",
    normal_value: "الحد الطبيعي",
    lower_limit: "الحد الادنى",
    upper_limit: "الحد الأعلى",
    lower_comment: "تعليق الحد الادنى",
    upper_comment: "تعليق الحد الأعلى",
    create: "حفظ",
    create_new: "اضافة جديد",
    close: "اغلاق",
    print: "طباعة",
    print_barcode: "طباعة الباركود",
    print_recipt: "طباعة الفاتورة",
    currency_name: "اسم العملة",
    currency_symbol: "رمز العملة",
    country: "البلد",
    visit_date: "تاريخ الفاتورة",
    comment: "الملاحضات",
    registration_details: "تفاصيل الفاتورة",
    test_list_details: "تفاصيل قائمة التحاليل",
    company_name: "اسم الشركة",
    loading: "جار التحميل...",
    payment_date: "تاريخ الدفع",
    payment_currency: "عملة المبلغ المدفوع",
    payment_currency_exchange: "سعر الصرف",
    payment_amount: "المبلغ المدفوع",
    back_currency: "عملة المبلغ المسترجع",
    back_currency_exchange: "سعر الصرف",
    back_amount: "المبلغ المسترجع",
    payment_list: "قائمة المدفوعات",
    total_paid: "المبلغ المدفوع",
    total_remain: "المبلغ المتبقي",
    journal_entry: "قيد دفتر اليومية",
    price: "السعر",
    result_date: "تاريخ النتيجة",
    account: "الحساب",
    partner: "الشريك",
    label: "مرجع",
    debit: "مدين",
    credit: "دائن",
    total_debit: "المجموع",
    total_credit: "المجموع",
    invoice_date: "تاريخ الفاتورة",
    description: "الملاحضات",
    currencey: "العملة",
    currencey_exchange: "سعر الصرف",
    invoice_id: "رقم الفاتورة",
    customer_registration_return_details: "تفاصيل فاتورة الارجاع",
    customer_registration_return_list_details: "تفاصيل قائمة فاتورة الارجاع",
    pricingtype: "نوع التسعير",
    offer_name: "اسم العرض",
    offer_details: "تفاصيل العرض",
    offer_list_details: "تفاصيل قائمة العرض",
    vender_name: "اسم المجهز",
    vender_invoice_number: "رقم فاتورة المجهز",
    purchase_details: "تفاصيل فاتورة الشراء",
    purchase_list_details: "تفاصيل قائمة فاتورة الشراء",
    qty: "الكمية",
    purchase_return_details: "تفاصيل فاتورة ارجاع المشتريات",
    purchase_return_list_details: "تفاصيل قائمة فاتورة ارجاع المشتريات",
    vender_type: "نوع المجهز",
    delete: "حذف",
    account_type: "نوع الحساب",
    account_number: "رقم الحساب",
    main_account: "الحساب الرئيسي",
    account_name: "اسم الحساب",
    journal_date: "تاريخ القيد",
    number: "الرقم",
    reference: "المرجع",
    journal: "القيد",
    is_posted: "مرحل",
    journal_details: "تفاصيل القيد",
    journal_items: "عناصر القيد",
    full_name: "الاسم الكامل",
    last_login: "اخر تسجيل دخول",
    client: "الفرع",
    password: "الرقم السري",
    is_superuser: "مدير نظام",
    about: "حول",
    permissions: "الصلاحيات",
    header_image: "صورة راس الصفحة",
    footer_image: "صورة ذيل الصفحة",
  },
};

const getIntl = (locale) =>
  createIntl({ locale, messages: messages[locale] }, cache);

export { getIntl };
