import React, { Fragment, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import AddModal from "./Components/AddModal";
import BaseTable, { tableIcons } from "../../app/generalComponent/BaseTable";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { ViewModal } from "./Components/ViewModal";
import { DeleteTest, GetTestDetails } from "./TestAPI";
import { EditModal } from "./Components/EditModal";
import AlertDialog from "../../app/generalComponent/DeletingAlert";
import { Fab, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useStyles } from "./Components/ComponentsStyle/ModalStyle";
import { NormalModal } from "./Components/NormalModal";
import { useSelector } from "react-redux";
import { selectClientInfo } from "../Authentecation/AuthSlice";
import { FormattedMessage } from "react-intl";

// import { jsPDF } from "jspdf";

const TestPage = () => {
  const [addOpen, setAddOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [editeOpen, setEditeOpen] = useState(false);
  const [normalOpen, setNormalOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [apiResultValues, setApiResultValues] = useState(null);
  const [apiNormalValues, setApiNormalValues] = useState(null);
  const [rowId, setRowId] = useState(null);
  const classes = useStyles();
  const theme = useTheme();
  const tableRef = React.createRef();
  const client = useSelector(selectClientInfo);

  const LoadDetails = async (id) => {
    if (id !== null) {
      const response = await GetTestDetails(id);
      const apiResultValues = {
        id: response.id,
        english_name: response.english_name,
        device_name: response.device_name,
        report_title: response.report_title,
        sample: response.sample,
        symbol: response.symbol,
        units: response.units,
        department: response.department ? response.department.id : null,
        department_textInput: response.department
          ? response.department.department
          : "",
        group: response.group ? response.group.id : null,
        group_textInput: response.group ? response.group.group : "",
        volume: response.volume,
        priority: response.priority,
        time: response.time,
        time_type: response.time_type,
        is_prepration: response.is_prepration,
        is_active: response.is_active,
        more_result: response.more_result,
        result_type: response.result_type,
        precautons: response.precautons,
        defult_result: response.defult_result,
        defult_comment: response.defult_comment,
      };
      // console.log(response);
      setApiResultValues(apiResultValues);
      console.log(apiResultValues);
    }
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };
  const handelConfirmDelete = () => {
    console.log("id " + rowId);
    DeleteTest(rowId);
    setDeleteDialogOpen(false);
  };
  const columns = [
    { title: <FormattedMessage id="test_name" />, field: "english_name" },
    { title: <FormattedMessage id="device_name" />, field: "device_name" },
    { title: <FormattedMessage id="symbol" />, field: "symbol" },
    {
      title: <FormattedMessage id="report_title" />,
      field: "report_title",
      export: false,
    },
    { title: <FormattedMessage id="sample" />, field: "sample" },
    { title: <FormattedMessage id="units" />, field: "units" },
    {
      title: <FormattedMessage id="department" />,
      field: "department.department",
      sorting: false,
    },
    {
      title: <FormattedMessage id="group" />,
      field: "group.group",
      sorting: false,
    },
    { title: <FormattedMessage id="volume" />, field: "volume" },
    {
      title: <FormattedMessage id="priority" />,
      field: "priority",
      export: false,
    },
    { title: <FormattedMessage id="time" />, field: "time" },
    { title: <FormattedMessage id="time_type" />, field: "time_type" },
    { title: <FormattedMessage id="is_prepration" />, field: "is_prepration" },
    {
      title: <FormattedMessage id="is_active" />,
      field: "is_active",
      export: false,
    },
    { title: <FormattedMessage id="precautons" />, field: "precautons" },
    { title: <FormattedMessage id="defult_result" />, field: "defult_result" },
    {
      title: <FormattedMessage id="defult_comment" />,
      field: "defult_comment",
    },
    {
      title: <FormattedMessage id="created_time" />,
      field: "created_time",
      export: false,
    },
    {
      title: <FormattedMessage id="created_user" />,
      field: "created_user",
      export: false,
    },
    {
      title: <FormattedMessage id="updated_time" />,
      field: "updated_time",
      export: false,
    },
    {
      title: <FormattedMessage id="updated_user" />,
      field: "updated_user",
      export: false,
    },
  ];

  return (
    <Fragment>
      <Tooltip title="Add" aria-label="add" onClick={() => setAddOpen(true)}>
        <Fab color="primary" className={classes.fab}>
          <AddIcon />
        </Fab>
      </Tooltip>
      <AddModal open={addOpen} setOpen={setAddOpen} />
      <ViewModal data={apiResultValues} open={viewOpen} setOpen={setViewOpen} />
      <NormalModal
        data={apiNormalValues}
        open={normalOpen}
        setOpen={setNormalOpen}
      />
      <EditModal
        data={apiResultValues}
        open={editeOpen}
        setOpen={setEditeOpen}
      />
      <AlertDialog
        open={deleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleConfirm={handelConfirmDelete}
      />
      <BaseTable
        refrence={tableRef}
        title={<FormattedMessage id="test_management" />}
        columns={columns}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = "http://206.189.137.141:8000/api/testManagement/test/?";
            url += "per_page=" + query.pageSize;
            url += "&client=" + client.id;
            url += "&page=" + (query.page + 1);
            if (query.search) {
              url += `&search=${query.search}`;
            }
            if (query.orderBy) {
              if (query.orderDirection === "asc") {
                url += `&orderBy=${query.orderBy.field}`;
              } else {
                url += `&orderBy=-${query.orderBy.field}`;
              }
            }
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data,
                  page: result.page - 1,
                  totalCount: result.count,
                });
              });
          })
        }
        actions={[
          {
            icon: tableIcons.Refresh,
            tooltip: "Refresh",
            onClick: (event, rowData) => {
              const scopedRef = tableRef.current;
              scopedRef.onQueryChange();
            },
            isFreeAction: true,
          },
          (rowData) => ({
            hidden:
              rowData.result_type === "Urine" ||
              rowData.result_type === "Stool" ||
              rowData.result_type === "Semen" ||
              rowData.result_type === "CBC Diff 3" ||
              rowData.result_type === "CBC Diff 5",
            icon: tableIcons.PollIcon,
            tooltip: "Normal",
            onClick: (event, rowData) => {
              setApiNormalValues(rowData.id);
              setNormalOpen(true);
            },
          }),
          {
            icon: tableIcons.View,
            tooltip: "View",
            onClick: (event, rowData) => {
              LoadDetails(rowData.id);
              setViewOpen(true);
            },
          },
          {
            icon: tableIcons.Edit,
            tooltip: "Edit",
            onClick: (event, rowData) => {
              LoadDetails(rowData.id);
              setEditeOpen(true);
            },
          },
          {
            icon: tableIcons.Delete,
            tooltip: "Delete",
            onClick: (event, rowData) => {
              setDeleteDialogOpen(true);
              setRowId(rowData.id);
            },
          },
        ]}
        options={{
          exportMenu: [
            // {
            //   label: "Export PDF",
            //   exportFunc: (cols, datas) => ExportPdf(cols, datas, "Test Data"),
            // },
            {
              label: <FormattedMessage id="Export_CSV" />,
              exportFunc: (cols, datas) =>
                ExportCsv(cols, datas, new Date().toLocaleDateString()),
            },
          ],
          exportButton: true,
          exportAllData: true,
          exportFileName: "TestData",
          pageSizeOptions: [5, 10, 20, 50, 100],
          maxBodyHeight: "55vh",
          paginationType: "stepped",
          paginationPosition: "bottom",
          searchFieldVariant: "outlined",
          searchFieldStyle: {
            fontSize: "small",
            maxHeight: 35,
            paddingInlineEnd: 0,
          },
          // columnsButton:true,
          headerStyle: {
            // background: theme.palette.primary.main,
            // color: "white",
            fontWeight: "bold",
          },
          rowStyle: (data) =>
            data.tableData.id % 2 === 0
              ? { background: theme.palette.common.white }
              : { background: theme.palette.grey[100] },
          // grouping:true
        }}
      />
    </Fragment>
  );
};

export default TestPage;

// function CustomExportPdf(columns, data = [], filename = "data") {
//   try {
//     let finalData = data;
//     if (data.length && !Array.isArray(data[0])) {
//       if (typeof data[0] === "object") {
//         finalData = data.map((row) =>
//           columns.map((col) =>
//             col.exportTransformer
//               ? col.exportTransformer(row)
//               : row[col.field]
//           )
//         );
//       }
//     }
//       const content = {
//         startY: 50,
//         head: [columns.map((col) => col.title)],
//         body: finalData,
//       };
//       const unit = "pt";
//       const size = "A4";
//       const orientation = "landscape";
//       const doc = new jsPDF(orientation, unit, size);
//       doc.setFontSize(15);
//       doc.text(filename, 40, 40);
//       doc.autoTable(content);
//       doc.save(filename + Date.now().toString() + ".pdf");
//     // }
//   } catch (err) {
//     console.error(
//       `exporting pdf : ${err}`
//     );
//   }
// }
